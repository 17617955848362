import React from 'react';

import { EntityProperty } from '../../gql/graphql';
import { useVecticeForm } from '../../hooks';
import { Input, ModalForm } from '../../ui';
import { defaultNameFieldConfiguration, defaultValueFieldConfiguration } from '../../utils';

interface PropertyFormModalProps {
  selectedProperty?: EntityProperty;
  onClose: () => void;
  onSubmit: (data: Pick<EntityProperty, 'key' | 'value'>) => Promise<any>;
}

export const PropertyFormModal = ({ selectedProperty, onClose, onSubmit }: PropertyFormModalProps) => {
  const { formState, preSubmit, registerWithErrors } = useVecticeForm({
    mode: 'onChange',
    defaultValues: {
      propertyName: selectedProperty?.key,
      propertyValue: selectedProperty?.value,
    },
  });
  const { isDirty, hasErrors, isSubmitting } = formState;

  return (
    <ModalForm
      title={
        selectedProperty
          ? $t({ id: 'modal.propertyForm.updateResource', defaultMessage: 'Update Property' })
          : $t({ id: 'modal.propertyForm.createResource', defaultMessage: 'Create Property' })
      }
      onClose={onClose}
      submitLabel={
        selectedProperty
          ? $t({ id: 'button.save', defaultMessage: 'Save' })
          : $t({ id: 'button.add', defaultMessage: 'Add' })
      }
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(async (data: { propertyName?: string; propertyValue?: string }) => {
        if (data.propertyName && data.propertyValue) {
          await onSubmit({ key: data.propertyName, value: data.propertyValue });
        }
      })}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <Input
        {...registerWithErrors('propertyName', defaultNameFieldConfiguration())}
        label={$t({ id: 'modal.propertyForm.propertyName', defaultMessage: 'Property Name' })}
        placeholder={$t({ id: 'modal.propertyForm.placeholder', defaultMessage: 'Type the property name' })}
        autoFocus
        gutterBottom
      />
      <Input
        {...registerWithErrors('propertyValue', defaultValueFieldConfiguration())}
        label={$t({ id: 'modal.propertyForm.valueLabel', defaultMessage: 'Value' })}
        placeholder={$t({ id: 'modal.propertyForm.valuePlaceholder', defaultMessage: 'Type value' })}
      />
    </ModalForm>
  );
};
