import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import NoPhasesGraphic from '../../../assets/graphics/documentation/empty-phases.svg';
import { useAuthentication } from '../../../context';
import { graphql } from '../../../gql';
import { buildAdvancedDateFilter } from '../../../graphql/utils';
import { Button, Card, FlexContainer, Graphic, List, Typography } from '../../../ui';
import { ReportListItem } from '../../asset-display';
import { DocumentationLink } from '../../links';

import styles from './HomeTabs.module.scss';

const PAGE_SIZE = 10;

export const GET_USER_REPORTS = graphql(`
  query getUserReports($contributors: [ID!], $afterCursor: String, $pageSize: Int!, $advancedFilters: [GroupFilter!]) {
    CDTReports(
      filters: { contributors: $contributors, advancedFilters: $advancedFilters }
      order: { field: "updatedDate", direction: DESC }
      page: { size: $pageSize, afterCursor: $afterCursor }
    ) {
      items {
        id
        ...reportListItem
      }
      page {
        afterCursor
        hasNextPage
      }
    }
  }
`);

const EmptyReportsTab = () => (
  <Card inert className={styles.emptyCard}>
    <FlexContainer direction="column" align="center" gap={8} className={styles.emptyContent}>
      <Graphic src={NoPhasesGraphic} size={200} />
      <Typography color="primary" weight="semi-bold">
        {$t({
          id: 'home.tabs.reports.empty.label',
          defaultMessage: 'No reports yet',
        })}
      </Typography>
      <Typography variant="callout" color="secondary" align="center" paragraph>
        {$t({
          id: 'home.tabs.reports.empty.description',
          defaultMessage:
            'Reports allow you to document project outcomes for validation. Complete a report once your project is finished.',
        })}
        <br />
        <DocumentationLink url="vectice_for_banking" asTypography>
          {$t({
            id: 'home.tabs.iterations.empty.readOurDocs',
            defaultMessage: 'Read our docs to learn more.',
          })}
        </DocumentationLink>
      </Typography>
    </FlexContainer>
  </Card>
);

export const ReportsTab = () => {
  const { user: connectedUser } = useAuthentication();
  const date = useMemo(
    () => ({
      start: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(),
      end: new Date(Date.now()).toISOString(),
    }),
    [],
  );
  const { data, loading, fetchMore } = useQuery(GET_USER_REPORTS, {
    fetchPolicy: 'network-only',
    variables: {
      contributors: [connectedUser.id],
      pageSize: PAGE_SIZE,
      advancedFilters: [
        ...buildAdvancedDateFilter({ field: 'createdDate', ...date }),
        ...buildAdvancedDateFilter({ field: 'updatedDate', ...date }),
      ],
    },
  });

  const reports = data?.CDTReports.items;
  const { hasNextPage, afterCursor } = data?.CDTReports.page ?? {};

  const handleShowMore = () => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          afterCursor,
        },
      });
    }
  };

  return (
    <>
      <List collapsed empty={reports?.length === 0} emptyText={<EmptyReportsTab />} loading={loading} gutterBottom>
        {reports?.map((report) => (
          <ReportListItem key={report.id} report={report} />
        ))}
      </List>
      {hasNextPage && (
        <Button variant="outlined" loading={loading} size="sm" onClick={handleShowMore}>
          {$t({ id: 'button.showMore', defaultMessage: 'Show More' })}
        </Button>
      )}
    </>
  );
};
