import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as AddIcon } from '../../assets/icons/interface/ic-add-circle.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/interface/ic-common-file-text.svg';
import { AttachmentOutput, AttachmentTypeEnum } from '../../gql/graphql';
import { useAttachments } from '../../hooks';
import {
  EmptySection,
  FlexContainer,
  Grid,
  GridCell,
  Icon,
  ModalPrompt,
  Section,
  Typography,
  Upload,
  WithLoading,
} from '../../ui';
import { VecticeResourceType, downloadEntityFile, validateMimeType, isNil } from '../../utils';
import { DeletionPrompt } from '../modals';
import { RenameAttachmentModal } from '../modals/RenameAttachmentModal';
import { LightboxPreview } from '../previews';
import { PreviewTile } from '../previewTile';

import styles from './VersionAttachments.module.scss';

interface VersionAttachmentsProps {
  readOnly?: boolean;
  resourceId: string;
  resourceType: VecticeResourceType;
}

type SelectedAttachment = Pick<AttachmentOutput, 'id' | 'name' | 'attachmentType'>;

export const VersionAttachments = ({ readOnly, resourceId, resourceType }: VersionAttachmentsProps) => {
  const uploadZoneRef = useRef<HTMLDivElement>(null);

  const [selectedAttachment, setSelectedAttachment] = useState<number | null>(null);
  const [showAttachmentToRename, setShowAttachmentToRename] = useState<SelectedAttachment>();
  const [showAttachmentToDelete, setShowAttachmentToDelete] = useState<SelectedAttachment>();
  const [showAttachmentToExtract, setShowAttachmentToExtract] = useState<SelectedAttachment>();

  const {
    attachments,
    total,
    loading,
    uploading,
    addEntityFiles,
    extractEntityFile,
    removeEntityFile,
    renameEntityFile,
    removeEntityMetadata,
    renameEntityMetadata,
  } = useAttachments(resourceId, resourceType);

  useEffect(() => {
    if (uploadZoneRef.current) {
      if (readOnly) {
        return () => null;
      }

      // Required for the drop event to register properly
      const dropShield = (e: DragEvent) => {
        e.preventDefault();
      };
      uploadZoneRef.current.addEventListener('dragover', dropShield);
      uploadZoneRef.current.addEventListener('dragenter', dropShield);

      const handleDataTransfer = (e: DragEvent) => {
        if (e.dataTransfer) {
          e.preventDefault();
          const files = Array.from(e.dataTransfer.files).filter(validateMimeType('*'));
          addEntityFiles(files);
        }
      };
      uploadZoneRef.current.addEventListener('drop', handleDataTransfer);

      return () => {
        if (uploadZoneRef.current) {
          uploadZoneRef.current.removeEventListener('dragover', dropShield);
          uploadZoneRef.current.removeEventListener('dragenter', dropShield);
          uploadZoneRef.current.removeEventListener('drop', handleDataTransfer);
        }
      };
    }

    return () => null;
  }, [readOnly]);

  const handleAttachmentUpload = async (files: File[]) => {
    addEntityFiles(files);
  };

  return (
    <>
      <Section
        label={(ariaId) => (
          <FlexContainer justify="space-between" className={styles.header}>
            <FlexContainer direction="column" gap={0}>
              <Typography id={ariaId} component="h2" color="primary" variant="callout" weight="semi-bold">
                {$t({ id: 'attachments.title', defaultMessage: 'Attachments' })}
              </Typography>
              {!isNil(total) && (
                <Typography color="tertiary" component={FlexContainer} gap={4} variant="footnote">
                  <Icon icon={FileIcon} size={12} />
                  <span>
                    {$t({
                      id: 'attachments.details.resourcesCountLabel',
                      defaultMessage: `{itemCount, plural, one {# File} other {# Files}}`,
                      values: { itemCount: total },
                    })}
                  </span>
                </Typography>
              )}
            </FlexContainer>
            {!readOnly && (
              <Upload
                accept="*"
                icon={AddIcon}
                multiple
                variant="white"
                onUpload={handleAttachmentUpload}
                disabled={uploading}
                uploading={uploading}
              >
                {$t({ id: 'attachments.button.add', defaultMessage: 'Add' })}
              </Upload>
            )}
          </FlexContainer>
        )}
        ref={uploadZoneRef}
      >
        <WithLoading loading={loading}>
          {!attachments?.length ? (
            <EmptySection
              message={$t({
                id: 'versionAttachments.empty.message',
                defaultMessage: 'Attach files, images, etc. related to this asset.',
              })}
            />
          ) : (
            <Grid role="list" className={styles.grid}>
              {attachments.map((attachment, index) => (
                <GridCell role="listitem" key={attachment.id} aria-label={attachment.name ?? ''}>
                  <PreviewTile
                    attachment={attachment}
                    onClick={() => setSelectedAttachment(index)}
                    onExtract={
                      !readOnly &&
                      (attachment.mimeType?.includes('ms-excel') ||
                        attachment.mimeType?.includes('officedocument.spreadsheetml.sheet'))
                        ? () => setShowAttachmentToExtract(attachment)
                        : undefined
                    }
                    onRename={readOnly ? undefined : () => setShowAttachmentToRename(attachment)}
                    onDelete={readOnly ? undefined : () => setShowAttachmentToDelete(attachment)}
                    onDownload={() => downloadEntityFile(attachment)}
                  />
                </GridCell>
              ))}
            </Grid>
          )}
        </WithLoading>
      </Section>

      {selectedAttachment !== null && attachments?.[selectedAttachment] && (
        <LightboxPreview
          entityFile={attachments[selectedAttachment]}
          previousDisabled={selectedAttachment === 0}
          nextDisabled={selectedAttachment === attachments.length - 1}
          onPrevious={() => setSelectedAttachment(selectedAttachment - 1)}
          onNext={() => setSelectedAttachment(selectedAttachment + 1)}
          onClose={() => setSelectedAttachment(null)}
        />
      )}

      {showAttachmentToDelete && (
        <DeletionPrompt
          resourceName={showAttachmentToDelete.name || ''}
          title={$t({
            id: 'VersionAttachments.deleteAttachment.title',
            defaultMessage: 'Delete Version Attachment',
          })}
          onClose={() => setShowAttachmentToDelete(undefined)}
          onConfirm={() => {
            if (showAttachmentToDelete.attachmentType === AttachmentTypeEnum.EntityFile) {
              removeEntityFile(showAttachmentToDelete);
            } else {
              removeEntityMetadata(showAttachmentToDelete);
            }
            setShowAttachmentToDelete(undefined);
          }}
        />
      )}
      {showAttachmentToExtract && (
        <ModalPrompt
          title={$t({
            id: 'VersionAttachments.extractModal.title',
            defaultMessage: 'Extract from {fileName}',
            values: { fileName: showAttachmentToExtract.name ?? '' },
          })}
          confirmLabel={$t({ id: 'modal.extract', defaultMessage: 'Extract' })}
          cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
          onClose={() => setShowAttachmentToExtract(undefined)}
          onConfirm={() => {
            extractEntityFile(showAttachmentToExtract);
            setShowAttachmentToExtract(undefined);
          }}
        >
          {$t({
            id: 'VersionAttachments.extractModal.content',
            defaultMessage: 'This will create new attachments based on charts and tables contained in this excel file.',
          })}
        </ModalPrompt>
      )}
      {showAttachmentToRename && (
        <RenameAttachmentModal
          attachment={showAttachmentToRename}
          onClose={() => setShowAttachmentToRename(undefined)}
          onConfirm={(name) => {
            if (showAttachmentToRename.attachmentType === AttachmentTypeEnum.EntityFile) {
              renameEntityFile({ variables: { id: showAttachmentToRename.id, newName: name } });
            } else {
              renameEntityMetadata({ variables: { id: showAttachmentToRename.id, name } });
            }
            setShowAttachmentToRename(undefined);
          }}
        />
      )}
    </>
  );
};
