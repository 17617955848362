import React from 'react';

import type { AssetRowProps } from '../../../ui';

import { ReactComponent as IterationIcon } from '../../../assets/icons/specials/ic-iteration.svg';
import { FragmentType, graphql, useFragment } from '../../../gql';
import { Project, Workspace } from '../../../gql/graphql';
import { AssetRow, FlexContainer, Typography } from '../../../ui';
import { IterationStatusBadge } from '../../badges';
import { FormatDate, FormatUpdatedBy } from '../../formatters';
import { UserAvatar } from '../user';
import { FormatWorkspace } from '../workspace';

const IterationRowFragment = graphql(`
  fragment iterationRow on Iteration {
    vecticeId
    index
    description
    name
    status
    starred
    phase {
      vecticeId
      name
    }
    owner {
      ...userFields
    }
    updatedDate
  }
`);

interface IterationRowProps extends Omit<AssetRowProps, 'icon' | 'label'> {
  iteration?: FragmentType<typeof IterationRowFragment>;
  project?: Pick<Project, 'vecticeId' | 'name'> | null;
  workspace?: Pick<Workspace, 'vecticeId' | 'type' | 'name'> | null;
}

export const IterationRow = (props: IterationRowProps) => {
  const iteration = useFragment(IterationRowFragment, props.iteration);

  if (!iteration) {
    return null;
  }

  return (
    <AssetRow
      icon={IterationIcon}
      parents={props.project ? [props.project.name, iteration?.phase?.name] : iteration?.phase?.name}
      label={iteration?.name}
      isStarred={iteration.starred}
      {...props}
    >
      <Typography variant="footnote" color="secondary">
        {iteration.vecticeId}
      </Typography>
      {props.workspace && (
        <Typography variant="footnote" color="tertiary" ellipsis>
          <FormatWorkspace name={props.workspace.name} iconSize={14} type={props.workspace.type} ellipsis />
        </Typography>
      )}
      <IterationStatusBadge status={iteration.status} />
      {!!iteration.owner && (
        <FlexContainer gap={4}>
          <Typography variant="footnote" color="secondary">
            {$t({
              id: 'iteration.row.owner',
              defaultMessage: 'Iteration owner',
            })}
          </Typography>
          <UserAvatar user={iteration.owner} size="xs" />
        </FlexContainer>
      )}
      {!!iteration.updatedDate && (
        <Typography variant="footnote" color="secondary">
          <FormatUpdatedBy date={<FormatDate date={iteration.updatedDate} fromNow />} />
        </Typography>
      )}
    </AssetRow>
  );
};
