export enum VecticeRoutes {
  // *****************************
  // UNSAFE ROUTES
  // *****************************
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  AUTH = 'AUTH',
  LOGGED_OUT = 'LOGGED_OUT',
  LOGOUT = 'LOGOUT',
  UNKNOWN_USER = 'UNKNOWN_USER',
  REGISTER = 'REGISTER',
  REGISTER_TOKEN = 'REGISTER_TOKEN',
  REGISTER_INVALID_TOKEN = 'REGISTER_INVALID_TOKEN',
  REGISTER_INVITE_NOT_FOUND = 'REGISTER_INVITE_NOT_FOUND',
  REGISTER_INVITE_ALREADY_ACCEPTED = 'REGISTER_INVITE_ALREADY_ACCEPTED',
  REGISTER_INVITE_EXPIRED = 'REGISTER_INVITE_EXPIRED',
  FORGOT_MY_PASSWORD = 'FORGOT_MY_PASSWORD',
  PASSWORD_RESET = 'PASSWORD_RESET',
  PASSWORD_RESET_TOKEN = 'PASSWORD_RESET_TOKEN',
  PASSWORD_RESET_RESENT = 'PASSWORD_RESET_RESENT',
  PASSWORD_RESET_EXPIRED = 'PASSWORD_RESET_EXPIRED',
  PASSWORD_RESET_NOT_FOUND = 'PASSWORD_RESET_NOT_FOUND',
  PASSWORD_RESET_RESENT_FAILED = 'PASSWORD_RESET_RESENT_FAILED',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  // *****************************
  // SAFE ROUTES
  // *****************************
  HOME = 'HOME',
  MY_WORKSPACE = 'MY_WORKSPACE',
  MY_ACTIVITY = 'MY_ACTIVITY',
  NOT_FOUND = 'NOT_FOUND',
  FORBIDDEN = 'FORBIDDEN',
  SEARCH = 'SEARCH',
  BROWSE = 'BROWSE',
  // ACCOUNT
  ACCOUNT = 'ACCOUNT',
  ACCOUNT_PROFILE = 'ACCOUNT_PROFILE',
  ACCOUNT_API_KEYS = 'ACCOUNT_API_KEYS',
  ACCOUNT_CHANGE_PASSWORD = 'ACCOUNT_CHANGE_PASSWORD',
  // WORKSPACES
  WORKSPACES = 'WORKSPACES',
  // WORKSPACE
  WORKSPACE = 'WORKSPACE',
  WORKSPACE_PROJECTS = 'WORKSPACE_PROJECTS',
  WORKSPACE_DASHBOARD = 'WORKSPACE_DASHBOARD',
  WORKSPACE_DASHBOARD_PROJECT_PROGRESS = 'WORKSPACE_DASHBOARD_PROJECT_PROGRESS',
  WORKSPACE_DASHBOARD_TEAM_ACTIVITY = 'WORKSPACE_DASHBOARD_TEAM_ACTIVITY',
  WORKSPACE_ACTIVITY = 'WORKSPACE_ACTIVITY',
  WORKSPACE_MEMBERS = 'WORKSPACE_MEMBERS',
  WORKSPACE_MEMBERS_ADD = 'WORKSPACE_MEMBERS_ADD',
  WORKSPACE_SETTINGS = 'WORKSPACE_SETTINGS',
  // PROJECT
  PROJECT = 'PROJECT',
  PROJECT_PHASES = 'PROJECT_PHASES',
  PROJECT_DOCUMENTATION = 'PROJECT_DOCUMENTATION',
  PROJECT_DATASETS = 'PROJECT_DATASETS',
  PROJECT_MODELS = 'PROJECT_MODELS',
  PROJECT_ACTIVITY = 'PROJECT_ACTIVITY',
  PROJECT_SETTINGS = 'PROJECT_SETTINGS',
  PROJECT_CDT_REPORTS = 'PROJECT_CDT_REPORTS',
  PROJECT_FINDINGS = 'PROJECT_FINDINGS',
  // CDT REPORT
  CDT_REPORT = 'CDT_REPORT',
  CDT_TEMPLATES = 'CDT_TEMPLATES',
  CDT_MACROS = 'CDT_MACROS',
  CDT_EDIT_TEMPLATE = 'CDT_EDIT_TEMPLATE',
  CDT_EDIT_MACRO = 'CDT_EDIT_MACRO',
  // PHASE
  PHASE = 'PHASE',
  PHASE_DOCUMENTATION = 'PHASE_DOCUMENTATION',
  PHASE_ITERATIONS = 'PHASE_ITERATIONS',
  PHASE_REVIEWS = 'PHASE_REVIEWS',
  PHASE_SETTINGS = 'PHASE_SETTINGS',
  // EXPORT
  EXPORT_PHASE = 'EXPORT_PHASE',
  EXPORT_PROJECT = 'EXPORT_PROJECT',
  RAW_PHASE_EXPORT = 'RAW_PHASE_EXPORT',
  RAW_PROJECT_EXPORT = 'RAW_PROJECT_EXPORT',
  RAW_REPORT_EXPORT = 'RAW_REPORT_EXPORT',
  RAW_FINDING_EXPORT = 'RAW_FINDING_EXPORT',
  // ITERATION
  ITERATION = 'ITERATION',
  // DATASET
  DATASET = 'DATASET',
  DATASET_VERSIONS = 'DATASET_VERSIONS',
  DATASET_ACTIVITY = 'DATASET_ACTIVITY',
  DATASET_SETTINGS = 'DATASET_SETTINGS',
  // DATASET VERSION
  DATASET_VERSION = 'DATASET_VERSION',
  DATASET_VERSION_ACTIVITY = 'DATASET_VERSION_ACTIVITY',
  // MODEL
  MODEL = 'MODEL',
  MODEL_VERSIONS = 'MODEL_VERSIONS',
  MODEL_ACTIVITY = 'MODEL_ACTIVITY',
  MODEL_SETTINGS = 'MODEL_SETTINGS',
  // MODEL VERSION
  MODEL_VERSION = 'MODEL_VERSION',
  MODEL_VERSION_ACTIVITY = 'MODEL_VERSION_ACTIVITY',
  // FINDING
  FINDING = 'FINDING',
  // ORGANIZATION
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_USERS = 'ORGANIZATION_USERS',
  ORGANIZATION_WORKSPACES = 'ORGANIZATION_WORKSPACES',
  ORGANIZATION_AUTHENTICATION_METHODS = 'ORGANIZATION_AUTHENTICATION_METHODS',
  ORGANIZATION_AUTHENTICATION_CREATION = 'ORGANIZATION_AUTHENTICATION_CREATION',
  ORGANIZATION_AUTHENTICATION_EDIT = 'ORGANIZATION_AUTHENTICATION_EDIT',
  // ADMINISTRATION
  ADMINISTRATION = 'ADMINISTRATION',
  ADMINISTRATION_ORGANIZATIONS = 'ADMINISTRATION_ORGANIZATIONS',
  ADMINISTRATION_ORGANIZATIONS_CREATION = 'ADMINISTRATION_ORGANIZATIONS_CREATION',
  ADMINISTRATION_ORGANIZATION = 'ADMINISTRATION_ORGANIZATION',
  ADMINISTRATION_SETTINGS = 'ADMINISTRATION_SETTINGS',
  ADMINISTRATION_SUPER_ADMIN_USERS = 'ADMINISTRATION_SUPER_ADMIN_USERS',
  ADMINISTRATION_FEATURE_FLAGS = 'ADMINISTRATION_FEATURE_FLAGS',
  ADMINISTRATION_ARCHIVES = 'ADMINISTRATION_ARCHIVES',
  ADMINISTRATION_LOG = 'ADMINISTRATION_LOG',
}
