import cn from 'classnames';
import React, { ElementType, ReactNode } from 'react';

import { Typography } from '../typography';

import styles from './Tabs.module.scss';

interface TabProps extends VecticeHTMLProps<HTMLSpanElement> {
  active?: boolean;
  count?: number;
  extra?: ReactNode;
  hideDot?: boolean;
}

export const Tab = <E extends ElementType>({
  component = 'button',
  active,
  count,
  extra,
  hideDot,
  disabled,
  'aria-current': ariaCurrent,
  children,
  ...props
}: TabProps & WithComponentProps<E>) => (
  <li className={cn(styles.tab, { [styles.disabled]: disabled, [styles.active]: active })}>
    <Typography
      component={disabled ? 'span' : component}
      variant="callout"
      color={active ? 'accent' : disabled ? 'disabled' : 'secondary'}
      ellipsis
      type="button"
      aria-current={ariaCurrent || active}
      tabIndex={0}
      disabled={disabled}
      {...props}
    >
      {children}
      {!!extra && (
        <>
          {!hideDot && <span>·</span>}
          <span>{extra}</span>
        </>
      )}
      {count !== undefined && (
        <>
          <span>·</span>
          <span>{count}</span>
        </>
      )}
    </Typography>
  </li>
);
