import { useQuery } from '@apollo/client';
import cn from 'classnames';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import type { Placement, TypographyVariants } from '../../ui';

import { ReactComponent as ArrowIcon } from '../../assets/icons/interface/ic-chevron-down.svg';
import { ReactComponent as StarredIcon } from '../../assets/icons/interface/ic-star.svg';
import { ReactComponent as DatasetVersionIcon } from '../../assets/icons/specials/ic-dataset-version.svg';
import { graphql } from '../../gql';
import { buildOrder } from '../../graphql/utils';
import { buildLink, VecticeRoutes } from '../../routes';
import {
  Divider,
  Dropdown,
  EmptySelect,
  FilterSearch,
  FlexContainer,
  Icon,
  Menu,
  MenuItem,
  Typography,
  WithLoading,
} from '../../ui';

import styles from './DatasetVersionPicker.module.scss';

export const GET_DATASET_VERSION_PICKER_LIST = graphql(`
  query getDatasetVersionPickerList(
    $datasetId: VecticeId!
    $filters: DataSetVersionFilterInput
    $order: ListOrderInput
  ) {
    getDatasetVersionsList(datasetId: $datasetId, filters: $filters, order: $order) {
      items {
        vecticeId
        versionNumber
        isStarred
      }
    }
  }
`);

interface DatasetVersionPickerProps {
  datasetId: string;
  datasetVersionId: string;
  datasetVersionNumber: number;
  dropdownClassName?: string;
  dropdownPlacement?: Placement;
  showStarredFirst?: boolean;
  triggerVariant?: TypographyVariants;
  unavailableAssetIds?: (string | undefined)[];
  onSelect?: (datasetVersionId: string) => void;
}

export const DatasetVersionPicker = ({
  datasetId,
  datasetVersionId: selectedVersionId,
  datasetVersionNumber,
  dropdownClassName,
  dropdownPlacement,
  showStarredFirst,
  triggerVariant = 'heading2',
  unavailableAssetIds,
  onSelect,
}: DatasetVersionPickerProps) => {
  const [search, setSearch] = useState('');
  const [showNavigation, setShowNavigation] = useState(false);

  const { data, loading } = useQuery(GET_DATASET_VERSION_PICKER_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      datasetId,
      filters: { search },
      order: buildOrder(
        showStarredFirst ? [{ field: 'isStarred' }, { field: 'createdDate' }] : { field: 'createdDate' },
      ),
    },
  });
  const versions = data?.getDatasetVersionsList.items;

  const menuItemProps = useMemo(
    () =>
      onSelect
        ? (datasetVersionId: string) => ({ component: undefined, to: '', onClick: () => onSelect(datasetVersionId) })
        : (datasetVersionId: string) => ({
            component: Link,
            to: buildLink(VecticeRoutes.DATASET_VERSION, { datasetVersionId }),
          }),
    [onSelect],
  );

  const pickerMenu = (
    <div className={cn(styles.menu, dropdownClassName)}>
      <FilterSearch
        initialSearch={search}
        placeholder={$t({ id: 'DatasetVersionPicker.searchPlaceholder', defaultMessage: 'Search a version' })}
        onSearch={setSearch}
        autoFocus
      />
      <Divider className={styles.divider} component="div" />
      <WithLoading loading={loading}>
        {versions && (
          <Menu aria-label="Dataset version picker" className={styles.menu_content}>
            {!versions.length && (
              <EmptySelect text={$t({ id: 'DatasetVersionPicker.noVersion', defaultMessage: 'No versions to show' })} />
            )}
            {versions.length > 0 &&
              versions.map(({ vecticeId: datasetVersionId, versionNumber, isStarred }) => (
                <MenuItem
                  key={datasetVersionId}
                  clickable
                  color="secondary"
                  leftIcon={DatasetVersionIcon}
                  disabled={
                    datasetVersionId === selectedVersionId ||
                    unavailableAssetIds?.some((vecticeId) => vecticeId === datasetVersionId)
                  }
                  {...menuItemProps(datasetVersionId)}
                >
                  {$t({
                    id: 'DatasetVersionPicker.version',
                    defaultMessage: 'Version {versionNumber}',
                    values: { versionNumber },
                  })}
                  {isStarred && <Icon icon={StarredIcon} size={12} className={styles.starred} />}
                </MenuItem>
              ))}
          </Menu>
        )}
      </WithLoading>
    </div>
  );

  return (
    <Dropdown
      overlay={pickerMenu}
      placement={dropdownPlacement}
      trigger="click"
      visible={showNavigation}
      onVisibleChange={setShowNavigation}
    >
      <FlexContainer className={styles.trigger} role="button">
        <Typography color="primary" variant={triggerVariant} weight="semi-bold">
          {$t({
            id: 'DatasetVersionPicker.version',
            defaultMessage: 'Version {versionNumber}',
            values: { versionNumber: datasetVersionNumber },
          })}
        </Typography>
        <Icon className={styles.icon} icon={ArrowIcon} size={20} />
      </FlexContainer>
    </Dropdown>
  );
};
