import cn from 'classnames';
import React from 'react';

import { getArray } from '../../utils';

import { Main } from './Main';

import styles from './Content.module.scss';

type ContainerAlignment = 'left' | 'center';

interface ContainerProps extends Omit<VecticeHTMLProps<HTMLDivElement>, 'size'> {
  align?: ContainerAlignment;
  fullscreen?: boolean;
}

export const Content = ({ align = 'center', fullscreen, className, children, ...props }: ContainerProps) => {
  const childrenArray = getArray<any>(children);

  const hasMain = childrenArray?.find((child) => child?.type?.name === Main.name);

  if (hasMain) {
    return (
      <div className={cn(styles.content, styles[align], className)} {...props}>
        {children}
      </div>
    );
  }

  return (
    <div className={cn(styles.content, styles[align], className)} {...props}>
      <Main fullscreen={fullscreen}>{children}</Main>
    </div>
  );
};
