import { useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as ModelIcon } from '../../../assets/icons/specials/flat/ic-model.svg';
import { graphql } from '../../../gql';
import { AssetTooltip, DataList, Tooltip, Typography } from '../../../ui';
import { getVecticeResourceTypeLabel, VecticeResourceType } from '../../../utils';
import { FormatDate, FormatUserName } from '../../formatters';
import { ModelType } from '../../model';

export const GET_MODEL_TOOLTIP = graphql(`
  query getModelTooltip($modelId: VecticeId!) {
    getModelById(modelId: $modelId) {
      vecticeId
      name
      type
      versionCount
      createdBy {
        ...userFields
      }
      createdDate
    }
  }
`);

interface ModelTooltipProps {
  id?: string | null;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

let globalId = 0;

export const ModelTooltip = ({ id, placement = 'top', children }: ModelTooltipProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`model-tooltip-${globalId++}`);
  const [skipQuery, setSkipQuery] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data, error } = useQuery(GET_MODEL_TOOLTIP, {
    skip: skipQuery || !id,
    variables: {
      modelId: id!,
    },
  });

  const model = data?.getModelById;

  useEffect(() => {
    setDataLoaded(!!model || !!error);
  }, [model, error]);

  const handleVisibleChange = useCallback((state: boolean) => {
    setSkipQuery(false);
    setVisible(state);
  }, []);

  if (!id) {
    return children;
  }

  const overlay = (
    <AssetTooltip
      ariaId={ariaId}
      icon={ModelIcon}
      resourceTypeLabel={getVecticeResourceTypeLabel(VecticeResourceType.MODEL)}
      label={model?.name}
      deleted={!!error}
    >
      {model && (
        <DataList>
          <Typography id={`created-${ariaId}`} component="dt" variant="callout" color="white">
            {$t({ id: 'tooltip.model.created.label', defaultMessage: 'Created' })}
          </Typography>
          <Typography aria-labelledby={`created-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
            {$t({
              id: 'tooltip.model.created.value',
              defaultMessage: '{date} by {author}',
              values: {
                date: <FormatDate date={model.createdDate} fromNow />,
                author: <FormatUserName user={model.createdBy} />,
              },
            })}
          </Typography>
          <Typography id={`type-${ariaId}`} component="dt" variant="callout" color="white">
            {$t({ id: 'tooltip.model.type.label', defaultMessage: 'Model type' })}
          </Typography>
          <Typography aria-labelledby={`type-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
            <ModelType modelType={model.type} noColor />
          </Typography>
          <Typography id={`versions-${ariaId}`} component="dt" variant="callout" color="white">
            {$t({ id: 'tooltip.model.version.label', defaultMessage: 'Versions' })}
          </Typography>
          <Typography aria-labelledby={`versions-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
            {model.versionCount}
          </Typography>
        </DataList>
      )}
    </AssetTooltip>
  );

  return (
    <Tooltip
      id={ariaId}
      overlay={overlay}
      visible={visible && dataLoaded}
      onVisibleChange={handleVisibleChange}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
