import { useMutation } from '@apollo/client';
import cn from 'classnames';
import React, { useRef } from 'react';

import type { ButtonProps } from '../../ui/button/Button';

import {
  getIterationStatusColor,
  getIterationStatusDisplayName,
  getIterationStatusOptions,
} from '../../entities/utils';
import { graphql } from '../../gql';
import { Iteration, IterationStatus as IterationStatusEnum } from '../../gql/graphql';
import { Dropdown, DropdownTrigger, Menu, MenuItem } from '../../ui';
import { getIterationStatusIcon } from '../../utils';
import { IterationStatusBadge } from '../badges';

import styles from './AssetStatus.module.scss';

export const UPDATE_ITERATION_STATUS = graphql(`
  mutation updateIterationStatus($id: VecticeId!, $status: IterationStatus!) {
    updateIteration(id: $id, data: { status: $status }) {
      vecticeId
      status
    }
  }
`);

interface IterationStatusProps extends ButtonProps {
  iterationId?: string;
  status?: IterationStatusEnum;
}

export const IterationStatus = ({ iterationId, status, className, ...props }: IterationStatusProps) => {
  const { current: statusOptions } = useRef(getIterationStatusOptions());

  const [updateIteration] = useMutation(UPDATE_ITERATION_STATUS, {
    optimisticResponse: ({ id, status }) => ({
      updateIteration: {
        vecticeId: id.toString(),
        status,
        __typename: 'Iteration' as Iteration['__typename'],
      },
    }),
  });

  const handleStatusUpdate = (updatedStatus: IterationStatusEnum) => async () => {
    if (iterationId) {
      await updateIteration({
        variables: {
          id: iterationId,
          status: updatedStatus,
        },
      });
    }
  };

  const menu = (
    <Menu aria-label="Iteration Status Menu">
      {statusOptions.map((option) => (
        <MenuItem key={option.value} selected={status === option.value} onClick={handleStatusUpdate(option.value)}>
          <IterationStatusBadge status={option.value} contrast />
        </MenuItem>
      ))}
    </Menu>
  );

  if (!status) {
    return null;
  }

  return (
    <Dropdown overlay={menu} trigger="click" minOverlayWidthMatchTrigger>
      <DropdownTrigger
        variant="primary"
        aria-label="Iteration status select"
        leftIcon={getIterationStatusIcon(status)}
        className={cn(styles[getIterationStatusColor(status)], className)}
        {...props}
      >
        {getIterationStatusDisplayName(status)}
      </DropdownTrigger>
    </Dropdown>
  );
};
