import { useQuery } from '@apollo/client';
import React, { HTMLProps, useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Lineage, ListOrderInput, ModelVersion, ModelVersionStatus, OrderDirection } from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import {
  buildAdvancedDateFilter,
  buildAdvancedEntitiesFilter,
  buildAdvancedIntegerFilter,
  buildAdvancedNameFilter,
} from '../../graphql/utils';
import { useWithFeatureFlags, useWithRoles } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { useStarModelVersion } from '../../hooks';
import { buildLink, VecticeRoutes } from '../../routes';
import {
  AutoLinkText,
  Column,
  FlexContainer,
  NestedKeyInput,
  Pagination,
  sanitizeColumnKey,
  SearchInput,
  Table,
  Tooltip,
  Typography,
} from '../../ui';
import { isNumber, round } from '../../utils';
import { UserAvatar } from '../asset-display';
import { ModelVersionStatusBadge } from '../badges';
import { StarringButton } from '../buttons';
import { LineageCell } from '../cells';
import {
  AlgorithmFilter,
  CreatedByFilter,
  CreatedDateFilter,
  FilterComposer,
  MetricsFilter,
  ModelVersionStatusFilter,
  PropertiesFilter,
  StarredFilter,
} from '../filters';
import { FormatDate } from '../formatters';
import { NoModelVersions, NoResults } from '../no-results';

import { GET_METRIC_NAMES } from './getMetricNames.query';
import { GET_MODEL_VERSIONS_LIST } from './getModelVersionsList.query';
import { GET_PROPERTY_NAMES } from './getPropertyNames.query';

import styles from './ModelVersionsTable.module.scss';

const PAGE_SIZE = 30;

const defaultOrder = {
  field: 'createdDate',
  direction: OrderDirection.Desc,
};

interface Props {
  borderless?: boolean;
  initialFilters?: Record<string, any>;
  initialOrder?: ListOrderInput;
  lessPaginationItems?: boolean;
  modelId?: string;
  nbOfVisibleFilterPills?: number;
  pageSize?: number;
  scrollable?: boolean;
  workspaceId?: string;
  linkTarget?: HTMLProps<HTMLLinkElement>['target'];
  onFiltersChange?: (filters: Record<string, any>) => void;
  onOrderChange?: (order?: ListOrderInput) => void;
}

export const ModelVersionsTable = ({
  borderless,
  initialFilters = {},
  initialOrder = defaultOrder,
  lessPaginationItems,
  modelId,
  nbOfVisibleFilterPills,
  pageSize = PAGE_SIZE,
  scrollable,
  workspaceId,
  linkTarget,
  onFiltersChange,
  onOrderChange,
}: Props) => {
  const workspaceIdList = useMemo(() => (workspaceId ? [workspaceId] : null), [workspaceId]);
  const { current: modelIds } = useRef(modelId ? [modelId] : []);

  const { allowed: canUpdate } = useWithRoles({
    workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS,
  });
  const { allowed: modelVersionInformation } = useWithFeatureFlags({
    featureFlag: 'view-inventory-information-in-model-version-list-page',
  });

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<Record<string, any>>(initialFilters);
  const [order, setOrder] = useState<ListOrderInput | undefined>(initialOrder);
  const [page, setPage] = useState(1);

  const { data: propertiesData, loading: loadingProperties } = useQuery(GET_PROPERTY_NAMES, {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    skip: !modelId,
    variables: { modelId: modelId! },
  });

  const { data: metricsData, loading: loadingMetrics } = useQuery(GET_METRIC_NAMES, {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    skip: !modelId,
    variables: { modelId: modelId! },
  });

  const { data: modelVersionData, loading: loadingVersions } = useQuery(GET_MODEL_VERSIONS_LIST, {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    skip: !modelId,
    variables: {
      modelId: modelId!,
      ...filters,
      advancedFilters: [
        ...buildAdvancedDateFilter(filters?.createdDate),
        ...buildAdvancedEntitiesFilter(filters?.metrics, 'metrics'),
        ...buildAdvancedEntitiesFilter(filters?.properties, 'properties'),
        ...buildAdvancedNameFilter(filters?.name),
        ...(isNumber(search) ? buildAdvancedIntegerFilter('versionNumber', [+search]) : []),
      ],
      pageIndex: page,
      pageSize,
      order,
    },
  });

  const versions = modelVersionData?.getModelVersionsList?.items;
  const total = modelVersionData?.getModelVersionsList?.total;
  const properties = propertiesData?.getPropertyKeysOfEntity;
  const metrics = metricsData?.getMetricKeysOfEntity;

  const loading = loadingMetrics || loadingProperties || loadingVersions;

  const { updateStarModelVersion } = useStarModelVersion();

  const handleStarChange = (modelVersionId: string, newState: boolean) =>
    updateStarModelVersion({
      variables: {
        resource: {
          resourceId: modelVersionId,
          isStarred: newState,
        },
      },
    });

  const onColumnSort = useCallback(
    (field: string, direction: OrderDirection, nestedKey?: NestedKeyInput) => {
      setOrder({
        field,
        direction,
        nestedKey,
      });
      onOrderChange?.({
        field,
        direction,
        nestedKey,
      });
    },
    [onOrderChange],
  );

  const onSearchUpdate = useCallback((value: string) => {
    setPage(1);
    setSearch(value);
  }, []);

  const handleFiltersChange = useCallback(
    (value: Record<string, any>) => {
      setFilters(value);
      onFiltersChange?.(value);
      setPage(1);
    },
    [onFiltersChange],
  );

  const formattedVersions = useMemo(() => {
    const items = versions || [];
    return items.map((modelVersion) => ({
      ...modelVersion,
      metrics: modelVersion.metrics.reduce<Record<string, string>>(
        // @ts-expect-error Issue with the current use of Fragments
        (acc, { key, value }) => ({
          ...acc,
          [sanitizeColumnKey(key)]: value,
        }),
        {},
      ),
      properties: modelVersion.properties.reduce<Record<string, string>>(
        // @ts-expect-error Issue with the current use of Fragments
        (acc, { key, value }) => ({
          ...acc,
          [sanitizeColumnKey(key)]: value,
        }),
        {},
      ),
    }));
  }, [versions]);

  const hasFilters = !!Object.keys(filters).length || !!search;

  return (
    <>
      <FlexContainer align="flex-end" className={styles.filters} gap={16}>
        <SearchInput
          aria-label="Search"
          className={styles.search}
          placeholder={$t({
            id: 'modelVersions.versionsTable.search.placeholder',
            defaultMessage: 'Search by version number',
          })}
          type="number"
          min={0}
          onDebouncedChange={onSearchUpdate}
        />
        <FilterComposer
          filters={filters}
          nbOfVisiblePills={nbOfVisibleFilterPills}
          onFiltersUpdate={handleFiltersChange}
        >
          {workspaceIdList && <AlgorithmFilter workspaceIdList={workspaceIdList} modelIdList={modelIds} />}
          <CreatedDateFilter />
          {workspaceIdList && <CreatedByFilter workspaceIdList={workspaceIdList} />}
          <MetricsFilter entitiesNames={metrics || []} />
          <PropertiesFilter entitiesNames={properties || []} />
          <StarredFilter />
          <ModelVersionStatusFilter />
        </FilterComposer>
      </FlexContainer>
      <Table
        aria-label="Model version table"
        data={formattedVersions}
        rowKey="vecticeId"
        initialSort={{ key: initialOrder.field, direction: initialOrder.direction }}
        scroll={{ x: true, y: scrollable }}
        loading={loading}
        onSort={onColumnSort}
        emptyText={hasFilters ? <NoResults borderless /> : <NoModelVersions borderless />}
        borderless={borderless}
      >
        <Column key="isStarred" title="" fixed="left" width={52}>
          {(isStarred: boolean, { vecticeId }: ModelVersion) => (
            <StarringButton
              inert={!canUpdate}
              starred={isStarred}
              onChange={canUpdate ? (value) => handleStarChange(vecticeId, value) : undefined}
            />
          )}
        </Column>
        <Column
          key="versionNumber"
          title={$t({ id: 'modelVersions.versionsTable.version', defaultMessage: 'Version' })}
          fixed="left"
          sortable
        >
          {(versionNumber: string, { vecticeId }: ModelVersion) => (
            <Typography
              component={Link}
              className={styles.version}
              to={buildLink(VecticeRoutes.MODEL_VERSION, { modelVersionId: vecticeId })}
              variant="callout"
              weight="semi-bold"
              color="primary"
              target={linkTarget ?? '_self'}
              ellipsis
              link
            >
              {$t({
                id: 'modelVersions.versionsTable.version.value',
                defaultMessage: 'Version {versionNumber}',
                values: { versionNumber: versionNumber },
              })}
            </Typography>
          )}
        </Column>
        <Column
          key="status"
          title={$t({ id: 'modelVersions.versionsTable.status', defaultMessage: 'Status' })}
          sortable
          width={150}
        >
          {(status: ModelVersionStatus) => <ModelVersionStatusBadge status={status} />}
        </Column>
        <Column
          key="algorithmName"
          title={$t({ id: 'modelVersions.versionsTable.technique', defaultMessage: 'Technique' })}
          width={100}
          ellipsis
          sortable
        />
        {modelVersionInformation && (
          <Column
            key="inventoryReference"
            sortable
            ellipsis
            title={$t({
              id: 'modelVersions.versionsTable.inventoryReference',
              defaultMessage: 'Model inventory ID',
            })}
            width={190}
          >
            {(inventoryReference: string | null) =>
              inventoryReference && (
                <Tooltip text={inventoryReference} placement="top">
                  <Typography>
                    <AutoLinkText text={inventoryReference} />
                  </Typography>
                </Tooltip>
              )
            }
          </Column>
        )}
        <Column
          key="origin"
          title={$t({ id: 'modelVersions.versionsTable.lineage', defaultMessage: 'Lineage' })}
          width={70}
          align="center"
          ellipsis
        >
          {(origin: Lineage | null) => (
            <span className={styles.lineage}>
              <LineageCell runId={origin?.id} />
            </span>
          )}
        </Column>
        <Column
          key="createdDate"
          title={$t({ id: 'modelVersions.versionsTable.createdDate', defaultMessage: 'Created date' })}
          sortable
          width={130}
          ellipsis
        >
          {(createdDate: Date) => <FormatDate date={createdDate} />}
        </Column>
        <Column
          key="createdBy"
          title={$t({ id: 'modelVersions.versionsTable.creator', defaultMessage: 'Creator' })}
          align="center"
          sortable="createdBy.name"
          width={70}
          ellipsis
        >
          {(createdBy: UserIdentity) => (
            <span className={styles.creator}>
              <UserAvatar user={createdBy} size="sm" />
            </span>
          )}
        </Column>
        {!!metrics?.length &&
          metrics.map((key) => (
            <Column
              key={`metrics.${sanitizeColumnKey(key)}`}
              onHeaderCell={() => ({
                title: key,
                prefix: 'Metric',
              })}
              title={key}
              align="right"
              sortable="metrics.value"
              nestedKey={{ field: 'key', value: key }}
              ellipsis
            >
              {(data?: number | null) => <span className={styles.metric}>{round(data) ?? '-'}</span>}
            </Column>
          ))}
        {!!properties?.length &&
          properties?.map((key) => (
            <Column
              key={`properties.${sanitizeColumnKey(key)}`}
              onHeaderCell={() => ({
                title: key,
                prefix: 'Property',
              })}
              title={key}
              sortable="properties.value"
              nestedKey={{ field: 'key', value: key }}
              ellipsis
            >
              {(data?: string | null) => (
                <Typography ellipsis className={styles.property}>
                  {data ?? '-'}
                </Typography>
              )}
            </Column>
          ))}
      </Table>
      <Pagination
        total={total}
        current={page}
        pageSize={pageSize}
        onChange={setPage}
        showLessItems={lessPaginationItems}
      />
    </>
  );
};
