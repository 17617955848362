import { graphql } from '../../gql';

export const UPDATE_FINDING_NAME = graphql(`
  mutation updateFinding($id: Float!, $data: FindingUpdateInput!) {
    updateFinding(id: $id, data: $data) {
      id
      name
    }
  }
`);

export const UPDATE_FINDING_OWNER = graphql(`
  mutation updateFindingOwner($id: Float!, $ownerId: Float!) {
    updateFinding(id: $id, data: { ownerId: $ownerId }) {
      id
      ownerId
      owner {
        id
        ...userFields
        disabled
      }
    }
  }
`);

export const UPDATE_FINDING_ASSIGNEE = graphql(`
  mutation updateFindingAssignee($id: Float!, $assigneeId: Float!) {
    updateFinding(id: $id, data: { reviewerId: $assigneeId }) {
      id
      ownerId
      reviewer {
        id
        ...userFields
        disabled
      }
    }
  }
`);

export const UPDATE_FINDING_STATUS = graphql(`
  mutation updateFindingStatus($id: Float!, $status: FindingStatus!) {
    updateFinding(id: $id, data: { status: $status }) {
      id
      status
    }
  }
`);

export const UPDATE_FINDING_SEVERITY = graphql(`
  mutation updateFindingSeverity($id: Float!, $severity: FindingSeverity!) {
    updateFinding(id: $id, data: { severity: $severity }) {
      id
      severity
    }
  }
`);

export const UPDATE_FINDING_MODEL_VERSION = graphql(`
  mutation updateFindingModelVersion($id: Float!, $modelVersionId: VecticeId) {
    updateFinding(id: $id, data: { modelVersionId: $modelVersionId }) {
      id
      modelVersion {
        vecticeId
        ...modelVersionInformationFields
      }
    }
  }
`);

export const UPDATE_FINDING_REPORT = graphql(`
  mutation updateFindingReport($id: Float!, $reportId: Float) {
    updateFinding(id: $id, data: { reportId: $reportId }) {
      id
      report {
        id
        name
      }
    }
  }
`);

export const UPDATE_FINDING_DUE_DATE = graphql(`
  mutation updateFindingDueDate($id: Float!, $date: DateTime) {
    updateFinding(id: $id, data: { dueDate: $date }) {
      id
      dueDate
    }
  }
`);
