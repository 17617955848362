import { useQuery } from '@apollo/client';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import type { AssetSelectorProps } from './interfaces';

import { ReactComponent as RemoveIcon } from '../../assets/icons/interface/ic-remove.svg';
import { ReactComponent as PhaseIcon } from '../../assets/icons/specials/ic-phase.svg';
import { OrderDirection, Phase } from '../../gql/graphql';
import { GET_PHASE_BY_ID, GET_PHASE_LIST } from '../../graphql/queries';
import { buildAdvancedExcludedVecticeIdFilter } from '../../graphql/utils';
import { Breadcrumb, Breadcrumbs, Button, Dropdown, FlexContainer, MenuItem, Typography } from '../../ui';
import { MenuWithSearch } from '../../ui-presets';
import { getVecticeResourceTypeIcon, isNil } from '../../utils';

import styles from './AssetSelector.module.scss';

type SelectedPhase = Pick<Phase, 'vecticeId' | 'name'>;

export const PhaseSelector = ({
  assetType,
  canClearAsset = true,
  projectId,
  phaseId,
  selectedAssetId,
  triggerClassName,
  unavailableAssetIds,
  onSelect,
}: AssetSelectorProps) => {
  const [selectedVersion, setSelectedVersion] = useState<SelectedPhase>();
  const [search, setSearch] = useState<string | undefined>(undefined);

  const { data: phaseData, loading: loadingPhases } = useQuery(GET_PHASE_LIST, {
    skip: !projectId,
    variables: {
      filters: {
        search,
        parentId: projectId!,
        advancedFilters: [...buildAdvancedExcludedVecticeIdFilter(unavailableAssetIds)],
      },
      order: {
        field: 'index',
        direction: OrderDirection.Asc,
      },
      page: {
        size: 100,
      },
    },
  });
  const options = phaseData?.getPhaseList.items;

  useQuery(GET_PHASE_BY_ID, {
    skip: !selectedAssetId,
    variables: { id: selectedAssetId! },
    onCompleted: (data) => setSelectedVersion(data.getPhaseById),
  });

  useEffect(() => {
    if (isNil(selectedAssetId)) {
      setSelectedVersion(undefined);
    }
    if (selectedAssetId === undefined) onSelect(phaseId);
  }, [selectedAssetId]);

  const clearAsset = () => {
    setSelectedVersion(undefined);
    onSelect(null);
  };

  const overlay = (
    <MenuWithSearch search={search} onSearch={setSearch} loading={loadingPhases} isEmpty={!options || !options.length}>
      {options?.map((phase) => (
        <MenuItem
          key={`option-${phase.vecticeId}`}
          leftIcon={PhaseIcon}
          onClick={() => {
            setSelectedVersion(phase);
            onSelect(phase.vecticeId);
          }}
        >
          <FlexContainer>
            <Typography variant="callout">{phase.name}</Typography>
          </FlexContainer>
        </MenuItem>
      ))}
    </MenuWithSearch>
  );

  if (!loadingPhases && search === '' && options?.length === 0) {
    return (
      <Typography className={styles.emptyAssetSelector} variant="callout" color="disabled" weight="semi-bold">
        {$t({ id: 'assetSelector.phaseSelector.empty', defaultMessage: 'No assets available' })}
      </Typography>
    );
  }

  return (
    <>
      {!selectedVersion && (
        <Dropdown
          overlay={overlay}
          trigger={['click']}
          placement="bottomRight"
          onVisibleChange={(visible) => {
            if (!visible) setSearch('');
          }}
        >
          <Button aria-label="Select asset" className={cn(styles.trigger, triggerClassName)} variant="white">
            <Typography ellipsis color="tertiary">
              {$t({ id: 'assetSelector.selectAPhase', defaultMessage: 'Select a phase' })}
            </Typography>
          </Button>
        </Dropdown>
      )}
      {selectedVersion && (
        <FlexContainer gap={18}>
          <Breadcrumbs>
            <Breadcrumb
              aria-label={selectedVersion.name}
              color="primary"
              icon={getVecticeResourceTypeIcon(assetType)}
              iconSize={20}
              inert
              weight="regular"
            >
              {selectedVersion.name}
            </Breadcrumb>
          </Breadcrumbs>
          {canClearAsset && (
            <Button
              aria-label="Clear asset"
              color="gray"
              leftIcon={RemoveIcon}
              size="xxs"
              variant="phantom"
              onClick={clearAsset}
            />
          )}
        </FlexContainer>
      )}
    </>
  );
};
