import React from 'react';

import { getModelTypeDisplayName } from '../../entities/utils';
import { ModelType as ModelTypeEnum } from '../../gql/graphql';
import { Icon, Typography } from '../../ui';
import { getModelTypeIcon } from '../../utils';

import styles from './Model.module.scss';

export const ModelType = ({
  modelType,
  noColor = false,
}: {
  modelType?: ModelTypeEnum | string | null;
  noColor?: boolean;
}) => {
  const icon = getModelTypeIcon(modelType);

  return (
    <Typography variant="callout" color={!noColor ? 'tertiary' : undefined}>
      {icon && <Icon className={styles.icon} icon={icon} size={20} />}
      {getModelTypeDisplayName(modelType)}
    </Typography>
  );
};
