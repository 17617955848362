import { graphql } from '../../gql';

export const GET_ENTITY_METRICS = graphql(`
  query getEntityMetricsByParentId($entityId: VecticeId!, $pageSize: Int!, $pageIndex: Int!) {
    getResource: getEntityMetricsByParentId(
      entityId: $entityId
      entityType: modelVersion
      page: { size: $pageSize, index: $pageIndex }
      order: { field: "key", direction: ASC }
    ) {
      items {
        ...metricsFields
      }
      page {
        ...pageIndexFields
      }
      total
    }
  }
`);
