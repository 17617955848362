import { graphql } from '../../../gql';

export const GET_PHASE_LIST = graphql(`
  query getPhaseListForProjectOverview($parentId: VecticeId!, $order: ListOrderInput, $page: PageInput) {
    getPhaseList(filters: { parentId: $parentId }, order: $order, page: $page) {
      items {
        vecticeId
        ...phaseListItem
      }
    }
  }
`);
