import React from 'react';

import { getDatasetTypeDisplayName } from '../../entities/utils';
import { DatasetType } from '../../gql/graphql';
import { Badge, Icon } from '../../ui';
import { getDatasetTypeIcon } from '../../utils';

import styles from './DatasetTypeBadge.module.scss';

interface DatasetTypeBadgeProps {
  datasetType: DatasetType;
  contrast?: boolean;
}

export const DatasetTypeBadge = ({ datasetType, contrast }: DatasetTypeBadgeProps) => {
  const typeIcon = getDatasetTypeIcon(datasetType);

  return (
    <Badge size="sm" contrast={contrast}>
      {typeIcon && <Icon className={styles.icon} icon={typeIcon} size={12} />}
      {getDatasetTypeDisplayName(datasetType)}
    </Badge>
  );
};
