import { useMutation, useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { Controller, SubmitHandler } from 'react-hook-form';

import { ReactComponent as RadioCheckedIcon } from '../../assets/icons/interface/ic-radio-checked.svg';
import { ReactComponent as RadioIcon } from '../../assets/icons/interface/ic-radio.svg';
import { useOrgConfig } from '../../context';
import { getUserRoleDescription, getUserRoleDisplayName } from '../../entities/utils';
import { EmailDomain, OrganizationLevel, UserRole, WorkspaceType } from '../../gql/graphql';
import { CREATE_USER_INVITE, GET_WORKSPACES_TO_INVITE } from '../../graphql/queries';
import { useWithFeatureFlags, useWithRoles } from '../../guards';
import { useRetrievePageContext, useVecticeForm } from '../../hooks';
import {
  FlexContainer,
  Icon,
  message,
  ModalForm,
  SelectMultiple,
  SelectOption,
  SelectTags,
  Typography,
  WithLoading,
} from '../../ui';
import { EMAIL_FORMAT } from '../../utils';
import { UserInviteType } from '../modals/InvitesSummaryModal';

import styles from './InviteUserModal.module.scss';

interface CreateResourceModalProps {
  loading?: boolean;
  emailDomains: Pick<EmailDomain, 'domain'>[];
  onClose: () => void;
  onCreateInvite: (data: UserInviteType[], noWorkspaces: boolean) => void;
}

type ValueType = {
  value: string;
  label: string;
};

export interface FormData {
  emails: string[];
  role: UserRole;
  workspaces: ValueType[];
}

const allWorkspacesValue = 'All';
const MAX_INVITATIONS = 100;

export const InviteUserModal = ({ loading, emailDomains, onClose, onCreateInvite }: CreateResourceModalProps) => {
  const config = useOrgConfig();
  const { workspace } = useRetrievePageContext();
  const { loading: loadingRoles, allowed: asOrgAdmin } = useWithRoles({ organizationRole: UserRole.OrgAdmin });
  const { allowed: viewerFFIsOn } = useWithFeatureFlags({ featureFlag: 'viewer-role' });
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [search, setSearch] = useState('');

  const initialWorkspace = useMemo(() => {
    if (!!workspace) {
      if (workspace.type !== WorkspaceType.Public) return [{ label: workspace.name, value: workspace.vecticeId }];
    }
    return [];
  }, []);

  const { data } = useQuery(GET_WORKSPACES_TO_INVITE, {
    variables: {
      search,
    },
    onError: (error) => message.error(error.message),
  });
  const userWorkspaces = data?.getUserWorkspaceListToInvite.items || [];

  const [createUserInvitation] = useMutation(CREATE_USER_INVITE);

  const { control, formState, preSubmit, trigger } = useVecticeForm<FormData>({
    defaultValues: {
      emails: [],
      role: UserRole.Member,
      workspaces: initialWorkspace,
    },
  });
  const { dirtyFields, hasErrors } = formState;

  const onSubmit: SubmitHandler<FormData> = async ({ role, emails, workspaces }: FormData) => {
    const userEmails: string[] = emails.filter((email) => email !== undefined);
    setLoadingInvite(true);

    if (userEmails.length > 0) {
      await createUserInvitation({
        variables: {
          data: {
            userEmails,
            role,
            workspaceIds: workspaces.some((workspace) => workspace.value === allWorkspacesValue)
              ? undefined
              : workspaces.map((workspace) => workspace.value),
          },
        },
        onCompleted: ({ createUserInvite }) => {
          const invites = createUserInvite.filter((invite) => !!invite);
          onCreateInvite(invites, workspaces.length === 0);
          setLoadingInvite(false);
        },
        onError: (error) => message.error(error.message),
      });
    }
  };

  const organizationDomains = emailDomains?.map(({ domain }) => domain);

  const validateEmail = (emails: string[]) => {
    if (!emails?.length) {
      return $t({ id: 'organization.emailRequired', defaultMessage: 'At least one email is required' });
    }

    if (emails.length >= MAX_INVITATIONS)
      return $t({
        id: 'organization.maxInvitationsReached',
        defaultMessage: 'You cannot generate more than {invite_number} invitations',
        values: { invite_number: MAX_INVITATIONS },
      });

    const invalidEmail = emails.find((email) => !EMAIL_FORMAT.exec(email));
    if (invalidEmail) {
      return $t({
        id: 'organization.invalidEmail',
        defaultMessage: '{email} is not a valid email',
        values: { email: invalidEmail },
      });
    }

    const unknownDomainEmail = emails.find((email) => {
      const [, domain] = email.split('@');
      return !organizationDomains.includes(domain);
    });
    if (unknownDomainEmail) {
      const domains = organizationDomains.join(', ');
      return $t({
        id: 'organization.emailDomainWarning',
        defaultMessage: '{email} does not match one of these domains: {domains}',
        values: { email: unknownDomainEmail, domains },
      });
    }

    return true;
  };

  const allWorkspacesOption = {
    key: allWorkspacesValue,
    value: allWorkspacesValue,
    label: $t({ id: 'organization.allExistingWorkspaces', defaultMessage: 'All existing workspaces' }),
  };

  const userRoleOptions = [
    ...(asOrgAdmin
      ? [
          {
            id: UserRole.OrgAdmin,
            value: UserRole.OrgAdmin,
            label: getUserRoleDisplayName(UserRole.OrgAdmin),
            description: getUserRoleDescription(UserRole.OrgAdmin),
          },
        ]
      : []),
    {
      id: UserRole.Member,
      value: UserRole.Member,
      label: getUserRoleDisplayName(UserRole.Member),
      description: getUserRoleDescription(UserRole.Member),
    },
    ...(viewerFFIsOn
      ? [
          {
            id: UserRole.Observer,
            value: UserRole.Observer,
            label: getUserRoleDisplayName(UserRole.Observer),
            description: getUserRoleDescription(UserRole.Observer),
          },
        ]
      : []),
  ];

  const workspaceOptions = useMemo(() => {
    const returnArray = [
      ...userWorkspaces.map((workspace) => ({
        key: workspace.vecticeId,
        value: workspace.vecticeId,
        label: workspace.name,
      })),
    ];
    if (asOrgAdmin && !search) returnArray.unshift(allWorkspacesOption);
    return returnArray;
  }, [userWorkspaces, asOrgAdmin, search]);

  if (!organizationDomains.length) {
    return null;
  }

  return (
    <ModalForm
      disabled={!dirtyFields.emails || hasErrors}
      isSubmitting={loadingInvite}
      title={$t({ id: 'invite.inviteUsers', defaultMessage: 'Invite Users' })}
      submitLabel={
        config.sendEmail
          ? $t({ id: 'invite.sendInvitation', defaultMessage: 'Send Invitation' })
          : $t({ id: 'invite.createInvitationLink', defaultMessage: 'Create Invitation Link' })
      }
      cancelLabel={$t({ id: 'buttton.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(onSubmit)}
      onClose={onClose}
    >
      <WithLoading loading={loading ?? loadingRoles}>
        <Controller
          control={control}
          name="emails"
          rules={{
            validate: validateEmail,
          }}
          render={({ field: { value: values, onChange, name }, fieldState: { error } }) => (
            <SelectTags
              name={name}
              className={styles.select}
              label={$t({ id: 'organization.emailAddresses', defaultMessage: 'Email Addresses' })}
              help={$t({
                id: 'organization.emailExample',
                defaultMessage: 'must match one of these domains: {domains}',
                values: {
                  domains: organizationDomains.join(', '),
                },
              })}
              options={[]}
              value={values}
              onSelect={(value) => {
                if (value && !values.find((v) => v === value)) {
                  onChange([...values, value]);
                  trigger('emails');
                }
              }}
              onDeselect={(value) => {
                onChange(values.filter((v) => v !== value));
                trigger('emails');
              }}
              open={false}
              error={error?.message}
              gutterBottom
            />
          )}
        />
        {(asOrgAdmin || viewerFFIsOn) && (
          <Controller
            control={control}
            name="role"
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange } }) => (
              <SelectMultiple<ValueType>
                label={$t({
                  id: 'workspace.orgRole.select.label',
                  defaultMessage: 'Select user role',
                })}
                value={{ label: value, value: getUserRoleDisplayName(value) }}
                onSelect={(_, option) => onChange(option.label)}
                onDeselect={(_value) => null}
                selectMultiple={false}
                gutterBottom
              >
                {userRoleOptions.map((option) => (
                  <SelectOption key={option.id} label={option.id} value={getUserRoleDisplayName(option.id)}>
                    <FlexContainer gap={16}>
                      <Icon icon={option.id === value ? RadioCheckedIcon : RadioIcon} size={20} />
                      <FlexContainer direction="column" gap={0}>
                        <Typography weight="semi-bold">{option.label}</Typography>
                        <Typography variant="footnote" color="tertiary">
                          {option.description}
                        </Typography>
                      </FlexContainer>
                    </FlexContainer>
                  </SelectOption>
                ))}
              </SelectMultiple>
            )}
          />
        )}
        <Controller
          control={control}
          name="workspaces"
          render={({ field: { value: values, onChange } }) => (
            <SelectMultiple<ValueType>
              label={$t({ id: 'organization.workspaces', defaultMessage: 'Workspaces' })}
              placeholder={$t({
                id: 'organization.workspaceSelectionPlaceholder',
                defaultMessage: 'Select workspaces',
              })}
              help={
                config.organization?.level !== OrganizationLevel.Trial
                  ? $t({
                      id: 'organization.workspaceSelectionHint',
                      defaultMessage: 'Created users will automatically join these selected workspaces.',
                    })
                  : null
              }
              searchValue={search}
              onSearch={(search) => setSearch(search)}
              value={values}
              options={workspaceOptions}
              onSelect={(_, value) => {
                if (
                  value &&
                  workspaceOptions.find((option) => option.value === value.value) &&
                  !values.find((v: ValueType) => v.value === value.value)
                ) {
                  setSearch('');
                  if (value.value !== allWorkspacesValue) {
                    onChange([
                      ...values.filter((value: ValueType) => value.value !== allWorkspacesValue),
                      { label: value.label, value: value.value },
                    ]);
                  } else {
                    onChange([{ label: value.label, value: value.value }]);
                  }
                }
              }}
              onDeselect={(value) => {
                onChange(values.filter((v: ValueType) => v.value !== value));
                setSearch('');
              }}
              gutterBottom
            />
          )}
        />
      </WithLoading>
    </ModalForm>
  );
};
