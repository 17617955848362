import React from 'react';

import { Checkbox, CheckboxGroup, FilterLabel } from '../../../ui';
import { CommonFilterProps } from '../types';

import styles from './Filters.module.scss';

export const CheckBoxFilterComponent = ({ filter, label, options, onFilterUpdate }: CommonFilterProps) => {
  return (
    <CheckboxGroup
      name={label || 'missing-label'}
      checkedValues={filter}
      onChange={(value) => onFilterUpdate(value.length ? value : undefined)}
    >
      {options?.map(({ icon, label, value }) => (
        <Checkbox
          key={value}
          label={<FilterLabel addonBefore={icon}>{label}</FilterLabel>}
          value={value}
          className={styles.option}
        />
      ))}
    </CheckboxGroup>
  );
};
