const { href, host, protocol, origin } = window.location;

// fallback for test env
if (window.__RUNTIME_CONFIG__ === undefined) window.__RUNTIME_CONFIG__ = { NODE_ENV: 'development' };

const {
  NODE_ENV,
  REACT_APP_USE_HTTP,
  REACT_APP_API_HOST,
  REACT_APP_KEYCLOAK_HOST,
  REACT_APP_API_REFERENCE_URL,
  REACT_APP_DOC_URL,
  REACT_APP_HOTJAR_SITE_ID,
  REACT_APP_HOTJAR_NONCE,
  REACT_APP_SENTRY_ENV,
  REACT_APP_SENTRY_DSN,
} = window.__RUNTIME_CONFIG__;

let httpProtocol;
let wsProtocol;

if (REACT_APP_USE_HTTP === 'true') {
  httpProtocol = 'http:';
  wsProtocol = 'ws:';
} else if (REACT_APP_USE_HTTP === 'false') {
  httpProtocol = 'https:';
  wsProtocol = 'wss:';
} else {
  httpProtocol = protocol;
  wsProtocol = protocol === 'http:' ? 'ws:' : 'wss:';
}

const serverHost = REACT_APP_API_HOST || host || '';
const keycloakHost = REACT_APP_KEYCLOAK_HOST || host || '';

const graphqlUrl = new URL('/graphql', `${httpProtocol}//${serverHost}`);
const restUrl = new URL('/api', `${httpProtocol}//${serverHost}`);
const wsUrl = new URL('/subscriptions', `${wsProtocol}//${serverHost}`);
const kcUrl = new URL('/auth', `${httpProtocol}//${keycloakHost}`);
const monacoUrl = new URL('/monaco', `${httpProtocol}//${serverHost}`);

const API_REFERENCE_URL = REACT_APP_API_REFERENCE_URL || 'https://api-docs.vectice.com/';
const API_URL_AUTOLOG = `${API_REFERENCE_URL}reference/vectice/autolog/`;
const AUTOLOG_VIDEO_URL = 'https://youtu.be/A2mmZLTliXk';
const CDT_VIDEO_URL = 'https://youtu.be/_mpSmZlg8uM';
const DOC_URL = REACT_APP_DOC_URL || 'https://docs.vectice.com/';
const DOC_URL_API_CHEATSHEET = `${DOC_URL}user-guides/connect-to-the-vectice-api/api-cheat-sheets-for-new-users/vectice-python-api-cheat-sheet`;
const DOC_URL_GETTING_STARTED = DOC_URL;
// FIXME: This link is not working, temporary using another link
// const DOC_URL_PRODUCT_TOUR = `${DOC_URL}introduction/interactive-product-tour/`;
const DOC_URL_PRODUCT_TOUR = `${DOC_URL}introduction/welcome-to-vectice-docs/`;
const DOC_URL_PYTHON_CHEATSHEET = `${DOC_URL}user-guides/connect-to-the-vectice-api/api-cheat-sheets-for-new-users/vectice-python-api-cheat-sheet`;
const DOC_URL_REVIEW = `${DOC_URL}user-guides/organize-workspaces-and-projects/phase-guides/request-reviews-and-feedback`;
const DOC_URL_R_CHEATSHEET = `${DOC_URL}user-guides/connect-to-the-vectice-api/api-cheat-sheets-for-new-users/vectice-r-api-cheat-sheet`;
const DOC_URL_START_YOUR_FREE_TRIAL = `${DOC_URL}quickstart/start-your-free-trial`;
const DOC_URL_VECTICE_FOR_BANKING = `${DOC_URL}introduction/vectice-for-banking`;
const PRODUCT_TOUR_VIDEO_URL = 'https://www.youtube.com/watch?v=STf39dKewVg';
const SUPERADMIN_TICKETS_URL = 'https://vectice-software.atlassian.net/browse/';
const SUPPORT_EMAIL = 'support@vectice.com';
const TOS_URL = 'https://www.vectice.com/privacy-and-terms-of-service';
const VALIDATION_NOTEBOOK_URL = 'https://github.com/vectice/validation-notebooks-repo/blob/develop/README.md';
const WALKTHROUGH_VIDEO_URL = 'https://youtu.be/NqVsPi23mpU';
const WORKSPACE_URL = `${DOC_URL}admin-guides/workspace-management`;

const config = {
  API_REFERENCE_URL: API_REFERENCE_URL,
  API_URL: href.includes('localhost') ? restUrl.href : restUrl.pathname,
  API_URL_AUTOLOG,
  APOLLO_URL: graphqlUrl.href,
  AUTH_URL: kcUrl.href,
  AUTOLOG_VIDEO_URL,
  CDT_VIDEO_URL,
  DEFAULT_DECIMAL_PRECISION: 3,
  DEFAULT_TRUNCATE_SIZE: 20,
  DOC_URL: DOC_URL,
  DOC_URL_API_CHEATSHEET,
  DOC_URL_GETTING_STARTED,
  DOC_URL_PRODUCT_TOUR,
  DOC_URL_PYTHON_CHEATSHEET,
  DOC_URL_REVIEW,
  DOC_URL_R_CHEATSHEET,
  DOC_URL_START_YOUR_FREE_TRIAL,
  DOC_URL_VECTICE_FOR_BANKING,
  ENVIRONMENT: NODE_ENV ?? 'development',
  HOTJAR: {
    siteId: REACT_APP_HOTJAR_SITE_ID ? parseInt(REACT_APP_HOTJAR_SITE_ID, 10) : undefined,
    siteNonce: REACT_APP_HOTJAR_NONCE,
    version: 6,
  },
  LIST_DEFAULT_SIZE: 20,
  MAXIMUM_ABOUT_LENGTH: 500,
  MAXIMUM_DESCRIPTION_LENGTH: 24000,
  MAXIMUM_EMAIL_LENGTH: 256,
  MAXIMUM_ID_LENGTH: 100,
  MAXIMUM_NAME_LENGTH: 60,
  MAXIMUM_PROMPT_LENGTH: 4000,
  MAXIMUM_REVIEW_LENGTH: 24000,
  MAXIMUM_THUMBNAIL_SIZE: 1024 * 1024,
  MAXIMUM_VALUE_LENGTH: 50000,
  MONACO_EDITOR_CDN: href.includes('localhost')
    ? 'https://cdn.jsdelivr.net/npm/monaco-editor@0.50.0/min'
    : monacoUrl.href,
  ORIGIN: origin,
  PRODUCT_TOUR_VIDEO_URL,
  PROMPT_LIST_DEFAULT_SIZE: 8,
  SUPERADMIN_TICKETS_URL,
  SCROLL_INTO_VIEW_OPTIONS: { behavior: 'smooth', block: 'center' } as ScrollIntoViewOptions,
  SENTRY: {
    enabled: (REACT_APP_SENTRY_ENV === 'qa' || REACT_APP_SENTRY_ENV === 'production') && !!REACT_APP_SENTRY_DSN,
    environment: REACT_APP_SENTRY_ENV ?? 'development',
    dsn: REACT_APP_SENTRY_DSN,
  },
  SUBSCRIPTIONS_URL: wsUrl.href,
  SUPPORT_EMAIL,
  TOS_URL,
  VALIDATION_NOTEBOOK_URL,
  WALKTHROUGH_VIDEO_URL,
  WORKSPACE_URL,
};

export default config;
