import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import type { ReactNode } from 'react';

import { Typography } from '../../ui';

import styles from './AssetNameCell.module.scss';

interface AssetNameCellProps {
  assetName: ReactNode;
  target?: string;
  to?: LinkProps['to'];
}

export const AssetNameCell = ({ assetName, to, target }: AssetNameCellProps) => (
  <Typography
    className={styles.asset_name}
    component={to ? Link : 'span'}
    link={!!to}
    target={target}
    to={to}
    variant="callout"
    weight="semi-bold"
  >
    {assetName}
  </Typography>
);
