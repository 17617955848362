import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { ReactComponent as AddIcon } from '../../assets/icons/interface/ic-add-circle.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/interface/ic-bin.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/interface/ic-edit.svg';
import { EntityPropertyType, EntityProperty } from '../../gql/graphql';
import {
  Button,
  Column,
  EmptySection,
  FlexContainer,
  Menu,
  MenuItem,
  MoreActions,
  Pagination,
  Section,
  Table,
  Typography,
  message,
} from '../../ui';
import { isNullOrWhiteSpace } from '../../utils';
import { DeletionPrompt, PropertyFormModal } from '../modals';

import { CREATE_PROPERTY } from './createProperty.mutation';
import { DELETE_PROPERTY } from './deleteProperty.mutation';
import { GET_ENTITY_PROPERTIES } from './getEntityProperties.query';
import { UPDATE_PROPERTY } from './updateProperty.mutation';

const PAGE_SIZE = 30;

interface IProps {
  resourceId: string;
  entityType: EntityPropertyType;
  readOnly?: boolean;
}

export const VersionProperties = ({ resourceId, entityType, readOnly }: IProps) => {
  const [selectedParameter, setSelectedParameter] = useState<EntityProperty>();
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const { data, loading: loadingFetch } = useQuery(GET_ENTITY_PROPERTIES, {
    fetchPolicy: 'no-cache',
    variables: {
      entityId: resourceId,
      entityType,
      pageIndex: currentPage,
      pageSize: PAGE_SIZE,
    },
    onError: () =>
      message.error($t({ id: 'VersionProperties.fetchError', defaultMessage: 'Oops, something went wrong!' })),
  });

  const [createProperty, { loading: loadingCreate }] = useMutation(CREATE_PROPERTY, {
    refetchQueries: ['getEntityPropertiesByParentId'],
    onCompleted: () => setShowModal(false),
    onError: (e) =>
      e.message.startsWith('The key already exists')
        ? message.error(
            $t({
              id: 'VersionProperties.existingNameError',
              defaultMessage: 'A property with the same name already exist!',
            }),
          )
        : message.error($t({ id: 'VersionProperties.updateError', defaultMessage: 'Oops, something went wrong!' })),
  });

  const [updateProperty, { loading: loadingUpdate }] = useMutation(UPDATE_PROPERTY, {
    refetchQueries: ['getEntityPropertiesByParentId'],
    onCompleted: () => setShowModal(false),
    onError: (e) =>
      e.message.startsWith('The key already exists')
        ? message.error(
            $t({
              id: 'VersionProperties.existingNameError',
              defaultMessage: 'A property with the same name already exist!',
            }),
          )
        : message.error($t({ id: 'VersionProperties.updateError', defaultMessage: 'Oops, something went wrong!' })),
  });

  const [deleteProperties, { loading: loadingDelete }] = useMutation(DELETE_PROPERTY, {
    refetchQueries: ['getEntityPropertiesByParentId'],
    onError: () =>
      message.error($t({ id: 'VersionProperties.deleteError', defaultMessage: 'Oops, something went wrong!' })),
  });

  const total = data?.getResource.total || 0;
  const properties = data?.getResource.items || [];
  const loading = loadingFetch || loadingCreate || loadingUpdate || loadingDelete;

  const handleCreate = async ({ key, value }: Pick<EntityProperty, 'key' | 'value'>) => {
    if (!loading) {
      if (isNullOrWhiteSpace(key) || isNullOrWhiteSpace(value)) {
        message.error($t({ id: 'VersionProperties.allFieldsRequired', defaultMessage: 'All the fields are required' }));
        return;
      }

      await createProperty({
        variables: {
          entityId: resourceId,
          entityType,
          createProperty: {
            key,
            value,
            timestamp: new Date(),
          },
        },
      });
    }
  };

  const handleUpdate = async ({ key, value }: Pick<EntityProperty, 'key' | 'value'>) => {
    if (selectedParameter && !loading) {
      const { id } = selectedParameter;
      if (isNullOrWhiteSpace(key) || isNullOrWhiteSpace(value)) {
        message.error($t({ id: 'VersionProperties.allFieldsRequired', defaultMessage: 'All the fields are required' }));
        return;
      }
      await updateProperty({
        variables: {
          entityType,
          updateProperty: {
            id,
            key,
            value,
          },
        },
      });
    }
  };

  const handleSelectionChange = (selection: EntityProperty[]) => {
    setSelectedRows(selection.map((row) => row.id));
  };

  const handleDelete = (record: EntityProperty) => {
    deleteProperties({
      variables: {
        entityType,
        propertyIds: [record.id],
      },
    });
  };

  const handleDeleteSelection = async () => {
    await deleteProperties({
      variables: {
        entityType,
        propertyIds: selectedRows,
      },
    });
    setSelectedRows([]);
  };

  const actions = (record: EntityProperty) => (
    <Menu>
      <MenuItem
        onClick={() => {
          setSelectedParameter(record);
          setShowModal(true);
        }}
        leftIcon={EditIcon}
      >
        {$t({ id: 'assets.versionProperties.actions.edit', defaultMessage: 'Edit property' })}
      </MenuItem>
      <MenuItem
        onClick={() => {
          setSelectedParameter(record);
          setShowDeletionModal(true);
        }}
        leftIcon={DeleteIcon}
      >
        {$t({ id: 'assets.versionProperties.actions.delete', defaultMessage: 'Delete property' })}
      </MenuItem>
    </Menu>
  );

  return (
    <Section
      label={(ariaId) => (
        <FlexContainer justify="space-between">
          <Typography id={ariaId} component="h2" color="primary" variant="callout" weight="semi-bold">
            {$t({ id: 'assets.versionProperties.tableTitle', defaultMessage: 'Version Properties' })}
          </Typography>
          {!readOnly && (
            <FlexContainer gap={0}>
              <Button
                color="danger"
                variant="outlined"
                size="sm"
                leftIcon={DeleteIcon}
                disabled={selectedRows.length === 0}
                onClick={() => {
                  setSelectedParameter(undefined);
                  setShowDeletionModal(true);
                }}
              >
                {$t({ id: 'button.delete', defaultMessage: 'Delete' })}
              </Button>
              <Button
                size="sm"
                variant="white"
                leftIcon={AddIcon}
                onClick={() => {
                  setSelectedParameter(undefined);
                  setShowModal(true);
                }}
              >
                {$t({ id: 'button.add', defaultMessage: 'Add' })}
              </Button>
            </FlexContainer>
          )}
        </FlexContainer>
      )}
    >
      <Table
        // @ts-expect-error Caused by our current Fragments
        data={properties}
        tableLayout="fixed"
        size="sm"
        rowKey="key"
        emptyText={
          <EmptySection
            message={$t({
              id: 'properties.empty.message',
              defaultMessage: 'To display properties, click the add button or use the Vectice API.',
            })}
          />
        }
        loading={loading}
        onSelectionChange={readOnly ? undefined : handleSelectionChange}
      >
        <Column key="key" title="Property" ellipsis />
        <Column key="value" title="Value" width="35%" ellipsis />
        {!readOnly && (
          <Column key="" title="" width="50px" align="right">
            {(_, record: EntityProperty) => <MoreActions moreActionsMenu={actions(record)} size="xs" />}
          </Column>
        )}
      </Table>
      <Pagination pageSize={PAGE_SIZE} current={currentPage} total={total} onChange={setCurrentPage} />

      {!readOnly && showModal && (
        <PropertyFormModal
          selectedProperty={selectedParameter}
          onClose={() => setShowModal(false)}
          onSubmit={selectedParameter ? handleUpdate : handleCreate}
        />
      )}

      {showDeletionModal && (
        <DeletionPrompt
          processing={loadingDelete}
          title={
            selectedParameter?.key
              ? $t({
                  id: 'VersionProperties.action.one',
                  defaultMessage: `Delete Version Property`,
                })
              : $t({
                  id: 'VersionProperties.action',
                  defaultMessage: `{count, plural, one {Delete Version Property} other {Delete Version Properties}}`,
                  values: { count: selectedRows.length },
                })
          }
          onClose={() => {
            setSelectedParameter(undefined);
            setShowDeletionModal(false);
          }}
          onConfirm={() => {
            if (selectedParameter) handleDelete(selectedParameter);
            else handleDeleteSelection();
            setSelectedParameter(undefined);
            setShowDeletionModal(false);
          }}
          resourceName={
            selectedParameter?.key ??
            $t({
              id: 'VersionProperties.resourceName',
              defaultMessage: `{count, plural, one {# Property} other {# Properties}}`,
              values: {
                count: selectedRows.length,
              },
            })
          }
        />
      )}
    </Section>
  );
};
