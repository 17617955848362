import React from 'react';

import { FragmentType, graphql, useFragment } from '../../gql';
import { DocumentationPageStatus as DocumentationPageStatusEnum } from '../../gql/graphql';
import { useWithRoles } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { RestrictedAccessTooltip, Skeleton } from '../../ui';
import { VecticeResourceType } from '../../utils';
import { AssetDetail, AssetDetails, CreationDetails, WithCopyID } from '../assets';
import { Contributors } from '../contributors';
import { DocumentationPageStatus, PhaseOwnerStatus } from '../status';

const PhaseSidebarFragment = graphql(`
  fragment PhaseSidebar on Phase {
    vecticeId
    status
    parent {
      vecticeId
      workspace {
        vecticeId
        type
      }
    }
    owner {
      id
      ...userFields
      disabled
    }
    createdDate
    createdBy {
      id
      ...userFields
    }
    updatedDate
    lastUpdatedBy {
      id
      ...userFields
    }
  }
`);

interface PhaseSidebarProps {
  loading?: boolean;
  phase?: FragmentType<typeof PhaseSidebarFragment>;
}

export const PhaseSidebar = ({ loading, phase: phaseFragment }: PhaseSidebarProps) => {
  const { allowed: canEdit } = useWithRoles({ workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS });
  const phase = useFragment(PhaseSidebarFragment, phaseFragment);

  const showSkeleton = loading || !phase;
  const isPhaseUnderReview = phase?.status === DocumentationPageStatusEnum.InReview;

  return (
    <>
      <AssetDetails
        label={$t({ id: 'phaseSidebar.phaseDetails', defaultMessage: 'Phase Details' })}
        loading={showSkeleton}
        skeleton={<Skeleton.Paragraph rows={2} width={['200px', '150px']} />}
      >
        <AssetDetail label={$t({ id: 'phaseSidebar.phaseStatus', defaultMessage: 'Phase status' })}>
          <RestrictedAccessTooltip
            placement="left"
            restricted={isPhaseUnderReview}
            message={$t({
              id: 'phase.toolbar.tooltip.underReview',
              defaultMessage: 'Phase is under review. Status cannot be changed manually',
            })}
          >
            <DocumentationPageStatus
              phaseId={phase?.vecticeId}
              status={phase?.status}
              disabled={!canEdit || isPhaseUnderReview}
            />
          </RestrictedAccessTooltip>
        </AssetDetail>
        <AssetDetail label={$t({ id: 'phaseSidebar.phaseOwner', defaultMessage: 'Phase owner' })}>
          <PhaseOwnerStatus
            phaseId={phase?.vecticeId}
            owner={phase?.owner}
            workspace={phase?.parent.workspace}
            disabled={!canEdit}
          />
        </AssetDetail>
        <AssetDetail label={$t({ id: 'phaseSidebar.phaseID', defaultMessage: 'Phase ID' })}>
          <WithCopyID
            iconLabel={$t({ id: 'phaseSidebar.copyLabel', defaultMessage: 'Copy Phase ID' })}
            idValue={phase?.vecticeId}
          />
        </AssetDetail>
      </AssetDetails>

      {phase && (
        <AssetDetails label={$t({ id: 'phaseSidebar.contributors', defaultMessage: 'Contributors' })}>
          <Contributors
            resourceId={phase.vecticeId}
            resourceType={VecticeResourceType.PHASE}
            workspaceId={phase.parent.workspace?.vecticeId}
          />
        </AssetDetails>
      )}

      {phase && (
        <CreationDetails
          createdDate={phase.createdDate}
          createdBy={phase.createdBy}
          updatedDate={phase.updatedDate}
          updatedBy={phase.lastUpdatedBy}
        />
      )}
    </>
  );
};
