import { useMutation, useQuery } from '@apollo/client';
import React, { useState, useMemo } from 'react';

import type { ButtonProps } from '../../ui/button/Button';

import { ReactComponent as CheckIcon } from '../../assets/icons/interface/ic-check-circle.svg';
import { ReactComponent as NoOwnerIcon } from '../../assets/icons/menu/ic-single-neutral.svg';
import { useAuthentication } from '../../context';
import { graphql } from '../../gql';
import { OrderDirection, User, Workspace, WorkspaceType } from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import { GET_USER_LIST, GET_WORKSPACE_MEMBER_LIST } from '../../graphql/queries';
import {
  Divider,
  Dropdown,
  DropdownTrigger,
  FilterSearch,
  FlexContainer,
  Icon,
  Menu,
  MenuItem,
  Typography,
  WithLoading,
} from '../../ui';
import { reorderMemberList } from '../../utils';
import { UserAvatar } from '../asset-display';
import { FormatUserName } from '../formatters';

import styles from './OwnerStatus.module.scss';

export const UPDATE_PHASE_OWNER = graphql(`
  mutation updatePhaseOwner($phaseId: VecticeId!, $ownerId: Float) {
    updatePhase(id: $phaseId, updateModel: { ownerId: $ownerId }) {
      vecticeId
      ownerId
      owner {
        id
        ...userFields
        disabled
      }
    }
  }
`);

interface PhaseOwnerStatusProps extends ButtonProps {
  phaseId?: string;
  owner?: (Pick<User, 'id'> & UserIdentity) | null;
  workspace?: Pick<Workspace, 'vecticeId' | 'type'> | null;
}

export const PhaseOwnerStatus = ({ phaseId, owner, workspace, ...props }: PhaseOwnerStatusProps) => {
  const [search, setSearch] = useState('');
  const [visible, setVisible] = useState(false);
  const { user: connectedUser } = useAuthentication();

  const [updatePhase] = useMutation(UPDATE_PHASE_OWNER);

  const { data: workspaceMembersData, loading: workspaceMembersLoading } = useQuery(GET_WORKSPACE_MEMBER_LIST, {
    fetchPolicy: 'network-only',
    skip: !workspace?.vecticeId || !visible || workspace?.type === WorkspaceType.Public,
    variables: {
      workspaceId: workspace?.vecticeId!,
      order: { field: 'name', direction: OrderDirection.Asc },
      filters: {
        searchFilter: {
          search,
          fields: ['name', 'email'],
        },
        includeDisabledUsers: false,
        includeReadOnlyUsers: false,
      },
    },
  });

  const { data: userListData, loading: userListLoading } = useQuery(GET_USER_LIST, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    skip: !visible || workspace?.type !== WorkspaceType.Public,
    variables: {
      filters: {
        searchFilter: search
          ? {
              fields: ['name', 'email'],
              search: search,
            }
          : undefined,
        includeDisabledUsers: false,
        includeReadOnlyUsers: false,
      },
    },
  });

  const members =
    workspaceMembersData?.getWorkspaceMemberList?.items ?? userListData?.getOrganizationUsersForUser ?? [];
  const membersArray = useMemo(() => reorderMemberList(members, connectedUser, !!search), [members]);
  const loading = workspaceMembersLoading ?? userListLoading;

  const handleVisibleChange = (newState: boolean) => {
    setVisible(newState);
    // Clean up the state when closing filter
    if (!newState) {
      setSearch('');
    }
  };

  const handleOwnerRemoval = async () => {
    handleVisibleChange(false);
    if (phaseId) {
      await updatePhase({
        variables: {
          phaseId,
          ownerId: null,
        },
      });
    }
  };

  const handleOwnerChange = (memberId: string) => async () => {
    // close the menu without triggering a change if the selected member is already the owner
    if (owner?.id !== memberId && phaseId) {
      await updatePhase({
        variables: {
          phaseId,
          ownerId: Number(memberId),
        },
      });
    }
    handleVisibleChange(false);
  };

  const overlay = (
    <Menu>
      <FilterSearch initialSearch={search} onSearch={setSearch} autoFocus />
      <Divider className={styles.divider} component="div" />
      <WithLoading loading={loading} loadingSize={20}>
        {search === '' && (
          <MenuItem selected={!owner} className={styles.owner_option} onClick={handleOwnerRemoval}>
            {!owner && <Icon className={styles.icon} icon={CheckIcon} size={20} />}
            <FlexContainer className={styles.label}>
              <Icon icon={NoOwnerIcon} size={18} className={styles.no_owner_icon} color="dark-gray" />
              <Typography variant="callout" color="primary" ellipsis>
                {$t({ id: 'phase.ownerName.unassigned', defaultMessage: 'Unassigned' })}
              </Typography>
            </FlexContainer>
          </MenuItem>
        )}
        {membersArray.map((member) => (
          <MenuItem
            key={`option-${member.id}`}
            selected={owner?.id === member.id}
            className={styles.owner_option}
            onClick={handleOwnerChange(member.id)}
          >
            {owner?.id === member.id && <Icon className={styles.icon} icon={CheckIcon} size={20} />}
            <FlexContainer className={styles.label}>
              <UserAvatar hideTooltip size="xs" user={member} />
              <Typography variant="callout">
                <FormatUserName user={member} />{' '}
                {member.id === connectedUser.id && (
                  <Typography variant="footnote">
                    {$t({ id: 'memberList.message.assignToMe', defaultMessage: '(Assign to me)' })}
                  </Typography>
                )}
              </Typography>
            </FlexContainer>
          </MenuItem>
        ))}
      </WithLoading>
    </Menu>
  );

  return (
    <Dropdown overlay={overlay} trigger={['click']} visible={visible} onVisibleChange={handleVisibleChange}>
      <DropdownTrigger variant="white" aria-label="Phase owner select" {...props}>
        <FlexContainer className={styles.trigger}>
          {owner ? (
            <>
              <UserAvatar user={owner} size="xs" />
              <Typography ellipsis>
                <FormatUserName user={owner} />
              </Typography>
            </>
          ) : (
            <Typography ellipsis color="tertiary">
              {$t({ id: 'phase.ownerName.unassigned', defaultMessage: 'Unassigned' })}
            </Typography>
          )}
        </FlexContainer>
      </DropdownTrigger>
    </Dropdown>
  );
};
