import React from 'react';

import { Skeleton } from '../loading-animation';

import styles from './TableSkeleton.module.scss';

const sizes = {
  lg: 56,
  md: 40,
  sm: 32,
};

export const TableSkeleton = ({ size = 'md' }: { size?: 'lg' | 'md' | 'sm' }) => (
  <div className={styles.table}>
    <Skeleton.Shape height={sizes[size]} />
    <Skeleton.Shape height={sizes[size]} light />
    <Skeleton.Shape height={sizes[size]} />
    <Skeleton.Shape height={sizes[size]} light />
    <Skeleton.Shape height={sizes[size]} />
  </div>
);
