import React, { useState } from 'react';

import { useOrgConfig } from '../../context';
import { UserInvite } from '../../gql/graphql';
import { useCancelInvite, useResendInvite } from '../../hooks';
import { Menu, MenuItem, message, MoreActions } from '../../ui';
import { UserInviteModal } from '../modals';

interface InviteMenuCellProps {
  record: UserInvite;
}

export const InviteMenuCell = ({ record }: InviteMenuCellProps) => {
  const { sendEmail } = useOrgConfig();
  const [showModal, setShowModal] = useState(false);

  const { cancel } = useCancelInvite();
  const { resend } = useResendInvite();

  const cancelInvite = async () => {
    try {
      await cancel(record);
      message.success($t({ id: 'invite.message.cancelled', defaultMessage: 'Invitation was canceled' }), 3);
    } catch (error) {
      message.error(error.message, 3);
    }
  };

  const resendInvite = async () => {
    try {
      await resend(record);
      message.success($t({ id: 'invite.message.resent', defaultMessage: 'Invitation was resent' }), 3);
    } catch (error) {
      message.error(error.message, 3);
    }
  };

  const menu = (
    <Menu>
      {sendEmail && (
        <MenuItem onClick={resendInvite}>
          {$t({ id: 'invite.action.resend', defaultMessage: 'Resend Invitation' })}
        </MenuItem>
      )}
      {record.inviteLinkToken && (
        <MenuItem onClick={() => setShowModal(true)}>
          <div>{$t({ id: 'invite.show.link', defaultMessage: 'Show Invitation Link' })}</div>
        </MenuItem>
      )}
      <MenuItem onClick={cancelInvite}>
        {$t({ id: 'invite.action.cancel', defaultMessage: 'Cancel invitation' })}
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <MoreActions moreActionsMenu={menu} />
      {showModal && <UserInviteModal invite={record} onClose={() => setShowModal(false)} />}
    </>
  );
};
