import React from 'react';

import { getModelVersionStatusDisplayName } from '../../entities/utils';
import { ModelVersionStatus } from '../../gql/graphql';
import { Badge } from '../../ui';

const getModelVersionStatusColor = (status?: ModelVersionStatus) => {
  switch (status) {
    case ModelVersionStatus.Experimentation:
      return 'info';
    case ModelVersionStatus.Production:
      return 'success';
    case ModelVersionStatus.Staging:
      return 'on-going';
    case ModelVersionStatus.Retired:
      return 'default';
    case ModelVersionStatus.Discarded:
      return 'abandoned';
    default:
      return 'info';
  }
};

export const ModelVersionStatusBadge = ({ status, contrast }: { status?: ModelVersionStatus; contrast?: boolean }) => (
  <Badge size="sm" color={getModelVersionStatusColor(status)} contrast={contrast}>
    {getModelVersionStatusDisplayName(status)}
  </Badge>
);
