import { useQuery } from '@apollo/client';
import React, { useState, useMemo } from 'react';

import { useAuthentication } from '../../../context';
import { Checkbox, CheckboxGroup, Divider, EmptySelect, FilterLabel, FilterSearch, WithLoading } from '../../../ui';
import { reorderMemberList } from '../../../utils';
import { UserAvatar } from '../../asset-display/user/UserAvatar';
import { GET_CONTRIBUTOR_LIST } from '../../contributors/getContributorList.query';
import { FormatUserName } from '../../formatters';
import { CommonFilterProps } from '../types';

import styles from './Filters.module.scss';

export interface ContributorsFilterComponentProps {
  workspaceIdList: string[];
}

type Props = CommonFilterProps & ContributorsFilterComponentProps;

export const ContributorsFilterComponent = ({ workspaceIdList, filter, onFilterUpdate }: Props) => {
  const [search, setSearch] = useState('');
  const { user: connectedUser } = useAuthentication();

  const filters = search
    ? {
        searchFilter: { search, fields: ['name', 'email'] },
        includeWorkspaceMembers: true,
      }
    : {
        includeWorkspaceMembers: true,
      };

  const { data, loading } = useQuery(GET_CONTRIBUTOR_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      workspaceIdList,
      filters,
    },
  });

  const users = data?.getContributorList.items ?? [];
  const usersArray = useMemo(() => reorderMemberList(users, connectedUser, !!search), [users]);

  return (
    <>
      <FilterSearch initialSearch={search} onSearch={setSearch} />
      <Divider component="div" className={styles.divider} />
      <WithLoading loading={loading}>
        <CheckboxGroup
          name="users-options"
          checkedValues={filter}
          onChange={(value) => onFilterUpdate(value.length ? value : undefined)}
          className={styles.options}
        >
          {usersArray.map((user) => (
            <Checkbox
              key={user.id}
              label={
                <FilterLabel addonBefore={<UserAvatar size="xs" user={user} />}>
                  <FormatUserName user={user} />
                </FilterLabel>
              }
              className={styles.option}
              value={user.id}
            />
          ))}
        </CheckboxGroup>
        {search && !users.length && <EmptySelect />}
      </WithLoading>
    </>
  );
};
