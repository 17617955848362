import { graphql } from '../../gql';

export const GET_ASSET_ATTACHMENTS = graphql(`
  query getAssetAttachments($entityId: VecticeId!, $type: EntityMetadataTypeEnum!) {
    attachments(entityId: $entityId, entityType: $type) {
      id
      name
      hasPreview
      mimeType
      attachmentType
      ...attachmentFragment
    }
  }
`);
