import { ModelVersionStatus } from '../../gql/graphql';

export const getModelVersionStatusColor = (status?: ModelVersionStatus) => {
  switch (status) {
    case ModelVersionStatus.Experimentation:
      return 'info';
    case ModelVersionStatus.Production:
      return 'success';
    case ModelVersionStatus.Staging:
      return 'on-going';
    case ModelVersionStatus.Retired:
      return 'default';
    case ModelVersionStatus.Discarded:
      return 'abandoned';
    default:
      return 'info';
  }
};

export const getModelVersionStatusDisplayName = (status?: ModelVersionStatus | string | null) => {
  switch (status) {
    case ModelVersionStatus.Discarded:
      return $t({ id: 'modelVersionStatus.discarded.label', defaultMessage: 'Discarded' });
    case ModelVersionStatus.Retired:
      return $t({ id: 'modelVersionStatus.retired.label', defaultMessage: 'Retired' });
    case ModelVersionStatus.Experimentation:
      return $t({ id: 'modelVersionStatus.experimentation.label', defaultMessage: 'Experimentation' });
    case ModelVersionStatus.Production:
      return $t({ id: 'modelVersionStatus.production.label', defaultMessage: 'Production' });
    case ModelVersionStatus.Staging:
      return $t({ id: 'modelVersionStatus.staging.label', defaultMessage: 'Staging' });
    default:
      return status || $t({ id: 'modelVersionStatus.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getModelVersionStatusOptions = (withDiscarded = true) => [
  {
    label: getModelVersionStatusDisplayName(ModelVersionStatus.Experimentation),
    value: ModelVersionStatus.Experimentation,
  },
  {
    label: getModelVersionStatusDisplayName(ModelVersionStatus.Staging),
    value: ModelVersionStatus.Staging,
  },
  {
    label: getModelVersionStatusDisplayName(ModelVersionStatus.Production),
    value: ModelVersionStatus.Production,
  },
  ...(withDiscarded
    ? [
        {
          label: getModelVersionStatusDisplayName(ModelVersionStatus.Discarded),
          value: ModelVersionStatus.Discarded,
        },
      ]
    : []),
  {
    label: getModelVersionStatusDisplayName(ModelVersionStatus.Retired),
    value: ModelVersionStatus.Retired,
  },
];
