import React, { PropsWithChildren } from 'react';

import { GuardedContent } from './GuardedContent';
import { useWithWorkspaceCreationRight } from './useWithWorkspaceCreationRight';

export const WithWorkspaceCreationRight = ({ children }: PropsWithChildren<unknown>) => {
  const { allowed, loading, error } = useWithWorkspaceCreationRight();

  return (
    <GuardedContent allowed={allowed} loading={loading} error={error}>
      {children}
    </GuardedContent>
  );
};
