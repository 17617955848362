import { useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as ReportIcon } from '../../../assets/icons/specials/ic-report.svg';
import { graphql } from '../../../gql';
import { AssetTooltip, DataList, Tooltip, Typography } from '../../../ui';
import { getVecticeResourceTypeLabel, VecticeResourceType } from '../../../utils';
import { FormatDate, FormatUserName } from '../../formatters';

export const REPORT_TOOLTIP = graphql(`
  query reportTooltip($id: Float!) {
    CDTReport(id: $id) {
      id
      name
      createdDate
      createdBy {
        ...userFields
      }
    }
  }
`);

interface ReportTooltipProps {
  id?: number | null;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

let globalId = 0;

export const ReportTooltip = ({ id, placement = 'top', children }: ReportTooltipProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`report-tooltip-${globalId++}`);
  const [skipQuery, setSkipQuery] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data, error } = useQuery(REPORT_TOOLTIP, {
    skip: skipQuery || !id,
    variables: {
      id: id!,
    },
  });
  const report = data?.CDTReport;

  useEffect(() => {
    setDataLoaded(!!report || !!error);
  }, [report, error]);

  const handleVisibleChange = useCallback((state: boolean) => {
    setSkipQuery(false);
    setVisible(state);
  }, []);

  if (!id) {
    return children;
  }

  const overlay = (
    <AssetTooltip
      ariaId={ariaId}
      deleted={!!error}
      icon={ReportIcon}
      label={report?.name}
      resourceTypeLabel={getVecticeResourceTypeLabel(VecticeResourceType.CDT_REPORT)}
    >
      {report && (
        <DataList>
          <Typography id={`created-${ariaId}`} component="dt" variant="callout" color="white">
            Created
          </Typography>
          <Typography aria-labelledby={`created-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
            <FormatDate date={report.createdDate} fromNow /> by <FormatUserName user={report.createdBy} />
          </Typography>
        </DataList>
      )}
    </AssetTooltip>
  );

  return (
    <Tooltip
      id={ariaId}
      overlay={overlay}
      placement={placement}
      visible={visible && dataLoaded}
      onVisibleChange={handleVisibleChange}
    >
      {children}
    </Tooltip>
  );
};
