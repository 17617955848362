import { useQuery } from '@apollo/client';
import React from 'react';

import NoReviewsGraphic from '../../../assets/graphics/documentation/empty-reviews.svg';
import { graphql } from '../../../gql';
import { Button, Card, FlexContainer, Graphic, List, Typography } from '../../../ui';
import { ReviewListItem } from '../../asset-display';
import { DocumentationLink } from '../../links';

import styles from './HomeTabs.module.scss';

export const GET_AFFECTED_REVIEWS = graphql(`
  query getAffectedReviews($afterCursor: String, $pageSize: Int!) {
    getReviewList(
      filters: { status: [Pending], affectedToMe: true }
      order: { field: "createdDate", direction: DESC }
      page: { size: $pageSize, afterCursor: $afterCursor }
    ) {
      items {
        id
        ...reviewListItem
        phase {
          vecticeId
          parent {
            vecticeId
            workspace {
              vecticeId
              type
              name
            }
          }
        }
      }
      page {
        afterCursor
        hasNextPage
      }
    }
  }
`);

const EmptyReviewsTab = () => (
  <Card inert className={styles.emptyCard}>
    <FlexContainer direction="column" align="center" gap={8} className={styles.emptyContent}>
      <Graphic src={NoReviewsGraphic} size={200} />
      <Typography color="primary" weight="semi-bold">
        {$t({
          id: 'home.tabs.reviews.empty.label',
          defaultMessage: 'No reviews yet!',
        })}
      </Typography>
      <Typography variant="callout" color="secondary" align="center" paragraph>
        {$t({
          id: 'home.tabs.reviews.empty.description',
          defaultMessage:
            'Reviews allow you to share outcomes and receive feedback from stakeholders and subject-matter experts on your initiatives.',
        })}
        <br />
        <DocumentationLink asTypography url="review">
          {$t({
            id: 'home.tabs.reviews.empty.readOurDocs',
            defaultMessage: 'Read our docs to learn more.',
          })}
        </DocumentationLink>
      </Typography>
    </FlexContainer>
  </Card>
);

export const PendingReviewsTab = () => {
  const { data, loading, fetchMore } = useQuery(GET_AFFECTED_REVIEWS, {
    fetchPolicy: 'network-only',
    variables: { pageSize: 10 },
  });

  const reviews = data?.getReviewList.items;
  const { hasNextPage, afterCursor } = data?.getReviewList.page ?? {};

  const handleShowMore = () => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          afterCursor,
        },
      });
    }
  };

  return (
    <>
      <List collapsed empty={reviews?.length === 0} emptyText={<EmptyReviewsTab />} loading={loading} gutterBottom>
        {reviews?.map((review) => (
          <ReviewListItem key={review.id} review={review} workspace={review.phase.parent?.workspace} />
        ))}
      </List>
      {hasNextPage && (
        <Button variant="outlined" size="sm" onClick={handleShowMore}>
          {$t({ id: 'button.showMore', defaultMessage: 'Show More' })}
        </Button>
      )}
    </>
  );
};
