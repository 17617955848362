import { useApolloClient, useMutation } from '@apollo/client';
import { useState, useCallback } from 'react';

import { AttachmentOutput } from '../gql/graphql';
import { DELETE_ENTITY_FILE } from '../graphql/queries/deleteEntityFile.mutation';
import { EXTRACT_ENTITY_FILE } from '../graphql/queries/extractEntityFile.mutation';
import { RENAME_ENTITY_FILE } from '../graphql/queries/renameEntityFile.mutation';
import { flushFromGraphQLCache } from '../graphql/utils';
import { message } from '../ui';
import { VecticeResourceType } from '../utils';

import { useUploadFiles } from './useUploadFiles';

export const useEntityFileMethods = (
  resourceId: number | string | undefined,
  resourceType: VecticeResourceType | undefined,
  refetchQuery: 'getAssetAttachments' | 'getEntityFileList' = 'getAssetAttachments',
) => {
  const apolloClient = useApolloClient();
  const [uploading, setUploading] = useState(false);

  const [renameEntityFile] = useMutation(RENAME_ENTITY_FILE, {
    refetchQueries: [refetchQuery],
  });

  const [deleteEntityFile] = useMutation(DELETE_ENTITY_FILE, {
    refetchQueries: [refetchQuery],
    optimisticResponse: ({ id }) => ({
      deleteEntityFile: id,
    }),
    update: (cache, { data }) => {
      if (data?.deleteEntityFile)
        flushFromGraphQLCache(cache, { id: data?.deleteEntityFile, __typename: 'EntityFile' });
    },
  });

  const [extractEntityFileMutation] = useMutation(EXTRACT_ENTITY_FILE, {
    refetchQueries: [refetchQuery],
    onCompleted: ({ extractEntityFile }) => {
      if (extractEntityFile.tables + extractEntityFile.images === 0) {
        message.info(
          $t({
            id: 'Attachments.noExtractedContent',
            defaultMessage: 'No content was extracted from the file.',
          }),
        );
      } else {
        message.success(
          $t({
            id: 'Attachments.extractSuccess',
            defaultMessage:
              'The file has been extracted successfully, creating {images} image(s) and {tables} table(s).',
            values: { images: extractEntityFile.images, tables: extractEntityFile.tables },
          }),
        );
      }
    },
  });

  const { uploadFiles } = useUploadFiles({ resourceId, resourceType });

  const addEntityFiles = useCallback(
    (files: File[] | null) => {
      if (files) {
        setUploading(true);
        uploadFiles(files)
          .then(() => {
            apolloClient.refetchQueries({ include: [refetchQuery] });
            message.success(
              $t({
                id: 'Attachments.uploadSuccess',
                defaultMessage: `{count, plural, one {The file has been added} other {The files have been added}}`,
                values: { count: files.length },
              }),
            );
          })
          .catch((error) => message.error(error.message))
          .finally(() => setUploading(false));
      }
    },
    [uploadFiles],
  );

  const removeEntityFile = useCallback(({ id, name }: { id: number; name: string }) => {
    deleteEntityFile({
      variables: { id },
      onCompleted: () =>
        message.success(
          $t({
            id: 'Attachments.deleteSuccess',
            defaultMessage: `{name} has been deleted`,
            values: {
              name,
            },
          }),
        ),
      onError: () =>
        message.error(
          $t({
            id: 'Attachments.deletionError',
            defaultMessage: 'Oops, something went wrong! "{name}" could not be deleted.',
            values: { name },
          }),
        ),
    });
  }, []);

  const extractEntityFile = useCallback((attachment: Pick<AttachmentOutput, 'id' | 'name' | '__typename'>) => {
    extractEntityFileMutation({
      variables: { id: attachment.id },
      onError: () =>
        message.error(
          $t({
            id: 'Attachments.extractError',
            defaultMessage: 'Oops, something went wrong! The file "{fileName}" could not be extracted.',
            values: { fileName: attachment.name },
          }),
        ),
    });
  }, []);

  return {
    uploading,
    addEntityFiles,
    extractEntityFile,
    removeEntityFile,
    renameEntityFile,
  };
};
