import { useQuery } from '@apollo/client';
import React from 'react';

import { OrderDirection } from '../../gql/graphql';
import { GET_PHASE_LIST } from '../../pages/project/overview/getPhaseList.query';
import { List } from '../../ui';
import { PhaseListItem } from '../asset-display';
import { NoData } from '../no-results';

export const PhaseList = ({ projectId }: { projectId?: string }) => {
  const { data: phaseListData, loading } = useQuery(GET_PHASE_LIST, {
    fetchPolicy: 'network-only',
    skip: !projectId,
    variables: {
      parentId: projectId!,
      order: { field: 'index', direction: OrderDirection.Asc },
      page: { size: 100 },
    },
  });
  const phases = phaseListData?.getPhaseList.items;

  return (
    <List collapsed empty={phases?.length === 0} emptyText={<NoData />} loading={loading}>
      {phases?.map((phase) => (
        <PhaseListItem key={phase.vecticeId} phase={phase} />
      ))}
    </List>
  );
};
