import React from 'react';

import { User } from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import { Typography } from '../../ui';
import { UserAvatar } from '../asset-display';
import { FormatUserName } from '../formatters';

import styles from './UserCell.module.scss';

interface UserCellProps {
  user?: Pick<User, 'email'> & UserIdentity;
  hideTooltip?: boolean;
  showEmail?: boolean;
}

export const UserCell = ({ user, hideTooltip, showEmail }: UserCellProps) => (
  <div className={styles.userCell}>
    <UserAvatar user={user} size="md" hideTooltip={hideTooltip} />
    <div className={styles.info}>
      <Typography variant="callout" weight="semi-bold" ellipsis>
        <FormatUserName user={user} />
      </Typography>
      {showEmail && user?.email && (
        <Typography variant="callout" color="tertiary" ellipsis>
          {user.email}
        </Typography>
      )}
    </div>
  </div>
);
