import React from 'react';

import { useFragment } from '../../../gql';
import { ActivityType, ActivityUpdateType } from '../../../gql/graphql';
import { ModelLink } from '../../asset-display';
import { ModelType } from '../../model';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { ActivityProps } from './interfaces';

export const ModelActivity = ({ activity: fragment }: ActivityProps) => {
  const activity = useFragment(UserActivityFragment, fragment);

  const model = <ModelLink name={activity.targetName} resourceId={activity.targetVecticeId} />;
  const user = <ActivityCreator user={activity.actor} isAutomated={activity.isAutomated} />;

  const oldNameLink = <ModelLink name={activity.fromValue || undefined} resourceId={activity.targetVecticeId} />;
  const newNameLink = <ModelLink name={activity.toValue || undefined} resourceId={activity.targetVecticeId} />;

  if (activity.type === ActivityType.Created) {
    return (
      <>
        {$t({
          id: 'activities.model.created',
          defaultMessage: '{user} created {resource}',
          values: {
            user,
            resource: model,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.Deleted) {
    return (
      <>
        {$t({
          id: 'activities.model.deleted',
          defaultMessage: '{user} deleted {resource}',
          values: {
            user,
            resource: model,
          },
        })}
      </>
    );
  }

  if (activity.type === ActivityType.UpdatedProp) {
    if (activity.updateType === ActivityUpdateType.Description) {
      return (
        <>
          {$t({
            id: 'activities.model.update.description',
            defaultMessage: '{user} updated the description of {resource}',
            values: {
              user,
              resource: model,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Name) {
      return (
        <>
          {$t({
            id: 'activities.model.update.name',
            defaultMessage: '{user} renamed {oldName} to {newName}',
            values: {
              user,
              resource: model,
              oldName: oldNameLink,
              newName: newNameLink,
            },
          })}
        </>
      );
    }

    if (activity.updateType === ActivityUpdateType.Type) {
      const oldType = <ModelType modelType={activity.fromValue} />;
      const newType = <ModelType modelType={activity.toValue} />;
      return (
        <>
          {$t({
            id: 'activities.model.update.type',
            defaultMessage: '{user} changed the type of {resource} from {oldType} to {newType}',
            values: {
              user,
              resource: model,
              oldType,
              newType,
            },
          })}
        </>
      );
    }
  }

  return null;
};
