import React, { useState } from 'react';

import { AttachmentOutput } from '../../gql/graphql';
import { EmptyList, Grid, GridCell, Summary, Typography } from '../../ui';
import { downloadEntityFile } from '../../utils';
import { LightboxPreview } from '../previews';
import { PreviewTile } from '../previewTile';

import styles from './SummaryAttachments.module.scss';

interface SummaryAttachmentsProps {
  attachments: Pick<AttachmentOutput, 'id' | 'name' | 'mimeType' | 'hasPreview' | 'attachmentType'>[];
  open?: boolean;
}

export const SummaryAttachments = ({ attachments, open }: SummaryAttachmentsProps) => {
  const [selectedAttachment, setSelectedAttachment] = useState<number | null>(null);

  return (
    <>
      <Summary
        className={styles.section}
        open={open}
        withBackground={false}
        summary={
          <Typography component="h2" color="primary" variant="body" weight="semi-bold">
            {$t({ id: 'attachments.title', defaultMessage: 'Attachments' })}
          </Typography>
        }
      >
        {attachments.length === 0 && (
          <EmptyList
            className={styles.empty}
            message={
              <Typography variant="callout" color="tertiary" weight="regular">
                {$t({ id: 'SummaryAttachments.noAttachments', defaultMessage: 'No Attachments' })}
              </Typography>
            }
          />
        )}
        {attachments.length > 0 && (
          <Grid className={styles.grid}>
            {attachments.map((attachment, index) => (
              <GridCell key={attachment.id}>
                <PreviewTile
                  attachment={attachment}
                  onClick={() => setSelectedAttachment(index)}
                  onDownload={() => downloadEntityFile(attachment)}
                />
              </GridCell>
            ))}
          </Grid>
        )}
      </Summary>

      {selectedAttachment !== null && attachments?.[selectedAttachment] && (
        <LightboxPreview
          entityFile={attachments[selectedAttachment]}
          previousDisabled={selectedAttachment === 0}
          nextDisabled={selectedAttachment === attachments.length - 1}
          onPrevious={() => setSelectedAttachment(selectedAttachment - 1)}
          onNext={() => setSelectedAttachment(selectedAttachment + 1)}
          onClose={() => setSelectedAttachment(null)}
        />
      )}
    </>
  );
};
