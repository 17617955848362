import React from 'react';

import { Badge, BadgeProps, Tooltip } from '../../ui';

export const ReadOnlyBadge = (props: BadgeProps) => (
  <Tooltip
    text={$t({ id: 'badge.readOnly.tooltip', defaultMessage: 'Contact the workspace Manager for full access' })}
    placement="right"
  >
    <Badge color="accent12" size="sm" {...props}>
      {$t({ id: 'badge.readOnly', defaultMessage: 'Read-only' })}
    </Badge>
  </Tooltip>
);
