import React, { PropsWithChildren, ReactNode } from 'react';

import { FlexContainer, Section, WithLoading } from '../../ui';

import styles from './AssetDetails.module.scss';

interface AssetDetailsProps {
  label: React.ReactNode | ((ariaId: string) => React.ReactNode);
  loading?: boolean;
  skeleton?: ReactNode;
}

export const AssetDetails = ({ children, label, loading, skeleton }: PropsWithChildren<AssetDetailsProps>) => (
  <Section label={label} className={styles.section}>
    <WithLoading loading={loading} skeleton={skeleton}>
      <FlexContainer direction="column" gap={24}>
        {children}
      </FlexContainer>
    </WithLoading>
  </Section>
);
