import { graphql } from '../../gql';

export const GET_DATASET_DETAILS = graphql(`
  query getDatasetDetails($datasetId: VecticeId!) {
    getDataset(datasetId: $datasetId) {
      ...DatasetSidebar
      vecticeId
      name
      project {
        vecticeId
      }
    }
  }
`);

export const UPDATE_DATASET_TYPE = graphql(`
  mutation updateDatasetType($datasetId: VecticeId!, $data: DatasetUpdateInput!) {
    updateDataset(datasetId: $datasetId, dataset: $data) {
      vecticeId
      type
    }
  }
`);
