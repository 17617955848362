import { graphql } from '../../gql';

export const GET_ITERATION_LIST = graphql(`
  query getIterationList($filters: IterationFiltersInput!, $order: ListOrderInput, $page: PageInput) {
    getIterationList(filters: $filters, order: $order, page: $page) {
      items {
        vecticeId
        index
        name
        starred
        status
        createdDate
        updatedDate
        phaseId
        owner {
          ...userFields
        }
      }
      total
    }
  }
`);
