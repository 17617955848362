// It's super extensive, but it's only there to catch the main types when the browser fails
// 99.9% of the time, known filetypes are recognized by the browser
const imagesExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'tiff'];
const textExtensions = ['css', 'csv', 'html', 'txt', 'json'];

const guesstimateFileType = (file: File) => {
  if (file.type) {
    return file.type;
  }

  // sometimes, files such as jupyter notebook don't seem to have a mime type
  const extension = file.name.split('.').pop()?.toLowerCase();

  // if we can't even find the extension there's nothing we can do
  if (!extension) {
    return '';
  }

  if (imagesExtensions.includes(extension)) {
    if (extension === 'svg') {
      return 'image/svg+xml';
    }

    if (extension === 'jpg') {
      return 'image/jpeg';
    }
    return `image/${extension}`;
  }

  if (textExtensions.includes(extension)) {
    return `text/${extension}`;
  }

  if (extension === 'ipynb') {
    return 'application/ipynb';
  }

  if (extension === 'zip') {
    return 'zip|compressed';
  }

  if (extension === 'epub') {
    return 'application/epub+zip';
  }

  return '';
};

export const matchMimeType = (accept: string, fileType?: string | null) => {
  if (!fileType) {
    return false;
  }

  const acceptedTypes = accept
    .split(',')
    .map((mime) => mime.toLowerCase())
    .map((mime) => mime.replace(/[-[\]{}()+?.,\\^$|#\s]/g, '\\$&'))
    .map((mime) => mime.replace('*', '.*'))
    .map((mime) => new RegExp(mime));

  return acceptedTypes.some((mime) => fileType.match(mime));
};

export const validateMimeType = (accept: string) => (file: File) => {
  if (!accept) {
    return true;
  }

  const fileType = guesstimateFileType(file);

  return matchMimeType(accept, fileType);
};

// reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types

export const isTableAttachment = (
  mimeType?: string | null,
  content?: Record<string, any> | null,
): content is { data: any[] } => !mimeType && content?.data && Array.isArray(content.data);

export const isImageFile = (mimeType?: string | null) =>
  matchMimeType('image/*', mimeType) && !matchMimeType('image/tiff', mimeType);

export const isAudioFile = (mimeType?: string | null) => matchMimeType('audio/*', mimeType);

export const isVideoFile = (mimeType?: string | null) => matchMimeType('video/*', mimeType);

export const isJsonFile = (mimeType?: string | null) => matchMimeType('application/json', mimeType);

export const isPdfFile = (mimeType?: string | null) => matchMimeType('application/pdf', mimeType);

export const isGoogleColabFile = (mimeType?: string | null) => matchMimeType('application/vnd.google.colab', mimeType);

export const isGoogleSheetFile = (mimeType?: string | null) =>
  matchMimeType('application/vnd.google-apps.spreadsheet', mimeType);

export const isXlsFile = (mimeType?: string | null) =>
  matchMimeType('application/vnd.oasis.opendocument.spreadsheet', mimeType) ||
  matchMimeType('application/vnd.ms-excel', mimeType) ||
  matchMimeType('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', mimeType);

export const isDatabaseFile = (mimeType?: string | null) =>
  matchMimeType('application/vnd.oasis.opendocument.database', mimeType);

export const isGoogleSlideFile = (mimeType?: string | null) =>
  matchMimeType('application/vnd.google-apps.presentation', mimeType) ||
  matchMimeType('application/vnd.oasis.opendocument.presentation', mimeType) ||
  matchMimeType('application/vnd.openxmlformats-officedocument.presentationml.presentation', mimeType);

export const isGoogleDocFile = (mimeType?: string | null) =>
  matchMimeType('application/vnd.google-apps.document', mimeType) ||
  matchMimeType('application/vnd.oasis.opendocument.text', mimeType) ||
  matchMimeType('application/vnd.openxmlformats-officedocument.wordprocessingml.document', mimeType);

export const isGoogleFolder = (mimeType?: string | null) =>
  matchMimeType('application/vnd.google-apps.folder', mimeType);

export const isRFile = (mimeType?: string | null) => matchMimeType('application/r', mimeType);

export const isNotebookFile = (mimeType?: string | null) =>
  matchMimeType('application/x-ipynb+json', mimeType) || matchMimeType('application/ipynb', mimeType);

export const isJupyterFile = (mimeType?: string | null) => matchMimeType('application/vnd.jupyter', mimeType);

export const isParquetFile = (mimeType?: string | null) => matchMimeType('application/vnd.apache.parquet', mimeType);

export const isXmlFile = (mimeType?: string | null) =>
  matchMimeType('application/xml', mimeType) || matchMimeType('text/xml', mimeType);

export const isSqlFile = (mimeType?: string | null) =>
  matchMimeType('application/sql', mimeType) ||
  matchMimeType('application/x-sql', mimeType) ||
  matchMimeType('application/x-sqlite3', mimeType) ||
  matchMimeType('text/sql', mimeType) ||
  matchMimeType('text/x-sql', mimeType);

export const isCsvFile = (mimeType?: string | null) => matchMimeType('text/csv', mimeType);

export const isTsvFile = (mimeType?: string | null) => matchMimeType('text/tab-separated-values', mimeType);

export const isCodeFile = (mimeType?: string | null) =>
  matchMimeType('text/html', mimeType) ||
  matchMimeType('text/css', mimeType) ||
  matchMimeType('text/java*', mimeType) ||
  matchMimeType('text/js', mimeType) ||
  matchMimeType('text/x-*', mimeType) ||
  matchMimeType('text/yaml', mimeType);

export const isTextFile = (mimeType?: string | null) =>
  matchMimeType('text/plain', mimeType) || (mimeType?.startsWith('text/') && mimeType !== 'text/html');

export const isHTMLFile = (mimeType?: string | null) => matchMimeType('text/html', mimeType);
