import { useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as ModelVersionIcon } from '../../../assets/icons/specials/flat/ic-model-version.svg';
import { graphql } from '../../../gql';
import { AssetTooltip, Badge, DataList, Tooltip, Typography } from '../../../ui';
import { getVecticeResourceTypeLabel, VecticeResourceType } from '../../../utils';
import { ModelVersionStatusBadge } from '../../badges';
import { FormatDate, FormatUserName } from '../../formatters';

import styles from './ModelVersionTooltip.module.scss';

export const GET_MODEL_VERSION_TOOLTIP = graphql(`
  query getModelVersionTooltip($modelVersionId: VecticeId!) {
    getModelVersion(modelVersionId: $modelVersionId) {
      vecticeId
      model {
        vecticeId
        name
      }
      versionNumber
      status
      algorithmName
      createdBy {
        ...userFields
      }
      properties {
        id
      }
      metrics {
        id
      }
      createdDate
    }
  }
`);

interface ModelVersionTooltipProps {
  id?: string | null;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

let globalId = 0;

export const ModelVersionTooltip = ({ id, placement = 'top', children }: ModelVersionTooltipProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`model-tooltip-${globalId++}`);
  const [skipQuery, setSkipQuery] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data, error } = useQuery(GET_MODEL_VERSION_TOOLTIP, {
    skip: skipQuery || !id,
    variables: {
      modelVersionId: id!,
    },
  });

  const modelVersion = data?.getModelVersion;

  useEffect(() => {
    setDataLoaded(!!modelVersion || !!error);
  }, [modelVersion, error]);

  const handleVisibleChange = useCallback((state: boolean) => {
    setSkipQuery(false);
    setVisible(state);
  }, []);

  if (!id) {
    return children;
  }

  const overlay = (
    <AssetTooltip
      ariaId={ariaId}
      icon={ModelVersionIcon}
      resourceTypeLabel={getVecticeResourceTypeLabel(VecticeResourceType.MODEL_VERSION)}
      label={modelVersion?.model.name}
      versionNumber={modelVersion?.versionNumber}
      deleted={!!error}
    >
      {modelVersion && (
        <>
          <DataList>
            <Typography id={`created-${ariaId}`} component="dt" variant="callout" color="white">
              Created
            </Typography>
            <Typography aria-labelledby={`created-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
              <FormatDate date={modelVersion.createdDate} fromNow /> by <FormatUserName user={modelVersion.createdBy} />
            </Typography>
            <Typography id={`status-${ariaId}`} component="dt" variant="callout" color="white">
              Status
            </Typography>
            <Typography aria-labelledby={`status-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
              <ModelVersionStatusBadge status={modelVersion.status} contrast />
            </Typography>
            <Typography id={`algorithm-${ariaId}`} component="dt" variant="callout" color="white">
              Technique
            </Typography>
            <Typography
              aria-labelledby={`algorithm-${ariaId}`}
              component="dd"
              variant="callout"
              color="white"
              paragraph
            >
              {modelVersion.algorithmName}
            </Typography>
          </DataList>
          <div className={styles.badges}>
            <Badge contrast size="sm">
              {modelVersion.properties.length} Properties
            </Badge>
            <Badge contrast size="sm">
              {modelVersion.metrics.length} Metrics
            </Badge>
          </div>
        </>
      )}
    </AssetTooltip>
  );

  return (
    <Tooltip
      id={ariaId}
      overlay={overlay}
      visible={visible && dataLoaded}
      onVisibleChange={handleVisibleChange}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
