import React from 'react';

import { ReactComponent as LineageIcon } from '../../assets/icons/specials/ic-lineage.svg';
import { Icon } from '../../ui';
import { LineageTooltip } from '../asset-display';

interface LineageCellProps {
  runId?: number;
}

export const LineageCell = ({ runId }: LineageCellProps) => {
  if (!runId) {
    return null;
  }

  return (
    <LineageTooltip id={runId}>
      <Icon icon={LineageIcon} size={24} />
    </LineageTooltip>
  );
};
