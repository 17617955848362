import { ApolloProvider } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { InternalServerError, UnauthenticatedError, VersionMismatchError } from '../errors';
import { getApolloClient } from '../services';
import { message } from '../ui';

export const GraphQLProvider = ({ children }: PropsWithChildren<unknown>) => {
  // With use this as a hack to force the error to trigger the ErrorBoundary
  const [, setError] = useState();

  const handleError = useCallback(
    ({ graphQLErrors, networkError }: ErrorResponse) => {
      if (graphQLErrors) {
        graphQLErrors.map((error) => {
          const { path, extensions } = error;
          if (extensions.code === 'UNAUTHENTICATED') {
            if (!path?.includes('whoAmI')) {
              setError(() => {
                throw new UnauthenticatedError('disconnected');
              });
            }
          } else if (extensions.code === 'NOT_ACCEPTABLE') {
            setError(() => {
              throw new VersionMismatchError();
            });
          } else {
            logger.error(error);
          }
        });
      }

      if (networkError) {
        setError(() => {
          throw new InternalServerError(networkError);
        });
      }
    },
    [setError],
  );

  const handleConnectionLoss = useCallback(() => {
    message.error(
      $t({
        id: 'websocket.cannotconnect',
        defaultMessage:
          'Unable to initiate real-time updates due to a network issue.\nThe application is currently operating in a limited mode, which may result in unexpected behavior.\nFor assistance, please contact our support team at support@vectice.com.',
      }),
      1000,
      { closable: true },
    );
  }, [setError]);

  const apolloClient = useMemo(() => getApolloClient(handleError, handleConnectionLoss), []);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
