import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';

import { ReactComponent as DownloadIcon } from '../../assets/icons/interface/ic-download.svg';
import { getModelTypeDisplayName } from '../../entities/utils';
import { FragmentType, graphql, useFragment } from '../../gql';
import { useWithFeatureFlags, useWithRoles, WithFeatureFlags } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { useVecticeForm } from '../../hooks';
import {
  AutoLinkText,
  Button,
  DataEntry,
  DataList,
  FlexContainer,
  Form,
  Icon,
  Input,
  message,
  Skeleton,
  Tooltip,
  Typography,
} from '../../ui';
import {
  defaultNameFieldConfiguration,
  defaultNumberFieldConfiguration,
  getModelTypeIcon,
  VecticeResourceType,
} from '../../utils';
import { IterationLink, PhaseLink } from '../asset-display';
import { AssetDescription, AssetDetail, AssetDetails, CreationDetails, WithCopyID } from '../assets';
import { Contributors } from '../contributors';
import { ModelVersionStatus } from '../status';

import { ValidationNotebookModal } from './ValidationNotebookModal';

import styles from './ModelVersionSidebar.module.scss';

const ModelVersionSidebarFragment = graphql(`
  fragment ModelVersionSidebar on ModelVersion {
    vecticeId
    description
    algorithmName
    status
    inventoryReference
    model {
      vecticeId
      type
      project {
        vecticeId
        workspace {
          vecticeId
        }
      }
    }
    origins {
      phase {
        vecticeId
        name
      }
      iteration {
        vecticeId
        name
      }
    }
    createdDate
    createdBy {
      id
      ...userFields
    }
    updatedDate
    lastUpdatedBy {
      id
      ...userFields
    }
  }
`);

export const UPDATE_MODEL_VERSION_DESCRIPTION = graphql(`
  mutation updateModelVersionDescription($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {
    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {
      vecticeId
      description
    }
  }
`);

export const UPDATE_MODEL_VERSION_TECHNIQUE = graphql(`
  mutation updateModelVersionTechnique($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {
    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {
      vecticeId
      algorithmName
    }
  }
`);

export const UPDATE_MODEL_VERSION_INVENTORY = graphql(`
  mutation updateModelVersionInventory($modelVersionId: VecticeId!, $data: ModelVersionUpdateInput!) {
    updateModelVersion(modelVersionId: $modelVersionId, data: $data) {
      vecticeId
      inventoryReference
    }
  }
`);

interface ModelVersionSidebarProps {
  loading?: boolean;
  modelVersion?: FragmentType<typeof ModelVersionSidebarFragment>;
}

export const ModelVersionSidebar = ({ loading, modelVersion: modelVersionFragment }: ModelVersionSidebarProps) => {
  const { allowed: canEdit } = useWithRoles({ workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS });
  const { allowed: canEditInventory } = useWithFeatureFlags({
    featureFlag: 'view-and-assign-inventory-information-in-model-version-page',
  });
  const [showValidationNotebook, setShowValidationNotebook] = useState(false);

  const modelVersion = useFragment(ModelVersionSidebarFragment, modelVersionFragment);

  const { preSubmit, reset, registerWithErrors, formState } = useVecticeForm({
    mode: 'all',
    defaultValues: { inventory: modelVersion?.inventoryReference ?? '', technique: modelVersion?.algorithmName ?? '' },
  });
  const { hasErrors, dirtyFields, isSubmitting } = formState;

  useEffect(() => {
    reset({ inventory: modelVersion?.inventoryReference ?? '', technique: modelVersion?.algorithmName ?? '' });
  }, [modelVersion]);

  const [updateDescription] = useMutation(UPDATE_MODEL_VERSION_DESCRIPTION, {
    optimisticResponse: ({ modelVersionId: vecticeId, data: { description } }) => ({
      updateModelVersion: {
        vecticeId: vecticeId.toString(),
        description: description ?? modelVersion!.description,
      },
    }),
    onCompleted: () =>
      message.success(
        $t({ id: 'ModelVersionSidebar.update.success', defaultMessage: 'Version description has been updated' }),
      ),
    onError: (error) => message.error(error.message),
  });

  const [updateTechnique] = useMutation(UPDATE_MODEL_VERSION_TECHNIQUE, {
    optimisticResponse: ({ modelVersionId: vecticeId, data: { algorithmName } }) => {
      reset({ technique: algorithmName ?? '' });
      return {
        updateModelVersion: {
          vecticeId: vecticeId.toString(),
          algorithmName: algorithmName ?? modelVersion!.algorithmName,
        },
      };
    },
    onCompleted: () =>
      message.success(
        $t({ id: 'ModelVersionSidebar.technique.update.success', defaultMessage: 'Technique has been updated' }),
      ),
    onError: (error) => message.error(error.message),
  });

  const [updateInventory] = useMutation(UPDATE_MODEL_VERSION_INVENTORY, {
    optimisticResponse: ({ modelVersionId: vecticeId, data: { inventoryReference } }) => {
      reset({ inventory: inventoryReference ?? '' });
      return {
        updateModelVersion: {
          vecticeId: vecticeId.toString(),
          inventoryReference: inventoryReference ?? modelVersion!.inventoryReference,
        },
      };
    },
    onCompleted: () =>
      message.success(
        $t({
          id: 'ModelVersionSidebar.inventory.update.success',
          defaultMessage: 'Model inventory ID has been updated',
        }),
      ),
    onError: (error) => message.error(error.message),
  });

  const origins = modelVersion?.origins;
  const showSkeleton = loading || !modelVersion;

  const modelTypeIcon = getModelTypeIcon(modelVersion?.model.type);

  const handleTechniqueSubmit = useCallback(
    async ({ technique }) => {
      if (modelVersion) {
        await updateTechnique({
          variables: {
            modelVersionId: modelVersion.vecticeId,
            data: { algorithmName: technique },
          },
        });
      }
    },
    [modelVersion, updateTechnique],
  );

  const handleInventorySubmit = useCallback(
    async ({ inventory }) => {
      if (modelVersion) {
        await updateInventory({
          variables: {
            modelVersionId: modelVersion.vecticeId,
            data: { inventoryReference: inventory },
          },
        });
      }
    },
    [modelVersion, updateInventory],
  );

  return (
    <>
      <AssetDetails
        label={$t({ id: 'ModelVersionSidebar.versionDetails', defaultMessage: 'Version Details' })}
        loading={showSkeleton}
        skeleton={<Skeleton.Paragraph rows={2} width={['200px', '150px']} />}
      >
        <AssetDetail label={$t({ id: 'ModelVersionSidebar.versionStatus', defaultMessage: 'Status' })}>
          <ModelVersionStatus
            modelVersionId={modelVersion?.vecticeId}
            status={modelVersion?.status}
            disabled={!canEdit}
          />
        </AssetDetail>
        <AssetDetail label={$t({ id: 'ModelVersionSidebar.modelType', defaultMessage: 'Model type' })}>
          <Tooltip text={getModelTypeDisplayName(modelVersion?.model.type)}>
            <FlexContainer gap={4}>
              {modelTypeIcon && <Icon icon={modelTypeIcon} size={16} />}
              <Typography ellipsis>{getModelTypeDisplayName(modelVersion?.model.type)}</Typography>
            </FlexContainer>
          </Tooltip>
        </AssetDetail>
        {canEditInventory && (
          <AssetDetail label={$t({ id: 'ModelVersionSidebar.inventory', defaultMessage: 'Model inventory ID' })}>
            {canEdit ? (
              <Form onSubmit={preSubmit(handleInventorySubmit)}>
                <Input
                  {...registerWithErrors('inventory', {
                    ...defaultNumberFieldConfiguration(),
                    required: false,
                  })}
                  aria-label={$t({ id: 'ModelVersionSidebar.inventory', defaultMessage: 'Model inventory ID' })}
                  placeholder={$t({ id: 'ModelVersionSidebar.noInventory', defaultMessage: 'N/A' })}
                  hint={null}
                />
                {dirtyFields.inventory && (
                  <FlexContainer justify="flex-end" gap={0} className={styles.submit}>
                    <Button
                      size="xs"
                      variant="phantom"
                      onClick={() => reset({ inventory: modelVersion?.inventoryReference ?? '' })}
                    >
                      {$t({ id: 'button.cancel', defaultMessage: 'Cancel' })}
                    </Button>
                    <Button size="xs" type="submit" disabled={hasErrors} loading={isSubmitting}>
                      {$t({ id: 'button.save', defaultMessage: 'Save' })}
                    </Button>
                  </FlexContainer>
                )}
              </Form>
            ) : (
              <AutoLinkText
                text={
                  modelVersion?.inventoryReference ??
                  $t({ id: 'ModelVersionSidebar.noInventory', defaultMessage: 'N/A' })
                }
              />
            )}
          </AssetDetail>
        )}
        <AssetDetail label={$t({ id: 'ModelVersionSidebar.technique', defaultMessage: 'Technique' })}>
          {canEdit ? (
            <Form onSubmit={preSubmit(handleTechniqueSubmit)}>
              <Input
                {...registerWithErrors('technique', {
                  ...defaultNameFieldConfiguration(),
                  required: false,
                })}
                aria-label={$t({ id: 'ModelVersionSidebar.technique', defaultMessage: 'Technique' })}
                placeholder={$t({ id: 'ModelVersionSidebar.noAlgorithm', defaultMessage: 'N/A' })}
                hint={null}
              />
              {dirtyFields.technique && (
                <FlexContainer justify="flex-end" gap={0} className={styles.submit}>
                  <Button
                    size="xs"
                    variant="phantom"
                    onClick={() => reset({ technique: modelVersion?.algorithmName ?? '' })}
                  >
                    {$t({ id: 'button.cancel', defaultMessage: 'Cancel' })}
                  </Button>
                  <Button size="xs" type="submit" disabled={hasErrors} loading={isSubmitting}>
                    {$t({ id: 'button.save', defaultMessage: 'Save' })}
                  </Button>
                </FlexContainer>
              )}
            </Form>
          ) : (
            <Typography ellipsis>
              {modelVersion?.algorithmName ?? $t({ id: 'ModelVersionSidebar.noAlgorithm', defaultMessage: 'N/A' })}
            </Typography>
          )}
        </AssetDetail>
        <AssetDetail label={$t({ id: 'ModelVersionSidebar.creationDetails', defaultMessage: 'Creation Details' })}>
          <DataList className={styles.origins}>
            <DataEntry label={$t({ id: 'ModelVersionSidebar.phase', defaultMessage: 'Phase:' })} ellipsis>
              <PhaseLink resourceId={origins?.phase?.vecticeId} name={origins?.phase?.name} tooltipPlacement="left" />
            </DataEntry>
            <DataEntry label={$t({ id: 'ModelVersionSidebar.iteration', defaultMessage: 'Iteration:' })} ellipsis>
              <IterationLink
                resourceId={origins?.iteration?.vecticeId}
                name={origins?.iteration?.name}
                tooltipPlacement="left"
              />
            </DataEntry>
          </DataList>
        </AssetDetail>
        <AssetDetail label={$t({ id: 'ModelVersionSidebar.modelVersionID', defaultMessage: 'Version ID' })}>
          <WithCopyID
            iconLabel={$t({ id: 'ModelVersionSidebar.copyLabel', defaultMessage: 'Copy Model version ID' })}
            idValue={modelVersion?.vecticeId}
          />
        </AssetDetail>
        <WithFeatureFlags featureFlag="validation-notebook-download">
          <div>
            <Button leftIcon={DownloadIcon} onClick={() => setShowValidationNotebook(true)}>
              {$t({ id: 'ValidationNotebook.DownloadButton', defaultMessage: 'Validation notebook' })}
            </Button>
          </div>
        </WithFeatureFlags>
      </AssetDetails>

      {modelVersion && (
        <AssetDescription
          label={$t({ id: 'ModelVersionSidebar.description', defaultMessage: 'Version Description' })}
          description={modelVersion?.description}
          onChange={
            canEdit
              ? (description) =>
                  updateDescription({
                    variables: {
                      modelVersionId: modelVersion.vecticeId,
                      data: { description },
                    },
                  })
              : undefined
          }
        />
      )}

      {modelVersion && (
        <AssetDetails label={$t({ id: 'ModelVersionSidebar.contributors', defaultMessage: 'Contributors' })}>
          <Contributors
            resourceId={modelVersion.vecticeId}
            resourceType={VecticeResourceType.MODEL_VERSION}
            workspaceId={modelVersion.model.project.workspace?.vecticeId}
          />
        </AssetDetails>
      )}

      {modelVersion && (
        <CreationDetails
          createdDate={modelVersion.createdDate}
          createdBy={modelVersion.createdBy}
          updatedDate={modelVersion.updatedDate}
          updatedBy={modelVersion.lastUpdatedBy}
        />
      )}

      {showValidationNotebook && modelVersion && (
        <ValidationNotebookModal
          modelVersionId={modelVersion.vecticeId}
          onClose={() => setShowValidationNotebook(false)}
        />
      )}
    </>
  );
};
