import { useQuery } from '@apollo/client';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import type { AssetSelectorProps } from './interfaces';

import { ReactComponent as RemoveIcon } from '../../assets/icons/interface/ic-remove.svg';
import { AccessibilityLevel, Workspace } from '../../gql/graphql';
import { GET_USER_WORKSPACE_TARGET_LIST } from '../../graphql/queries';
import { Breadcrumb, Breadcrumbs, Button, Dropdown, FlexContainer, MenuItem, message, Typography } from '../../ui';
import { MenuWithSearch } from '../../ui-presets';
import { getVecticeResourceTypeIcon } from '../../utils';

import { WORKSPACE_BY_ID } from './queries';

import styles from './AssetSelector.module.scss';

type SelectedWorkspace = Pick<Workspace, 'vecticeId' | 'name'>;

const acceptableAccessibilityLevels = [AccessibilityLevel.Editor, AccessibilityLevel.Manager];

export const WorkspaceSelector = ({
  assetType,
  selectedAssetId,
  disabled = false,
  canClearAsset = true,
  onSelect,
}: AssetSelectorProps) => {
  const [selectedVersion, setSelectedVersion] = useState<SelectedWorkspace>();
  const [search, setSearch] = useState<string | undefined>(undefined);

  const { data: workspacesData, loading: loadingWorkspaces } = useQuery(GET_USER_WORKSPACE_TARGET_LIST, {
    variables: {
      filters: {
        searchFilter: { search: search ?? '', fields: ['name'] },
        access: acceptableAccessibilityLevels,
      },
    },
    onError: (error) => message.error(error.message),
  });
  const options = workspacesData?.getUserWorkspaceList.items;

  useQuery(WORKSPACE_BY_ID, {
    skip: !selectedAssetId,
    variables: { workspaceId: selectedAssetId! },
    onCompleted: (data) => setSelectedVersion(data.getWorkspaceById),
  });

  useEffect(() => {
    if (!selectedAssetId) {
      setSelectedVersion(undefined);
    }
  }, [selectedAssetId]);

  const clearAsset = () => {
    setSelectedVersion(undefined);
    onSelect(null);
  };

  const overlay = (
    <MenuWithSearch
      search={search}
      onSearch={setSearch}
      loading={loadingWorkspaces}
      isEmpty={!options || !options.length}
    >
      {options?.map((workspace) => (
        <MenuItem
          key={`option-${workspace.vecticeId}`}
          leftIcon={getVecticeResourceTypeIcon(assetType)}
          onClick={() => {
            setSelectedVersion(workspace);
            onSelect(workspace.vecticeId);
          }}
        >
          <FlexContainer>
            <Typography variant="callout">{workspace.name}</Typography>
          </FlexContainer>
        </MenuItem>
      ))}
    </MenuWithSearch>
  );

  if (!loadingWorkspaces && search === '' && options?.length === 0) {
    return (
      <Typography className={styles.emptyAssetSelector} variant="callout" color="disabled" weight="semi-bold">
        {$t({ id: 'assetSelector.workspaceSelector.empty', defaultMessage: 'No assets available' })}
      </Typography>
    );
  }

  return (
    <>
      {!selectedVersion && (
        <Dropdown
          overlay={overlay}
          trigger={['click']}
          placement="bottomRight"
          onVisibleChange={(visible) => {
            if (!visible) setSearch('');
          }}
        >
          <Button
            aria-label="Select asset"
            className={cn(styles.trigger, { [styles.disabled]: disabled })}
            disabled={disabled}
            variant="white"
          >
            <Typography ellipsis color="tertiary">
              {$t({ id: 'assetSelector.selectAWorkspace', defaultMessage: 'Select a workspace' })}
            </Typography>
          </Button>
        </Dropdown>
      )}
      {selectedVersion && (
        <FlexContainer gap={18}>
          <Breadcrumbs>
            <Breadcrumb
              aria-label={selectedVersion.name}
              color="primary"
              icon={getVecticeResourceTypeIcon(assetType)}
              iconSize={20}
              inert
              weight="regular"
            >
              {selectedVersion.name}
            </Breadcrumb>
          </Breadcrumbs>
          {canClearAsset && (
            <Button
              aria-label="Clear asset"
              color="gray"
              leftIcon={RemoveIcon}
              size="xxs"
              variant="phantom"
              onClick={clearAsset}
            />
          )}
        </FlexContainer>
      )}
    </>
  );
};
