import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as MacroIcon } from '../../../assets/icons/editor/ic-macro.svg';
import { ReactComponent as TemplateIcon } from '../../../assets/icons/interface/ic-file-templates.svg';
import { ReactComponent as LockIcon } from '../../../assets/icons/interface/ic-lock.svg';
import { ReactComponent as CogIcon } from '../../../assets/icons/menu/ic-cog.svg';
import { ReactComponent as ApiKeyIcon } from '../../../assets/icons/menu/ic-key.svg';
import { ReactComponent as LogoutButton } from '../../../assets/icons/menu/ic-logout.svg';
import { ReactComponent as UsersIcon } from '../../../assets/icons/menu/ic-multiple-neutral.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/menu/ic-single-neutral.svg';
import { ReactComponent as ActivityIcon } from '../../../assets/icons/menu/ic-synchronize-arrow-clock.svg';
import { ReactComponent as WorkspaceIcon } from '../../../assets/icons/specials/flat/ic-workspace.svg';
import { useAuthentication } from '../../../context';
import { UserRole } from '../../../gql/graphql';
import { Allowed, Fallback, WithRoles } from '../../../guards';
import { VecticeRoutes, buildLink } from '../../../routes';
import { Dropdown, Menu, MenuDivider, MenuItem, MenuItemGroup } from '../../../ui';
import { UserAvatar, UserCard } from '../../asset-display';

import styles from './ProfileMenu.module.scss';

export const ProfileMenu = () => {
  const { user } = useAuthentication();

  const menu = (
    <Menu className={styles.menu}>
      <UserCard user={user} className={styles.signedInAs} />

      <MenuDivider />

      <Link to={buildLink(VecticeRoutes.ACCOUNT_PROFILE)} className={styles.link}>
        <MenuItem clickable leftIcon={UserIcon}>
          {$t({
            id: 'profileMenu.myProfile',
            defaultMessage: 'My Profile',
          })}
        </MenuItem>
      </Link>

      <WithRoles organizationRole={UserRole.Superadmin}>
        <Allowed>
          <Link to={buildLink(VecticeRoutes.ACCOUNT_PROFILE)} className={styles.link}>
            <MenuItem clickable leftIcon={CogIcon}>
              {$t({
                id: 'profileMenu.manageAccount',
                defaultMessage: 'Manage Account',
              })}
            </MenuItem>
          </Link>
          <MenuDivider />
          <Link to={buildLink(VecticeRoutes.ADMINISTRATION_ORGANIZATIONS)} className={styles.link}>
            <MenuItem clickable leftIcon={CogIcon}>
              {$t({
                id: 'profileMenu.manageOrganizations',
                defaultMessage: 'Manage Organizations',
              })}
            </MenuItem>
          </Link>
        </Allowed>
        <Fallback>
          <WithRoles organizationRole={[UserRole.OrgAdmin, UserRole.Member]}>
            <Link to={buildLink(VecticeRoutes.ACCOUNT_API_KEYS)} className={styles.link}>
              <MenuItem clickable leftIcon={ApiKeyIcon}>
                {$t({
                  id: 'profileMenu.apiKeys',
                  defaultMessage: 'Manage API Keys',
                })}
              </MenuItem>
            </Link>
          </WithRoles>
          <Link to={buildLink(VecticeRoutes.ACCOUNT_CHANGE_PASSWORD)} className={styles.link}>
            <MenuItem clickable leftIcon={LockIcon}>
              {$t({
                id: 'profileMenu.changePassword',
                defaultMessage: 'Change Password',
              })}
            </MenuItem>
          </Link>

          <Link to={buildLink(VecticeRoutes.MY_ACTIVITY)} className={styles.link}>
            <MenuItem clickable leftIcon={ActivityIcon}>
              {$t({
                id: 'profileMenu.myActivity',
                defaultMessage: 'My Activity',
              })}
            </MenuItem>
          </Link>

          <WithRoles organizationRole={UserRole.OrgAdmin}>
            <MenuDivider />
            <MenuItemGroup
              title={$t({
                id: 'profileMenu.organizationGroup',
                defaultMessage: 'Org Admin settings',
              })}
            >
              <Link to={buildLink(VecticeRoutes.ORGANIZATION_USERS)} className={styles.link}>
                <MenuItem clickable leftIcon={UsersIcon}>
                  {$t({
                    id: 'profileMenu.organizationUsers',
                    defaultMessage: 'Manage Users',
                  })}
                </MenuItem>
              </Link>
              <Link to={buildLink(VecticeRoutes.ORGANIZATION_WORKSPACES)} className={styles.link}>
                <MenuItem clickable leftIcon={WorkspaceIcon}>
                  {$t({
                    id: 'profileMenu.organizationWorkspaces',
                    defaultMessage: 'Manage Workspaces',
                  })}
                </MenuItem>
              </Link>
              <Link to={buildLink(VecticeRoutes.CDT_TEMPLATES)} className={styles.link}>
                <MenuItem clickable leftIcon={TemplateIcon}>
                  {$t({
                    id: 'profileMenu.organizationTemplates',
                    defaultMessage: 'Manage report templates',
                  })}
                </MenuItem>
              </Link>
              <Link to={buildLink(VecticeRoutes.CDT_MACROS)} className={styles.link}>
                <MenuItem clickable leftIcon={MacroIcon}>
                  {$t({
                    id: 'profileMenu.organizationMacros',
                    defaultMessage: 'Manage macros',
                  })}
                </MenuItem>
              </Link>
              <Link to={buildLink(VecticeRoutes.ORGANIZATION)} className={styles.link}>
                <MenuItem clickable leftIcon={CogIcon}>
                  {$t({
                    id: 'profileMenu.organizationSettings',
                    defaultMessage: 'Organization Settings',
                  })}
                </MenuItem>
              </Link>
            </MenuItemGroup>
          </WithRoles>
        </Fallback>
      </WithRoles>

      <MenuDivider />

      <Link to={buildLink(VecticeRoutes.LOGOUT)} className={styles.link}>
        <MenuItem clickable leftIcon={LogoutButton}>
          {$t({
            id: 'profileMenu.logout',
            defaultMessage: 'Logout',
          })}
        </MenuItem>
      </Link>
    </Menu>
  );

  return (
    <>
      {user && (
        <Dropdown overlay={menu} trigger={['click']}>
          <button className={styles.button} type="button" aria-label="Profile">
            <UserAvatar user={user} hideTooltip />
          </button>
        </Dropdown>
      )}
    </>
  );
};
