import React from 'react';

import { ReactComponent as ReportIcon } from '../../../assets/icons/specials/ic-report.svg';
import { buildLink, VecticeRoutes } from '../../../routes';
import { AssetLink } from '../../../ui';

import { ReportTooltip } from './ReportTooltip';

interface ReportLinkProps {
  name?: string | null;
  resourceId?: number | null;
}

export const ReportLink = ({ name, resourceId }: ReportLinkProps) => (
  <ReportTooltip id={resourceId}>
    <AssetLink
      name={name}
      to={resourceId ? buildLink(VecticeRoutes.CDT_REPORT, { reportId: resourceId }) : undefined}
      icon={ReportIcon}
    />
  </ReportTooltip>
);
