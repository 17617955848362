import { useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';

import { InventoryReferenceOutput } from '../../../gql/graphql';
import { Checkbox, CheckboxGroup, Divider, EmptySelect, FilterLabel, FilterSearch, WithLoading } from '../../../ui';
import { CommonFilterProps } from '../types';

import { GET_UNIQUE_INVENTORY_REFERENCES } from './getUniqueInventoryReferences';

import styles from './Filters.module.scss';

export interface InventoryReferenceFilterComponentProps {
  workspaceIdList: string[];
  projectIdList?: string[];
  modelIdList?: string[];
}

type Props = CommonFilterProps & InventoryReferenceFilterComponentProps;

export const InventoryReferenceFilterComponent = ({
  workspaceIdList,
  projectIdList,
  modelIdList,
  filter,
  onFilterUpdate,
}: Props) => {
  const [search, setSearch] = useState('');

  const { data, loading } = useQuery(GET_UNIQUE_INVENTORY_REFERENCES, {
    fetchPolicy: 'network-only',
    variables: {
      workspaceIdList,
      projectIdList,
      modelIdList,
      search,
    },
  });

  const uniqueInventoryReferences = useMemo(() => {
    const names = [...(data?.getUniqueInventoryReferences.items ?? [])];

    return names.sort((a, b) => (!a.inventoryReference ? 1 : !b.inventoryReference ? -1 : 0));
  }, [data]);

  return (
    <>
      <FilterSearch initialSearch={search} onSearch={setSearch} />
      <Divider component="div" className={styles.divider} />
      <WithLoading loading={loading}>
        <CheckboxGroup
          name="uniqueInventoryReferences-options"
          checkedValues={filter}
          onChange={(value) => onFilterUpdate(value.length ? value : undefined)}
          className={styles.options}
        >
          {uniqueInventoryReferences.map(({ inventoryReference }: InventoryReferenceOutput) => (
            <Checkbox
              key={inventoryReference}
              label={
                <FilterLabel color={!inventoryReference ? 'tertiary' : undefined}>
                  {inventoryReference ??
                    $t({
                      id: 'filter.inventoryReferences.noValue',
                      defaultMessage: 'N/A',
                    })}
                </FilterLabel>
              }
              className={styles.option}
              value={inventoryReference}
            />
          ))}
        </CheckboxGroup>
        {search && !uniqueInventoryReferences.length && <EmptySelect />}
      </WithLoading>
    </>
  );
};
