import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import { AttachmentOutput, EntityMetadataTypeEnum } from '../gql/graphql';
import { GET_ASSET_ATTACHMENTS } from '../graphql/queries';
import { DELETE_ENTITY_METADATA } from '../graphql/queries/deleteEntityMetadata.mutation';
import { RENAME_ENTITY_METADATA } from '../graphql/queries/renameEntityMetadata.mutation';
import { flushFromGraphQLCache } from '../graphql/utils';
import { message } from '../ui';
import { VecticeResourceType } from '../utils';

import { useEntityFileMethods } from './useEntityFileMethods';

export const useAttachments = (resourceId: number | string | undefined, resourceType?: VecticeResourceType) => {
  const resourceTypeForQuery = useMemo(() => {
    switch (resourceType) {
      case VecticeResourceType.MODEL_VERSION:
        return EntityMetadataTypeEnum.ModelVersion;
      default:
        return EntityMetadataTypeEnum.DatasetVersion;
    }
  }, [resourceType]);

  const [renameEntityMetadata] = useMutation(RENAME_ENTITY_METADATA, {
    refetchQueries: ['getAssetAttachments'],
  });

  const [deleteEntityMetadata] = useMutation(DELETE_ENTITY_METADATA, {
    refetchQueries: ['getAssetAttachments'],
    optimisticResponse: ({ id }) => ({
      deleteEntityMetadata: id,
    }),
    update: (cache, { data }) => {
      if (data?.deleteEntityMetadata)
        flushFromGraphQLCache(cache, { id: data?.deleteEntityMetadata, __typename: 'EntityMetadata' });
    },
  });

  const { data, loading } = useQuery(GET_ASSET_ATTACHMENTS, {
    fetchPolicy: 'network-only',
    skip: !resourceId,
    variables: {
      entityId: resourceId!,
      type: resourceTypeForQuery,
    },
  });

  const attachments = data?.attachments ?? [];
  const total = data?.attachments.length ?? 0;

  const removeEntityMetadata = useCallback((attachment: Pick<AttachmentOutput, 'id' | 'name' | '__typename'>) => {
    deleteEntityMetadata({
      variables: { id: attachment.id },
      onCompleted: () =>
        message.success(
          $t({
            id: 'Attachments.deleteSuccess',
            defaultMessage: `{name} has been deleted`,
            values: {
              name,
            },
          }),
        ),
      onError: () =>
        message.error(
          $t({
            id: 'Attachments.deletionError',
            defaultMessage: 'Oops, something went wrong! "{name}" could not be deleted.',
            values: { name },
          }),
        ),
    });
  }, []);

  const entityFileMethods = useEntityFileMethods(resourceId, resourceType);

  return {
    ...entityFileMethods,
    attachments,
    total,
    loading,
    renameEntityMetadata,
    removeEntityMetadata,
  };
};
