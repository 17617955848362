import queryString from 'query-string';
import { generatePath, matchPath } from 'react-router-dom';

import { CommentQueryParams } from '../comments/CommentQueryParams';
import { VecticeResourceType } from '../utils';

import { VecticeRouteConfig, VecticeRoutesConfiguration } from './routes-config';
import { VecticeRoutes } from './routes-names';
import { SearchParameters } from './routes-parameters';

const { stringify } = queryString;

interface RoutePathOptions {
  fullPath?: boolean;
  wildCard?: boolean;
}

const defaultRoutePathOptions: RoutePathOptions = { fullPath: true, wildCard: false };

/**
 * Inject url parameters in path
 *
 * @example
 * withRouteParams("/project/:projectId", { projectId: 'PRJ-1' }) -> "/project/PRJ-1"
 */
export const withRouteParams = (route: string, params: Record<string, unknown>) => {
  let routeWithParams = route;
  const keys = Object.keys(params);
  keys.forEach((key) => {
    routeWithParams = routeWithParams.replace(new RegExp(`:${key}\\??`), `${params[key]}`);
  });
  // remove any leftover params from the url
  routeWithParams = routeWithParams.replace(/\/:\w+\??/, '');
  return routeWithParams;
};

/**
 * Generate a route according the Vectice route name
 *
 * @example
 * getRoutePath(VecticeRoutes.ACCOUNT_CHANGE_PASSWORD) -> "/account/change-password"
 * @example
 * getRoutePath(VecticeRoutes.ACCOUNT_CHANGE_PASSWORD, { fullPath: false }) -> "/change-password"
 * @example
 * getRoutePath(VecticeRoutes.WORKSPACE, { wildCard: true }) -> "/workspace/:workspaceId/*"
 */
export const getRoutePath = (routeName: VecticeRoutes, customOptions: Partial<RoutePathOptions> = {}): string => {
  const route: VecticeRouteConfig = VecticeRoutesConfiguration[routeName];
  const options = { ...defaultRoutePathOptions, ...customOptions };
  if (options.fullPath && !!route.parent) {
    const parentRoutePath = getRoutePath(route.parent);
    return `${parentRoutePath}${route.path}${options.wildCard ? '/*' : ''}`;
  }

  return `${route.path}${options.wildCard ? '/*' : ''}`;
};

/**
 * Generate URL according Vectice route name and optional parameters
 *
 * @example
 * buildLink(VecticeRoutes.ACCOUNT_CHANGE_PASSWORD) -> "/account/change-password"
 * @example
 * buildLink(VecticeRoutes.PROJECT, { parameters: { projectId: 'PRJ-1' } }) -> "/project/PRJ-1"
 */
export const buildLink = (route: VecticeRoutes, parameters?: Record<string, unknown>) => {
  const path = getRoutePath(route);

  return parameters ? withRouteParams(path, parameters) : path;
};

export const buildBrowseLink = (params: { id: number | string; type: VecticeResourceType }) => {
  return buildLink(VecticeRoutes.BROWSE, params);
};

export const formatSearchParameters = (searchParams: SearchParameters) => {
  return stringify(searchParams, { sort: false });
};

export const getUserInviteLink = (token: string) =>
  `${window.location.protocol}//${window.location.host}${generatePath(getRoutePath(VecticeRoutes.REGISTER_TOKEN), {
    token,
  })}`;

export const getPhaseThreadLink = (phaseId: number | string, threadEditorId?: string) => {
  return {
    pathname: buildLink(VecticeRoutes.PHASE_DOCUMENTATION, { phaseId }),
    search: formatSearchParameters({ [CommentQueryParams.COMMENT_STATUS]: 'all' }),
    hash: threadEditorId ? 'cmt-' + threadEditorId : undefined,
  };
};

export const getReportThreadLink = (reportId: number | string, threadEditorId?: string) => {
  return {
    pathname: buildLink(VecticeRoutes.CDT_REPORT, { reportId }),
    search: formatSearchParameters({ [CommentQueryParams.COMMENT_STATUS]: 'all' }),
    hash: threadEditorId ? 'cmt-' + threadEditorId : undefined,
  };
};

export const getFindingThreadLink = (findingId: number | string, threadEditorId?: string) => {
  return {
    pathname: buildLink(VecticeRoutes.FINDING, { findingId }),
    search: formatSearchParameters({ [CommentQueryParams.COMMENT_STATUS]: 'all' }),
    hash: threadEditorId ? 'cmt-' + threadEditorId : undefined,
  };
};

export const isWorkspaceRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.WORKSPACE), end: false }, pathname);
export const isProjectRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.PROJECT), end: false }, pathname);
export const isDatasetRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.DATASET), end: false }, pathname);
export const isDatasetVersionRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.DATASET_VERSION), end: false }, pathname);
export const isModelRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.MODEL), end: false }, pathname);
export const isModelVersionRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.MODEL_VERSION), end: false }, pathname);
export const isPhaseRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.PHASE), end: false }, pathname);
export const isIterationRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.ITERATION), end: false }, pathname);
export const isPhaseExportRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.EXPORT_PHASE), end: false }, pathname);
export const isProjectRawExportRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.RAW_PROJECT_EXPORT), end: false }, pathname);
export const isPhaseRawExportRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.RAW_PHASE_EXPORT), end: false }, pathname);
export const isProjectExportRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.EXPORT_PROJECT), end: false }, pathname);
export const isReportRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.CDT_REPORT), end: false }, pathname);
export const isTemplateRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.CDT_EDIT_TEMPLATE), end: false }, pathname);
export const isReportRawExportRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.RAW_REPORT_EXPORT), end: false }, pathname);
export const isFindingRawExportRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.RAW_FINDING_EXPORT), end: false }, pathname);
export const isFindingRoute = (pathname: string) =>
  matchPath({ path: getRoutePath(VecticeRoutes.FINDING), end: false }, pathname);
