import { graphql } from '../../gql';

export const GET_MODEL_VERSIONS_LIST = graphql(`
  query getModelVersionsList(
    $modelId: VecticeId!
    $search: String
    $order: ListOrderInput
    $pageIndex: Int!
    $pageSize: Int!
    $showStarred: Boolean
    $createdBy: [ID!]
    $contributors: [ID!]
    $algorithmNames: [String!]
    $modelVersionStatus: [ModelVersionStatus!]
    $advancedFilters: [GroupFilter!]
  ) {
    getModelVersionsList(
      modelId: $modelId
      filters: {
        createdBy: $createdBy
        contributors: $contributors
        modelVersionStatus: $modelVersionStatus
        search: $search
        showStarred: $showStarred
        algorithmNames: $algorithmNames
        advancedFilters: $advancedFilters
      }
      order: $order
      page: { size: $pageSize, index: $pageIndex }
    ) {
      items {
        vecticeId
        versionNumber
        isStarred
        status
        algorithmName
        inventoryReference
        origin {
          id
        }
        properties {
          ...propertiesFields
        }
        metrics {
          ...metricsFields
        }
        createdBy {
          ...userFields
        }
        createdDate
      }
      total
    }
  }
`);
