import { useMutation } from '@apollo/client';
import React, { useMemo } from 'react';

import { DatasetType } from '../../components_v3/dataset/DatasetType';
import { getDatasetTypeOptions } from '../../entities/utils';
import { DatasetType as DatasetTypeEnum } from '../../gql/graphql';
import { Menu, MenuItem, message } from '../../ui';

import { UPDATE_DATASET_TYPE } from './queries';

type DatasetTypeMenuProps = {
  id: string;
  type: DatasetTypeEnum;
};

export const DatasetTypeMenu = ({ id, type }: DatasetTypeMenuProps) => {
  const datasetTypeOptions = useMemo(() => getDatasetTypeOptions(), []);

  const [updateType] = useMutation(UPDATE_DATASET_TYPE, {
    refetchQueries: ({ data }) =>
      data?.updateDataset.type === DatasetTypeEnum.Modeling || type === DatasetTypeEnum.Modeling
        ? ['getDatasetVersionsList', 'getDatasetVersionResources']
        : [],
    optimisticResponse: ({ datasetId: vecticeId, data: { type: newType } }) => ({
      updateDataset: {
        vecticeId: vecticeId.toString(),
        type: newType ?? type,
      },
    }),
    onCompleted: () =>
      message.success($t({ id: 'DatasetTypeMenu.update.success', defaultMessage: 'Dataset type has been updated' })),
    onError: (error) => message.error(error.message),
  });
  return (
    <Menu>
      {datasetTypeOptions.map((option) => (
        <MenuItem
          key={option.value}
          selected={type === option.value}
          onClick={() => updateType({ variables: { datasetId: id, data: { type: option.value } } })}
        >
          <DatasetType type={option.value} />
        </MenuItem>
      ))}
    </Menu>
  );
};
