import { FindingSeverity } from '../../gql/graphql';
import { BadgeColors } from '../../ui';

type findingSeverityType = FindingSeverity | string | null;

export const getFindingSeverityDisplayName = (status?: findingSeverityType) => {
  switch (status) {
    case 'Low':
      return $t({ id: 'issueSeverity.low.label', defaultMessage: 'Low' });
    case 'Medium':
      return $t({ id: 'issueSeverity.completed.label', defaultMessage: 'Medium' });
    case 'High':
      return $t({ id: 'issueSeverity.inReview.label', defaultMessage: 'High' });
    default:
      return status || $t({ id: 'issueSeverity.unknown.label', defaultMessage: 'Unknown' });
  }
};

export const getFindingSeverityColor = (status?: findingSeverityType): BadgeColors => {
  switch (status) {
    case FindingSeverity.Low:
      return 'success';
    case FindingSeverity.Medium:
      return 'warning';
    case FindingSeverity.High:
      return 'danger';
    default:
      return 'default';
  }
};

export const getFindingSeverityOptions = () => [
  {
    label: getFindingSeverityDisplayName(FindingSeverity.Low),
    value: FindingSeverity.Low,
  },
  {
    label: getFindingSeverityDisplayName(FindingSeverity.Medium),
    value: FindingSeverity.Medium,
  },
  {
    label: getFindingSeverityDisplayName(FindingSeverity.High),
    value: FindingSeverity.High,
  },
];
