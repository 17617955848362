import cn from 'classnames';
import React, { ElementType } from 'react';

import styles from './Divider.module.scss';

interface Props {
  vertical?: boolean;
  className?: string;
  component: ElementType;
}

export const Divider = ({ vertical, className, component: Component }: Props) => (
  <Component className={cn(styles.divider, { [styles.vertical]: vertical }, className)} />
);
