import dayjs from 'dayjs';
import calendarPlugin from 'dayjs/plugin/calendar';
import durationPlugin from 'dayjs/plugin/duration';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import utcPlugin from 'dayjs/plugin/utc';
import React, { useMemo } from 'react';

import { useExportFormatContext } from '../../context';
import { Placement, Tooltip } from '../../ui';
import { VecticeDateOptions } from '../../utils';

import { DEFAULT_DURATION_FORMAT, DEFAULT_FORMAT, DEFAULT_HOUR_FORMAT, FULL_FORMAT } from './constants';

import styles from './FormatTime.module.scss';

dayjs.extend(calendarPlugin);
dayjs.extend(durationPlugin);
dayjs.extend(utcPlugin);
dayjs.extend(relativeTimePlugin);

export const formatDate = (
  date: dayjs.ConfigType | null,
  { calendar, format, fromNow, toNow, toIsoFormat, utc }: VecticeDateOptions = {},
): string => {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }

  const d = utc ? dayjs.utc(date) : dayjs(date);

  if (toIsoFormat) {
    return d.toISOString();
  }

  if (calendar) {
    return d.calendar(null, {
      sameDay: `[Today], ${DEFAULT_HOUR_FORMAT}`,
      nextDay: `[Tomorrow], ${DEFAULT_HOUR_FORMAT}`,
      lastDay: `[Yesterday], ${DEFAULT_HOUR_FORMAT}`,
      sameElse: DEFAULT_FORMAT,
    });
  }

  if (fromNow) {
    return d.fromNow(fromNow === 'nosuffix');
  }

  if (toNow) {
    return d.to(dayjs(), true);
  }

  return d.format(format || DEFAULT_FORMAT);
};

export const formatDuration = (duration: number, format?: string) =>
  dayjs.duration(duration, 'milliseconds').format(format);
export const formatDurationISOString = (duration: number) => dayjs.duration(duration, 'milliseconds').toISOString();
export const humanizeDuration = (duration: number) => dayjs.duration(duration, 'milliseconds').humanize();

export interface FormatDateProps extends VecticeDateOptions {
  date: dayjs.ConfigType;
  tooltipPlacement?: Placement;
  withTooltip?: boolean;
}

export const FormatDate = ({ date, tooltipPlacement = 'top', withTooltip = true, ...options }: FormatDateProps) => {
  const { dateOptions: overriddenOptions } = useExportFormatContext();
  const formattedDate = useMemo(() => formatDate(date, { ...options, ...overriddenOptions }), [date, options]);
  const formattedFullDate = useMemo(() => formatDate(date, { format: FULL_FORMAT }), [date]);
  const isoDate = useMemo(() => formatDate(date, { toIsoFormat: true }), [date]);

  if (!date || !formattedDate) {
    return null;
  }

  const value = (
    <time dateTime={isoDate} className={styles.time} data-testid="datetime">
      {formattedDate}
    </time>
  );

  if (!withTooltip) {
    return <>{value}</>;
  }

  return (
    <Tooltip text={formattedFullDate} placement={tooltipPlacement}>
      {value}
    </Tooltip>
  );
};

export interface FormatDurationProps {
  duration?: number | null;
  format?: string;
}

export const FormatDuration = ({ duration, format = DEFAULT_DURATION_FORMAT }: FormatDurationProps) => {
  const { formattedDuration, formattedISODuration, humanizedDuration } = useMemo(() => {
    if (duration) {
      return {
        formattedDuration: formatDuration(duration, format),
        formattedISODuration: formatDurationISOString(duration),
        humanizedDuration: humanizeDuration(duration),
      };
    }
    return {
      formattedDuration: null,
      formattedISODuration: null,
      humanizedDuration: null,
    };
  }, [duration, format]);

  if (!formattedDuration) {
    return null;
  }

  return (
    <Tooltip text={formattedDuration} placement="top">
      <time data-testid="duration" dateTime={formattedISODuration} className={styles.time}>
        {humanizedDuration}
      </time>
    </Tooltip>
  );
};
