import React from 'react';

import { EntityMetric } from '../../gql/graphql';
import { useVecticeForm } from '../../hooks';
import { Input, ModalForm } from '../../ui';
import { defaultNameFieldConfiguration, defaultValueFieldConfiguration } from '../../utils';

interface MetricFormModalProps {
  selectedMetric?: EntityMetric;
  onClose: () => void;
  onSubmit: (data: Pick<EntityMetric, 'key' | 'value'>) => Promise<any>;
}

export const MetricFormModal = ({ selectedMetric, onClose, onSubmit }: MetricFormModalProps) => {
  const { formState, preSubmit, registerWithErrors } = useVecticeForm({
    mode: 'onChange',
    defaultValues: {
      metricName: selectedMetric?.key,
      metricValue: selectedMetric?.value?.toString(),
    },
  });
  const { isDirty, hasErrors, isSubmitting } = formState;

  return (
    <ModalForm
      title={
        selectedMetric
          ? $t({ id: 'modal.metricform.updateResource', defaultMessage: 'Update Metric' })
          : $t({ id: 'modal.metricform.createResource', defaultMessage: 'Create Metric' })
      }
      onClose={onClose}
      submitLabel={
        selectedMetric
          ? $t({ id: 'button.save', defaultMessage: 'Save' })
          : $t({ id: 'button.add', defaultMessage: 'Add' })
      }
      cancelLabel={$t({ id: 'modal.cancel', defaultMessage: 'Cancel' })}
      onSubmit={preSubmit(async (data: { metricName?: string; metricValue?: string }) => {
        if (data.metricValue && data.metricName) {
          await onSubmit({ key: data.metricName, value: parseFloat(data.metricValue) });
        }
      })}
      disabled={!isDirty || hasErrors}
      isSubmitting={isSubmitting}
    >
      <Input
        {...registerWithErrors('metricName', defaultNameFieldConfiguration())}
        label={$t({ id: 'modal.metricform.metricName', defaultMessage: 'Metric Name' })}
        placeholder={$t({ id: 'modal.metricform.placeholder', defaultMessage: 'Type the metric name' })}
        autoFocus
        gutterBottom
      />
      <Input
        {...registerWithErrors('metricValue', defaultValueFieldConfiguration())}
        type="number"
        step="any"
        label={$t({ id: 'modal.metricform.valueLabel', defaultMessage: 'Value' })}
        placeholder={$t({ id: 'modal.metricform.valuePlaceholder', defaultMessage: 'Type a numerical value' })}
      />
    </ModalForm>
  );
};
