import { useQuery } from '@apollo/client';
import React, { HTMLProps, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  getDatasetSourceUsageName,
  getDataSourceColumnCount,
  getDataSourceFileCount,
  getDataSourceSize,
} from '../../entities/utils';
import {
  DatasetSource,
  DatasetSourceUsage,
  DataSetVersion,
  Lineage,
  ListOrderInput,
  OrderDirection,
} from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import {
  buildAdvancedDateFilter,
  buildAdvancedEntitiesFilter,
  buildAdvancedIntegerFilter,
  buildAdvancedNameFilter,
} from '../../graphql/utils';
import { useWithRoles } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { useRetrievePageContext, useStarDatasetVersion } from '../../hooks';
import { buildLink, VecticeRoutes } from '../../routes';
import {
  Column,
  FlexContainer,
  NestedKeyInput,
  Pagination,
  sanitizeColumnKey,
  SearchInput,
  Table,
  Typography,
} from '../../ui';
import { isNil, isNumber } from '../../utils';
import { UserAvatar } from '../asset-display';
import { StarringButton } from '../buttons';
import { LineageCell } from '../cells';
import { CreatedByFilter, CreatedDateFilter, FilterComposer, PropertiesFilter, StarredFilter } from '../filters';
import { FormatDate, FormatFileSize } from '../formatters';
import { NoDatasetVersions, NoResults } from '../no-results';

import { GET_DATASET_TYPE } from './getDatasetType.query';
import { GET_DATASET_VERSIONS_LIST } from './getDatasetVersionsList.query';
import { GET_PROPERTY_NAMES } from './getPropertyNames.query';

import styles from './DatasetVersionsTable.module.scss';

const PAGE_SIZE = 30;

const defaultOrder = {
  field: 'createdDate',
  direction: OrderDirection.Desc,
};

interface DatasetVersionsTableProps {
  borderless?: boolean;
  datasetId?: string;
  initialFilters?: Record<string, any>;
  initialOrder?: ListOrderInput;
  lessPaginationItems?: boolean;
  nbOfVisibleFilterPills?: number;
  pageSize?: number;
  scrollable?: boolean;
  linkTarget?: HTMLProps<HTMLLinkElement>['target'];
  onFiltersChange?: (filters: Record<string, any>) => void;
  onOrderChange?: (order?: ListOrderInput) => void;
}

export const DatasetVersionsTable = ({
  borderless,
  datasetId,
  initialFilters = {},
  initialOrder = defaultOrder,
  lessPaginationItems,
  nbOfVisibleFilterPills,
  pageSize = PAGE_SIZE,
  scrollable,
  linkTarget,
  onFiltersChange,
  onOrderChange,
}: DatasetVersionsTableProps) => {
  const { workspace } = useRetrievePageContext();
  const { allowed: canUpdate } = useWithRoles({
    workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS,
  });

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<Record<string, any>>(initialFilters);
  const [order, setOrder] = useState<ListOrderInput | undefined>(initialOrder);
  const [page, setPage] = useState(1);

  const { updateStarDatasetVersion } = useStarDatasetVersion();

  const handleStarChange = (datasetVersionId: string, newState: boolean) =>
    updateStarDatasetVersion({
      variables: {
        resource: {
          resourceId: datasetVersionId,
          isStarred: newState,
        },
      },
    });

  const handleSearch = useCallback((value: string) => {
    setSearch(value);
    setPage(1);
  }, []);

  const handleFiltersChange = useCallback((value: Record<string, any>) => {
    setFilters(value);
    onFiltersChange?.(value);
    setPage(1);
  }, []);

  const onColumnSort = (field: string, direction: OrderDirection, nestedKey?: NestedKeyInput) => {
    setOrder({
      field,
      direction,
      nestedKey,
    });
    onOrderChange?.({
      field,
      direction,
      nestedKey,
    });
  };

  const { loading: loadingDatasetType } = useQuery(GET_DATASET_TYPE, {
    fetchPolicy: 'network-only',
    skip: !datasetId,
    variables: {
      datasetId: datasetId!,
    },
  });

  const { data: datasetVersionData, loading: loadingVersions } = useQuery(GET_DATASET_VERSIONS_LIST, {
    fetchPolicy: 'network-only',
    skip: !datasetId,
    variables: {
      datasetId: datasetId!,
      ...filters,
      advancedFilters: [
        ...buildAdvancedDateFilter(filters?.createdDate),
        ...buildAdvancedNameFilter(filters?.name),
        ...buildAdvancedEntitiesFilter(filters?.properties, 'properties'),
        ...(isNumber(search) ? buildAdvancedIntegerFilter('versionNumber', [+search]) : []),
      ],
      pageIndex: page,
      pageSize,
      order,
    },
  });

  const { data: propertyNamesData, loading: loadingProperties } = useQuery(GET_PROPERTY_NAMES, {
    fetchPolicy: 'network-only',
    skip: !datasetId,
    variables: {
      datasetId: datasetId!,
    },
  });

  const versions = datasetVersionData?.getDatasetVersionsList?.items;
  const totalItems = datasetVersionData?.getDatasetVersionsList?.total;
  const properties = propertyNamesData?.getPropertyKeysOfEntity;
  const sources = datasetVersionData?.getDatasetVersionsList?.items.flatMap((item) => item.datasetSources);
  const hasTraining = sources?.some((source) => source.usage === DatasetSourceUsage.Training);
  const hasTesting = sources?.some((source) => source.usage === DatasetSourceUsage.Testing);
  const hasValidation = sources?.some((source) => source.usage === DatasetSourceUsage.Validation);

  const loading = loadingProperties || loadingVersions || loadingDatasetType;

  const formattedVersions = useMemo(() => {
    const items = versions || [];
    return items.map((datasetVersion) => ({
      ...datasetVersion,
      properties: datasetVersion.properties.reduce<Record<string, string>>(
        // @ts-expect-error Issue with the current use of Fragments
        (acc, { key, value }) => ({
          ...acc,
          [sanitizeColumnKey(key)]: value,
        }),
        {},
      ),
    }));
  }, [versions]);

  const hasFilters = !!Object.keys(filters).length || !!search;

  const datasetSourcesColumnsConfig = [
    {
      title: $t({ id: 'dataset.versionsTable.header.itemsCount', defaultMessage: 'Nb of Files' }),
      render: (usage: DatasetSourceUsage | null) => (datasetSources: DatasetSource[]) =>
        (
          <span className={styles.filesCount}>
            {!isNil(getDataSourceFileCount(datasetSources, usage))
              ? $t({
                  id: 'dataset.versionsTable.itemsCount',
                  defaultMessage: '{itemCount, plural, one {# File} other {# Files}}',
                  values: { itemCount: getDataSourceFileCount(datasetSources, usage) },
                })
              : $t({ id: 'dataset.versionsTable.filesCountLabel.noValue', defaultMessage: 'N/A' })}
          </span>
        ),
    },
    {
      title: $t({ id: 'dataset.versionsTable.header.totalSize', defaultMessage: 'Size' }),
      render: (usage: DatasetSourceUsage | null) => (datasetSources: DatasetSource[]) =>
        (
          <span className={styles.totalSize}>
            <FormatFileSize size={getDataSourceSize(datasetSources, usage)} />
          </span>
        ),
    },
    {
      title: $t({ id: 'dataset.versionsTable.header.columnsCount', defaultMessage: 'Nb of Columns' }),
      render: (usage: DatasetSourceUsage | null) => (datasetSources: DatasetSource[]) =>
        (
          <span className={styles.columnsCount}>
            {!isNil(getDataSourceColumnCount(datasetSources, usage))
              ? $t({
                  id: 'dataset.versionsTable.columnsCount',
                  defaultMessage: '{itemCount, plural, one {# Column} other {# Columns}}',
                  values: { itemCount: getDataSourceColumnCount(datasetSources, usage) },
                })
              : $t({ id: 'dataset.versionsTable.columnsCount.noValue', defaultMessage: 'N/A' })}
          </span>
        ),
    },
  ];

  return (
    <>
      <FlexContainer align="flex-end" className={styles.filters} gap={16}>
        <SearchInput
          aria-label="Search"
          className={styles.search}
          placeholder={$t({ id: 'datasetVersion.search.placeholder', defaultMessage: 'Search by version number' })}
          type="number"
          min={0}
          onDebouncedChange={handleSearch}
        />
        <FilterComposer
          filters={filters}
          nbOfVisiblePills={nbOfVisibleFilterPills}
          onFiltersUpdate={handleFiltersChange}
        >
          <CreatedDateFilter />
          {workspace && <CreatedByFilter workspaceIdList={[workspace.vecticeId]} />}
          <PropertiesFilter entitiesNames={properties || []} />
          <StarredFilter />
        </FilterComposer>
      </FlexContainer>
      <Table
        aria-label="Dataset version table"
        data={formattedVersions}
        rowKey="vecticeId"
        initialSort={{ key: initialOrder.field, direction: initialOrder.direction }}
        scroll={{ x: true, y: scrollable }}
        loading={loading}
        onSort={onColumnSort}
        emptyText={hasFilters ? <NoResults borderless /> : <NoDatasetVersions borderless />}
        borderless={borderless}
      >
        <Column key="isStarred" title="" fixed="left" width={52}>
          {(isStarred: boolean, { vecticeId }: DataSetVersion) => (
            <StarringButton
              inert={!canUpdate}
              starred={isStarred}
              onChange={canUpdate ? (value) => handleStarChange(vecticeId, value) : undefined}
            />
          )}
        </Column>
        <Column
          key="versionNumber"
          title={$t({ id: 'datasetVersion.table.version.label', defaultMessage: 'Version' })}
          fixed="left"
          sortable
        >
          {(versionNumber: string, { vecticeId }: DataSetVersion) => (
            <Typography
              component={Link}
              className={styles.version}
              to={buildLink(VecticeRoutes.DATASET_VERSION, { datasetVersionId: vecticeId })}
              variant="callout"
              weight="semi-bold"
              color="primary"
              target={linkTarget ?? '_self'}
              ellipsis
              link
            >
              {$t({
                id: 'datasetVersion.table.version.value',
                defaultMessage: 'Version {versionNumber}',
                values: { versionNumber: versionNumber },
              })}
            </Typography>
          )}
        </Column>
        <Column key="origin" title="Lineage" width={70} align="center" ellipsis>
          {(origin: Lineage | null) => (
            <span className={styles.lineage}>
              <LineageCell runId={origin?.id} />
            </span>
          )}
        </Column>
        <Column
          key="createdDate"
          title={$t({ id: 'datasetVersion.table.createdDate', defaultMessage: 'Created date' })}
          sortable
          width={130}
          ellipsis
        >
          {(createdDate: Date) => <FormatDate date={createdDate} />}
        </Column>
        <Column
          key="createdBy"
          title={$t({ id: 'datasetVersion.table.creator', defaultMessage: 'Creator' })}
          align="center"
          sortable="createdBy.name"
          width={70}
          ellipsis
        >
          {(createdBy: UserIdentity) => (
            <span className={styles.creator}>
              <UserAvatar user={createdBy} size="sm" />
            </span>
          )}
        </Column>
        {hasTraining &&
          datasetSourcesColumnsConfig.map((columnConfig) => (
            <Column
              key="datasetSources"
              onHeaderCell={() => ({
                prefix: getDatasetSourceUsageName(DatasetSourceUsage.Training),
                title: columnConfig.title,
              })}
              title={columnConfig.title}
              width={120}
              ellipsis
            >
              {columnConfig.render(DatasetSourceUsage.Training)}
            </Column>
          ))}
        {hasTesting &&
          datasetSourcesColumnsConfig.map((columnConfig) => (
            <Column
              key="datasetSources"
              onHeaderCell={() => ({
                prefix: getDatasetSourceUsageName(DatasetSourceUsage.Testing),
                title: columnConfig.title,
              })}
              title={columnConfig.title}
              width={120}
              ellipsis
            >
              {columnConfig.render(DatasetSourceUsage.Testing)}
            </Column>
          ))}
        {hasValidation &&
          datasetSourcesColumnsConfig.map((columnConfig) => (
            <Column
              key="datasetSources"
              onHeaderCell={() => ({
                prefix: getDatasetSourceUsageName(DatasetSourceUsage.Validation),
                title: columnConfig.title,
              })}
              title={columnConfig.title}
              width={120}
              ellipsis
            >
              {columnConfig.render(DatasetSourceUsage.Validation)}
            </Column>
          ))}
        {!hasTraining &&
          !hasTesting &&
          !hasValidation &&
          datasetSourcesColumnsConfig.map((columnConfig) => (
            <Column
              key="datasetSources"
              onHeaderCell={() => ({
                prefix: getDatasetSourceUsageName(null),
                title: columnConfig.title,
              })}
              title={columnConfig.title}
              width={120}
              ellipsis
            >
              {columnConfig.render(null)}
            </Column>
          ))}
        {!!properties?.length &&
          properties?.map((key) => (
            <Column
              key={`properties.${sanitizeColumnKey(key)}`}
              onHeaderCell={() => ({
                title: key,
                prefix: 'Property',
              })}
              title={key}
              sortable="properties.value"
              nestedKey={{ field: 'key', value: key }}
              ellipsis
            >
              {(data?: string | null) => (
                <Typography ellipsis className={styles.property}>
                  {data ?? '-'}
                </Typography>
              )}
            </Column>
          ))}
      </Table>
      <Pagination
        total={totalItems}
        current={page}
        pageSize={pageSize}
        onChange={setPage}
        showLessItems={lessPaginationItems}
      />
    </>
  );
};
