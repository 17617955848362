import React from 'react';

import { useFragment } from '../../../gql';
import { ActivityType, ActivityUpdateType, DatasetType } from '../../../gql/graphql';
import { DatasetLink } from '../../asset-display';
import { DatasetTypeBadge } from '../../badges';
import { UserActivityFragment } from '../fragments';

import { ActivityCreator } from './ActivityCreator';
import { ActivityProps } from './interfaces';

export const DatasetActivity = ({ activity: fragment }: ActivityProps) => {
  const { type, fromValue, toValue, actor, targetName, targetVecticeId, updateType, isAutomated } = useFragment(
    UserActivityFragment,
    fragment,
  );

  const dataset = <DatasetLink name={targetName} resourceId={targetVecticeId} />;
  const user = <ActivityCreator user={actor} isAutomated={isAutomated} />;

  const oldNameLink = <DatasetLink name={fromValue || undefined} resourceId={targetVecticeId} />;

  const newNameLink = <DatasetLink name={toValue || undefined} resourceId={targetVecticeId} />;

  if (type === ActivityType.Created) {
    return (
      <>
        {$t({
          id: 'activities.dataset.created',
          defaultMessage: '{user} created {resource}',
          values: {
            user,
            resource: dataset,
          },
        })}
      </>
    );
  }

  if (type === ActivityType.Deleted) {
    return (
      <>
        {$t({
          id: 'activities.dataset.deleted',
          defaultMessage: '{user} deleted {resource}',
          values: {
            user,
            resource: dataset,
          },
        })}
      </>
    );
  }

  if (type === ActivityType.UpdatedProp) {
    if (updateType === ActivityUpdateType.Description) {
      return (
        <>
          {$t({
            id: 'activities.dataset.update.description',
            defaultMessage: '{user} updated the description of {resource}',
            values: {
              user,
              resource: dataset,
            },
          })}
        </>
      );
    }

    if (updateType === ActivityUpdateType.Name) {
      return (
        <>
          {$t({
            id: 'activities.dataset.update.name',
            defaultMessage: '{user} renamed {oldName} to {newName}',
            values: {
              user,
              oldName: oldNameLink,
              newName: newNameLink,
            },
          })}
        </>
      );
    }

    if (updateType === ActivityUpdateType.Type) {
      const unknown = $t({ id: 'datasetType.unknown.label', defaultMessage: 'Unknown' });
      const oldType = fromValue ? <DatasetTypeBadge datasetType={fromValue as DatasetType} /> : unknown;
      const newType = toValue ? <DatasetTypeBadge datasetType={toValue as DatasetType} /> : unknown;

      return (
        <>
          {$t({
            id: 'activities.dataset.update.type',
            defaultMessage: '{user} changed the type of {resource} from {oldType} to {newType}',
            values: {
              user,
              resource: dataset,
              oldType,
              newType,
            },
          })}
        </>
      );
    }
  }

  return null;
};
