import Hotjar from '@hotjar/browser';
import { ErrorBoundary } from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import config from '../config';
import { PublicConfigProvider } from '../context';
import { ErrorFallback } from '../error-boundary';
import { GraphQLProvider, I18nProvider } from '../providers';
import { broadcastClient } from '../services';
import { Modal } from '../ui';

import { AppRouter } from './AppRouter';

const reloadPage = () => window.location.reload();

export const AppRoot = () => {
  const [showWarningSupport, setShowWarningSupport] = useState(false);
  const { HOTJAR } = config;
  const { siteId, siteNonce, version } = HOTJAR;
  useEffect(() => {
    if (siteId && siteNonce) {
      Hotjar.init(siteId, version, {
        nonce: siteNonce,
      });
    }
  }, []);

  useEffect(() => {
    return broadcastClient.listen('update', () => {
      if (!!window.location) {
        reloadPage();
      }
    });
  }, []);

  useEffect(() => {
    if (navigator.userAgent.indexOf('Chrome') === -1 && navigator.userAgent.indexOf('Firefox') === -1) {
      setShowWarningSupport(true);
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      if (
        navigator.userAgent.indexOf('Edg') !== -1 ||
        navigator.userAgent.indexOf('OPR') !== -1 ||
        navigator.userAgent.indexOf('YaBrowser') !== -1 ||
        navigator.userAgent.indexOf('Yowser') !== -1 ||
        navigator.userAgent.indexOf('Vivaldi') !== -1
      ) {
        setShowWarningSupport(true);
      }
    } else if (navigator.userAgent.indexOf('Firefox') === -1) {
      setShowWarningSupport(true);
    }
  }, []);

  return (
    <I18nProvider locale="en-US">
      <BrowserRouter>
        <ErrorBoundary fallback={ErrorFallback}>
          <GraphQLProvider>
            <PublicConfigProvider>
              <AppRouter />
              {showWarningSupport && (
                <Modal
                  title={$t({
                    id: 'modal.unsupportedBrowser.title',
                    defaultMessage: 'Unsupported Browser',
                  })}
                  closeLabel={$t({
                    id: 'modal.close',
                    defaultMessage: 'Close',
                  })}
                  onClose={() => setShowWarningSupport(false)}
                >
                  Vectice supports only Google Chrome and Mozilla Firefox browsers. Other browsers such as Edge, and
                  Safari are not supported. As a result, certain features may not work as expected. Vectice recommends
                  using Chrome for the best experience. Ad block browser extensions may cause display or performance
                  issues in the web application.
                </Modal>
              )}
            </PublicConfigProvider>
          </GraphQLProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </I18nProvider>
  );
};
