import React from 'react';

import { ReactComponent as VersionIcon } from '../../assets/icons/specials/flat/ic-version.svg';
import { ModelVersionStatistics } from '../../gql/graphql';
import { WithFeatureFlags } from '../../guards';
import { Badge, FlexContainer } from '../../ui';

export const ModelSnapshot = ({ versionStats }: { versionStats: ModelVersionStatistics }) => (
  <FlexContainer direction="column" gap={5}>
    <Badge leftIcon={VersionIcon} size="sm" color="info">
      {$t({
        id: 'ModelSnapshot.versionsInExperimentation',
        defaultMessage: '{count} in Experimentation',
        values: {
          count: versionStats.experimentationCount,
        },
      })}
    </Badge>
    <Badge leftIcon={VersionIcon} size="sm" color="warning">
      {$t({
        id: 'ModelSnapshot.versionsInStaging',
        defaultMessage: '{count} in Staging',
        values: { count: versionStats.stagingCount },
      })}
    </Badge>
    <Badge leftIcon={VersionIcon} size="sm" color="success">
      {$t({
        id: 'ModelSnapshot.versionsInProduction',
        defaultMessage: '{count} in Production',
        values: { count: versionStats.productionCount },
      })}
    </Badge>
    <WithFeatureFlags featureFlag="model-version-discarded">
      <Badge leftIcon={VersionIcon} size="sm" color="abandoned">
        {$t({
          id: 'ModelSnapshot.versionsInDiscarded',
          defaultMessage: '{count} in Discarded',
          values: { count: versionStats.discardedCount },
        })}
      </Badge>
    </WithFeatureFlags>
    <Badge leftIcon={VersionIcon} size="sm" color="default">
      {$t({
        id: 'ModelSnapshot.versionsInRetired',
        defaultMessage: '{count} in Retired',
        values: { count: versionStats.retiredCount },
      })}
    </Badge>
  </FlexContainer>
);
