import { useQuery } from '@apollo/client';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import type { TooltipProps } from '../../../ui/tooltip/Tooltip';

import { ReactComponent as DatasetIcon } from '../../../assets/icons/specials/flat/ic-dataset.svg';
import { graphql } from '../../../gql';
import { AssetTooltip, DataList, Tooltip, Typography } from '../../../ui';
import { getVecticeResourceTypeLabel, VecticeResourceType } from '../../../utils';
import { DatasetTypeBadge } from '../../badges';
import { FormatDate, FormatUserName } from '../../formatters';

export const GET_DATASET_TOOLTIP = graphql(`
  query getDataSetTooltip($datasetId: VecticeId!) {
    getDataset(datasetId: $datasetId) {
      vecticeId
      name
      type
      versionCount
      createdBy {
        ...userFields
      }
      createdDate
    }
  }
`);

interface DatasetTooltipProps {
  id?: string | null;
  placement?: TooltipProps['placement'];
  children: ReactElement;
}

let globalId = 0;

export const DatasetTooltip = ({ id, placement = 'top', children }: DatasetTooltipProps) => {
  // eslint-disable-next-line no-plusplus
  const { current: ariaId } = useRef(`dataset-tooltip-${globalId++}`);
  const [skipQuery, setSkipQuery] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const { data, error } = useQuery(GET_DATASET_TOOLTIP, {
    skip: skipQuery || !id,
    variables: {
      datasetId: id!,
    },
  });

  const dataset = data?.getDataset;

  useEffect(() => {
    setDataLoaded(!!dataset || !!error);
  }, [dataset, error]);

  const handleVisibleChange = useCallback((state: boolean) => {
    setSkipQuery(false);
    setVisible(state);
  }, []);

  if (!id) {
    return children;
  }

  const overlay = (
    <AssetTooltip
      ariaId={ariaId}
      icon={DatasetIcon}
      resourceTypeLabel={getVecticeResourceTypeLabel(VecticeResourceType.DATASET)}
      label={dataset?.name}
      deleted={!!error}
    >
      {dataset && (
        <DataList>
          <Typography id={`created-${ariaId}`} component="dt" variant="callout" color="white">
            Created
          </Typography>
          <Typography aria-labelledby={`created-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
            <FormatDate date={dataset.createdDate} fromNow /> by <FormatUserName user={dataset.createdBy} />
          </Typography>
          <Typography component="dt" variant="callout" color="white">
            Type
          </Typography>
          <DatasetTypeBadge datasetType={dataset.type} contrast />
          <Typography id={`versions-${ariaId}`} component="dt" variant="callout" color="white">
            Versions
          </Typography>
          <Typography aria-labelledby={`versions-${ariaId}`} component="dd" variant="callout" color="white" paragraph>
            {dataset.versionCount}
          </Typography>
        </DataList>
      )}
    </AssetTooltip>
  );

  return (
    <Tooltip
      id={ariaId}
      overlay={overlay}
      visible={visible && dataLoaded}
      onVisibleChange={handleVisibleChange}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};
