import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { ReactComponent as AddIcon } from '../../assets/icons/interface/ic-add-circle.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/interface/ic-bin.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/interface/ic-edit.svg';
import { EntityMetric } from '../../gql/graphql';
import {
  Button,
  Column,
  EmptySection,
  FlexContainer,
  Menu,
  MenuItem,
  MoreActions,
  Pagination,
  Section,
  Table,
  Tooltip,
  Typography,
  message,
} from '../../ui';
import { isNullOrWhiteSpace, round } from '../../utils';
import { DeletionPrompt, MetricFormModal } from '../modals';

import { CREATE_METRIC } from './createMetric.mutation';
import { DELETE_METRICS } from './deleteMetrics.mutation';
import { GET_ENTITY_METRICS } from './getEntityMetrics.query';
import { UPDATE_METRIC } from './updateMetric.mutation';

const PAGE_SIZE = 30;

interface VersionMetricsProps {
  resourceId: string;
  readOnly?: boolean;
}

export const VersionMetrics = ({ resourceId, readOnly }: VersionMetricsProps) => {
  const [selectedMetric, setSelectedMetric] = useState<EntityMetric>();
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const { data, loading: loadingFetch } = useQuery(GET_ENTITY_METRICS, {
    fetchPolicy: 'no-cache',
    variables: {
      entityId: resourceId,
      pageIndex: currentPage,
      pageSize: PAGE_SIZE,
    },
    onError: () =>
      message.error($t({ id: 'VersionMetrics.fetchError', defaultMessage: 'Oops, something went wrong!' })),
  });

  const [createMetric, { loading: loadingCreate }] = useMutation(CREATE_METRIC, {
    refetchQueries: ['getEntityMetricsByParentId'],
    onCompleted: () => setShowModal(false),
    onError: (e) =>
      e.message.startsWith('The key already exists')
        ? message.error(
            $t({
              id: 'VersionMetrics.existingNameError',
              defaultMessage: 'A metric with the same name already exist!',
            }),
          )
        : message.error($t({ id: 'VersionMetrics.createError', defaultMessage: 'Oops, something went wrong!' })),
  });

  const [updateMetric, { loading: loadingUpdate }] = useMutation(UPDATE_METRIC, {
    refetchQueries: ['getEntityMetricsByParentId'],
    onCompleted: () => setShowModal(false),
    onError: (e) =>
      e.message.startsWith('The key already exists')
        ? message.error(
            $t({
              id: 'VersionMetrics.existingNameError',
              defaultMessage: 'A metric with the same name already exist!',
            }),
          )
        : message.error($t({ id: 'VersionMetrics.updateError', defaultMessage: 'Oops, something went wrong!' })),
  });

  const [deleteMetrics, { loading: loadingDelete }] = useMutation(DELETE_METRICS, {
    refetchQueries: ['getEntityMetricsByParentId'],
    onError: () =>
      message.error($t({ id: 'VersionMetrics.deleteError', defaultMessage: 'Oops, something went wrong!' })),
  });

  const total = data?.getResource.total || 0;
  const metrics = data?.getResource.items || [];
  const loading = loadingFetch || loadingCreate || loadingUpdate || loadingDelete;

  const handleCreate = async ({ key, value }: Pick<EntityMetric, 'key' | 'value'>) => {
    if (!loading) {
      if (isNullOrWhiteSpace(key) || isNullOrWhiteSpace(value?.toString())) {
        message.error($t({ id: 'VersionMetrics.allFieldsRequired', defaultMessage: 'All the fields are required' }));
        return;
      }
      await createMetric({
        variables: {
          entityId: resourceId,
          createMetric: {
            key,
            value: Number(value),
            timestamp: new Date(),
          },
        },
      });
    }
  };

  const handleUpdate = async ({ key, value }: Pick<EntityMetric, 'key' | 'value'>) => {
    if (selectedMetric && !loading) {
      const { id } = selectedMetric;
      if (isNullOrWhiteSpace(key) || isNullOrWhiteSpace(value?.toString())) {
        message.error($t({ id: 'VersionMetrics.allFieldsRequired', defaultMessage: 'All the fields are required' }));
        return;
      }
      await updateMetric({
        variables: {
          updateMetric: {
            id,
            key,
            value: Number(value),
          },
        },
      });
    }
  };

  const handleSelectionChange = (selection: EntityMetric[]) => {
    setSelectedRows(selection.map((row) => row.id));
  };

  const handleDelete = (record: EntityMetric) => {
    deleteMetrics({
      variables: {
        metricIds: [record.id],
      },
    });
  };

  const handleDeleteSelection = async () => {
    await deleteMetrics({
      variables: {
        metricIds: selectedRows,
      },
    });
    setSelectedRows([]);
  };

  const actions = (record: EntityMetric) => (
    <Menu>
      <MenuItem
        onClick={() => {
          setSelectedMetric(record);
          setShowModal(true);
        }}
        leftIcon={EditIcon}
      >
        {$t({ id: 'VersionMetrics.editMetric', defaultMessage: 'Edit metric' })}
      </MenuItem>
      <MenuItem
        onClick={() => {
          setSelectedMetric(record);
          setShowDeletionModal(true);
        }}
        leftIcon={DeleteIcon}
      >
        {$t({ id: 'VersionMetrics.deleteMetric', defaultMessage: 'Delete metric' })}
      </MenuItem>
    </Menu>
  );

  return (
    <Section
      label={(ariaId) => (
        <FlexContainer justify="space-between">
          <Typography id={ariaId} component="h2" color="primary" variant="callout" weight="semi-bold">
            {$t({ id: 'VersionMetrics.versionMetrics', defaultMessage: 'Version Metrics' })}
          </Typography>
          {!readOnly && (
            <FlexContainer gap={0}>
              <Button
                color="danger"
                variant="outlined"
                size="sm"
                leftIcon={DeleteIcon}
                disabled={selectedRows.length === 0}
                onClick={() => {
                  setSelectedMetric(undefined);
                  setShowDeletionModal(true);
                }}
              >
                {$t({ id: 'button.delete', defaultMessage: 'Delete' })}
              </Button>
              <Button
                size="sm"
                variant="white"
                leftIcon={AddIcon}
                onClick={() => {
                  setSelectedMetric(undefined);
                  setShowModal(true);
                }}
              >
                {$t({ id: 'button.add', defaultMessage: 'Add' })}
              </Button>
            </FlexContainer>
          )}
        </FlexContainer>
      )}
    >
      <Table
        // @ts-expect-error Caused by our current Fragments
        data={metrics}
        tableLayout="fixed"
        size="sm"
        rowKey="key"
        emptyText={
          <EmptySection
            message={$t({
              id: 'metrics.empty.message',
              defaultMessage: 'To display metrics, click the add button or use the Vectice API.',
            })}
          />
        }
        loading={loading}
        onSelectionChange={readOnly ? undefined : handleSelectionChange}
      >
        <Column key="key" title="Metric" ellipsis />
        <Column key="value" title="Value" width="35%">
          {(value: number) => (
            <>
              <Tooltip text={value} placement="topLeft">
                <div>{round(value)}</div>
              </Tooltip>
            </>
          )}
        </Column>
        {!readOnly && (
          <Column key="" title="" width="50px" align="right">
            {(_, record: EntityMetric) => <MoreActions moreActionsMenu={actions(record)} size="xs" />}
          </Column>
        )}
      </Table>
      <Pagination pageSize={PAGE_SIZE} current={currentPage} total={total} onChange={setCurrentPage} />

      {!readOnly && showModal && (
        <MetricFormModal
          selectedMetric={selectedMetric}
          onClose={() => setShowModal(false)}
          onSubmit={selectedMetric ? handleUpdate : handleCreate}
        />
      )}
      {showDeletionModal && (
        <DeletionPrompt
          processing={loadingDelete}
          title={
            selectedMetric?.key
              ? $t({
                  id: 'VersionMetrics.action.one',
                  defaultMessage: `Delete Version Metric`,
                })
              : $t({
                  id: 'VersionMetrics.action',
                  defaultMessage: `{count, plural, one {Delete Version Metric} other {Delete Version Metrics}}`,
                  values: { count: selectedRows.length },
                })
          }
          onClose={() => {
            setSelectedMetric(undefined);
            setShowDeletionModal(false);
          }}
          onConfirm={() => {
            if (selectedMetric) handleDelete(selectedMetric);
            else handleDeleteSelection();
            setSelectedMetric(undefined);
            setShowDeletionModal(false);
          }}
          resourceName={
            selectedMetric?.key ??
            $t({
              id: 'VersionMetrics.resourceName',
              defaultMessage: `{count, plural, one {# Metric} other {# Metrics}}`,
              values: {
                count: selectedRows.length,
              },
            })
          }
        />
      )}
    </Section>
  );
};
