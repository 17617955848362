import { useMutation } from '@apollo/client';
import React from 'react';

import { getDatasetSourceOriginDisplayName, getDatasetTypeDisplayName } from '../../entities/utils';
import { FragmentType, graphql, useFragment } from '../../gql';
import { useWithRoles } from '../../guards';
import { DEFAULT_WRITER_ACCESSIBILITY_LEVELS } from '../../guards/utils';
import { DatasetTypeMenu } from '../../pages/dataset/DatasetTypeMenu';
import {
  DataEntry,
  DataList,
  Dropdown,
  DropdownTrigger,
  FlexContainer,
  Icon,
  message,
  Skeleton,
  Tooltip,
  Typography,
} from '../../ui';
import { getDatasetSourceOriginIcon, VecticeResourceType } from '../../utils';
import { IterationLink, PhaseLink } from '../asset-display';
import { AssetDescription, AssetDetail, AssetDetails, CreationDetails, WithCopyID } from '../assets';
import { Contributors } from '../contributors';
import { DatasetType } from '../dataset/DatasetType';

import styles from './DatasetVersionSidebar.module.scss';

const DatasetVersionSidebarFragment = graphql(`
  fragment DatasetVersionSidebar on DataSetVersion {
    vecticeId
    description
    sourceOrigin
    dataSet {
      vecticeId
      type
      project {
        vecticeId
        workspace {
          vecticeId
        }
      }
    }
    origins {
      phase {
        vecticeId
        name
      }
      iteration {
        vecticeId
        name
      }
    }
    createdDate
    createdBy {
      id
      ...userFields
    }
    updatedDate
    lastUpdatedBy {
      id
      ...userFields
    }
  }
`);

export const UPDATE_DATASET_VERSION_DESCRIPTION = graphql(`
  mutation updateDatasetVersionDescription($datasetVersionId: VecticeId!, $data: DatasetVersionUpdateInput!) {
    updateDatasetVersion(datasetVersionId: $datasetVersionId, data: $data) {
      vecticeId
      description
    }
  }
`);

interface DatasetVersionSidebarProps {
  loading?: boolean;
  datasetVersion?: FragmentType<typeof DatasetVersionSidebarFragment>;
}

export const DatasetVersionSidebar = ({
  loading,
  datasetVersion: datasetVersionFragment,
}: DatasetVersionSidebarProps) => {
  const { allowed: canEdit } = useWithRoles({ workspaceRole: DEFAULT_WRITER_ACCESSIBILITY_LEVELS });

  const datasetVersion = useFragment(DatasetVersionSidebarFragment, datasetVersionFragment);

  const [updateDescription] = useMutation(UPDATE_DATASET_VERSION_DESCRIPTION, {
    optimisticResponse: ({ datasetVersionId: vecticeId, data: { description } }) => ({
      updateDatasetVersion: {
        vecticeId: vecticeId.toString(),
        description: description ?? datasetVersion!.description,
      },
    }),
    onCompleted: () =>
      message.success(
        $t({ id: 'DatasetVersionSidebar.update.success', defaultMessage: 'Version description has been updated' }),
      ),
    onError: (error) => message.error(error.message),
  });

  const origins = datasetVersion?.origins;
  const dataset = datasetVersion?.dataSet;
  const showSkeleton = loading || !datasetVersion;

  const sourceIcon = getDatasetSourceOriginIcon(datasetVersion?.sourceOrigin);

  return (
    <>
      <AssetDetails
        label={$t({ id: 'DatasetVersionSidebar.versionDetails', defaultMessage: 'Version Details' })}
        loading={showSkeleton}
        skeleton={<Skeleton.Paragraph rows={2} width={['200px', '150px']} />}
      >
        <AssetDetail label={$t({ id: 'DatasetVersionSidebar.datasetType', defaultMessage: 'Dataset Type' })}>
          {canEdit && dataset ? (
            <Dropdown overlay={<DatasetTypeMenu id={dataset.vecticeId} type={dataset.type} />} trigger="click">
              <DropdownTrigger aria-label="Dataset type select" regular>
                <DatasetType type={dataset?.type} />
              </DropdownTrigger>
            </Dropdown>
          ) : (
            <Tooltip text={getDatasetTypeDisplayName(dataset?.type)}>
              <DatasetType type={dataset?.type} />
            </Tooltip>
          )}
        </AssetDetail>
        <AssetDetail label={$t({ id: 'DatasetVersionSidebar.datasetSource', defaultMessage: 'Dataset Source' })}>
          <Tooltip text={getDatasetSourceOriginDisplayName(datasetVersion?.sourceOrigin || undefined)}>
            <FlexContainer gap={4}>
              {sourceIcon && <Icon icon={sourceIcon} size={16} />}
              <Typography ellipsis>
                {getDatasetSourceOriginDisplayName(datasetVersion?.sourceOrigin || undefined)}
              </Typography>
            </FlexContainer>
          </Tooltip>
        </AssetDetail>
        <AssetDetail label={$t({ id: 'DatasetVersionSidebar.creationDetails', defaultMessage: 'Creation Details' })}>
          <DataList className={styles.origins}>
            <DataEntry label={$t({ id: 'DatasetVersionSidebar.phase', defaultMessage: 'Phase:' })} ellipsis>
              <PhaseLink resourceId={origins?.phase?.vecticeId} name={origins?.phase?.name} tooltipPlacement="left" />
            </DataEntry>
            <DataEntry label={$t({ id: 'DatasetVersionSidebar.iteration', defaultMessage: 'Iteration:' })} ellipsis>
              <IterationLink
                resourceId={origins?.iteration?.vecticeId}
                name={origins?.iteration?.name}
                tooltipPlacement="left"
              />
            </DataEntry>
          </DataList>
        </AssetDetail>
        <AssetDetail label={$t({ id: 'DatasetVersionSidebar.datasetVersionID', defaultMessage: 'Version ID' })}>
          <WithCopyID
            iconLabel={$t({ id: 'DatasetVersionSidebar.copyLabel', defaultMessage: 'Copy Dataset version ID' })}
            idValue={datasetVersion?.vecticeId}
          />
        </AssetDetail>
      </AssetDetails>

      {datasetVersion && (
        <AssetDescription
          label={$t({ id: 'DatasetVersionSidebar.description', defaultMessage: 'Version Description' })}
          description={datasetVersion?.description}
          onChange={
            canEdit
              ? (description) =>
                  updateDescription({
                    variables: {
                      datasetVersionId: datasetVersion.vecticeId,
                      data: { description },
                    },
                  })
              : undefined
          }
        />
      )}

      {datasetVersion && (
        <AssetDetails label={$t({ id: 'DatasetVersionSidebar.contributors', defaultMessage: 'Contributors' })}>
          <Contributors
            resourceId={datasetVersion.vecticeId}
            resourceType={VecticeResourceType.DATASET_VERSION}
            workspaceId={datasetVersion.dataSet.project.workspace?.vecticeId}
          />
        </AssetDetails>
      )}

      {datasetVersion && (
        <CreationDetails
          createdDate={datasetVersion.createdDate}
          createdBy={datasetVersion.createdBy}
          updatedDate={datasetVersion.updatedDate}
          updatedBy={datasetVersion.lastUpdatedBy}
        />
      )}
    </>
  );
};
