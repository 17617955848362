import { useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';

import type { ButtonProps } from '../../ui/button/Button';

import { ReactComponent as CheckIcon } from '../../assets/icons/interface/ic-check-circle.svg';
import { useAuthentication } from '../../context';
import { OrderDirection, User } from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import { GET_USER_LIST, GET_WORKSPACE_MEMBER_LIST } from '../../graphql/queries';
import { Dropdown, DropdownTrigger, FlexContainer, Icon, MenuItem, Typography } from '../../ui';
import { MenuWithSearch } from '../../ui-presets';
import { reorderMemberList } from '../../utils';
import { UserAvatar } from '../asset-display';
import { FormatUserName } from '../formatters';

import styles from './OwnerStatus.module.scss';

interface FindingUserProps extends ButtonProps {
  user?: Pick<User, 'id'> & UserIdentity;
  workspaceId?: string | null;
  handleUserChange: (newUser: Pick<User, 'id'> & UserIdentity) => void;
}

export const FindingUser = ({ user, workspaceId, handleUserChange, ...props }: FindingUserProps) => {
  const [search, setSearch] = useState('');
  const [visible, setVisible] = useState(false);
  const { user: connectedUser } = useAuthentication();

  const { data: workspaceMembersData, loading: workspaceMembersLoading } = useQuery(GET_WORKSPACE_MEMBER_LIST, {
    fetchPolicy: 'network-only',
    skip: !workspaceId || !visible,
    variables: {
      workspaceId: workspaceId!,
      order: { field: 'name', direction: OrderDirection.Asc },
      filters: {
        searchFilter: {
          search,
          fields: ['name', 'email'],
        },
        includeDisabledUsers: false,
        includeReadOnlyUsers: false,
      },
    },
  });

  const { data: userListData, loading: userListLoading } = useQuery(GET_USER_LIST, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    skip: !visible || !workspaceId,
    variables: {
      filters: {
        searchFilter: search
          ? {
              fields: ['name', 'email'],
              search: search,
            }
          : undefined,
        includeDisabledUsers: false,
        includeReadOnlyUsers: false,
      },
    },
  });

  const members =
    workspaceMembersData?.getWorkspaceMemberList?.items ?? userListData?.getOrganizationUsersForUser ?? [];
  const membersArray = useMemo(() => reorderMemberList(members, connectedUser, !!search), [members]);
  const loading = workspaceMembersLoading ?? userListLoading;

  const handleVisibleChange = (newState: boolean) => {
    setVisible(newState);
    // Clean up the state when closing filter
    if (!newState) {
      setSearch('');
    }
  };

  const handleOwnerChange = (member: Pick<User, 'id'> & UserIdentity) => async () => {
    // close the menu without triggering a change if the selected member is already the user
    if (user?.id !== member.id) {
      handleUserChange(member);
    }
    handleVisibleChange(false);
  };

  const overlay = (
    <MenuWithSearch search={search} onSearch={setSearch} loading={loading} isEmpty={membersArray.length === 0}>
      {membersArray.map((member) => (
        <MenuItem
          key={`option-${member.id}`}
          selected={user?.id === member.id}
          className={styles.owner_option}
          onClick={handleOwnerChange(member)}
        >
          {user?.id === member.id && <Icon className={styles.icon} icon={CheckIcon} size={20} />}
          <FlexContainer className={styles.label}>
            <UserAvatar hideTooltip size="xs" user={member} />
            <Typography variant="callout">
              <FormatUserName user={member} />{' '}
              {member.id === connectedUser.id && (
                <Typography variant="footnote">
                  {$t({ id: 'memberList.message.assignToMe', defaultMessage: '(Assign to me)' })}
                </Typography>
              )}
            </Typography>
          </FlexContainer>
        </MenuItem>
      ))}
    </MenuWithSearch>
  );

  return (
    <Dropdown overlay={overlay} trigger={['click']} visible={visible} onVisibleChange={handleVisibleChange}>
      <DropdownTrigger variant="white" aria-label="Phase user select" {...props}>
        <FlexContainer className={styles.trigger}>
          <UserAvatar user={user} size="xs" />
          <Typography ellipsis>
            <FormatUserName user={user} />
          </Typography>
        </FlexContainer>
      </DropdownTrigger>
    </Dropdown>
  );
};
