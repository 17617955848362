import cn from 'classnames';
import React from 'react';

import { FragmentType } from '../../../gql';
import { CategoryType, DatasetSourceType, DatasetSourceUsage } from '../../../gql/graphql';
import { Divider } from '../../../ui';

import { ResourceMenuFragment, ResourcesMenu } from './ResourcesMenu';

import styles from './ResourcesNavigation.module.scss';

type DatasetResources = {
  sources?: {
    id: number;
    name: string;
    type?: string;
    mimeType?: string;
    rowsNumber?: number | null;
    columnsNumber?: number | null;
    columnsTypesCounts: { categoryType: CategoryType; count: number }[];
  }[];
  total?: number;
};

interface ResourcesNavigationProps extends VecticeHTMLProps<HTMLDivElement> {
  sources?: Partial<{
    default: FragmentType<typeof ResourceMenuFragment>;
    [DatasetSourceUsage.Testing]: FragmentType<typeof ResourceMenuFragment>;
    [DatasetSourceUsage.Training]: FragmentType<typeof ResourceMenuFragment>;
    [DatasetSourceUsage.Validation]: FragmentType<typeof ResourceMenuFragment>;
  }>;
  defaultResources?: DatasetResources;
  testingResources?: DatasetResources;
  trainingResources?: DatasetResources;
  validationResources?: DatasetResources;
  selectedResourceId?: number;
  onSelectResource?: (id: number, type: DatasetSourceType) => void;
}

export const ResourcesNavigation = ({
  sources,
  defaultResources,
  testingResources,
  trainingResources,
  validationResources,
  selectedResourceId,
  onSelectResource,
  className,
  ...props
}: ResourcesNavigationProps) => {
  return (
    <div className={cn(styles.navigation, className)} {...props}>
      {sources?.default && defaultResources?.sources && (
        <ResourcesMenu
          resources={defaultResources.sources}
          source={sources.default}
          total={defaultResources.total}
          selectedResourceId={selectedResourceId}
          onSelectResource={onSelectResource}
        />
      )}
      {sources?.[DatasetSourceUsage.Training] && trainingResources?.sources && (
        <>
          <ResourcesMenu
            resources={trainingResources.sources}
            source={sources[DatasetSourceUsage.Training]}
            total={trainingResources.total}
            selectedResourceId={selectedResourceId}
            onSelectResource={onSelectResource}
          />
          <Divider component="div" className={styles.divider} />
        </>
      )}
      {sources?.[DatasetSourceUsage.Testing] && testingResources?.sources && (
        <>
          <ResourcesMenu
            resources={testingResources.sources}
            source={sources[DatasetSourceUsage.Testing]}
            total={testingResources.total}
            selectedResourceId={selectedResourceId}
            onSelectResource={onSelectResource}
          />
          <Divider component="div" className={styles.divider} />
        </>
      )}
      {sources?.[DatasetSourceUsage.Validation] && validationResources?.sources && (
        <ResourcesMenu
          resources={validationResources.sources}
          source={sources[DatasetSourceUsage.Validation]}
          total={validationResources.total}
          selectedResourceId={selectedResourceId}
          onSelectResource={onSelectResource}
        />
      )}
    </div>
  );
};
