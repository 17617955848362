export class UIError extends Error {}

export class UnauthenticatedError extends UIError {}

export class VersionMismatchError extends UIError {}

export class ConnectionLostError extends UIError {}

export class InternalServerError extends UIError {
  name: string;
  stack?: string;

  constructor(networkError: { name: string; message?: string; stack?: string }) {
    super(networkError.message);
    this.name = networkError.name;
    this.stack = networkError.stack;
  }
}
