import { useMutation } from '@apollo/client';
import React, { useState } from 'react';

import { useOrgConfig, useAuthentication } from '../../context';
import { getUserRoleDisplayName } from '../../entities/utils';
import { User, UserRole } from '../../gql/graphql';
import { UserIdentity } from '../../graphql/fragments';
import { CHANGE_USER_PASSWORD, DISABLE_USER, ENABLE_USER, SET_USER_ROLE } from '../../graphql/queries';
import { useWithFeatureFlags } from '../../guards';
import { Menu, MenuItem, message, MoreActions } from '../../ui';
import { ChangeUserRoleModal, ResetUserPasswordModal, ToggleUserActivationModal } from '../modals';

const RoleRanked = [UserRole.Observer, UserRole.Member, UserRole.OrgAdmin, UserRole.Superadmin];

interface UserMenuCellProps {
  user: Pick<User, 'id' | 'name' | 'role' | 'disabled'> & UserIdentity;
}

export const UserMenuCell = ({ user }: UserMenuCellProps) => {
  const { configuration } = useOrgConfig();
  const { user: currentUser } = useAuthentication();

  const { allowed: withObserver } = useWithFeatureFlags({ featureFlag: 'viewer-role' });

  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [showUserActivationModal, setShowUserActivationModal] = useState(false);
  const [showChangeRoleModal, setShowChangeRoleModal] = useState<UserRole>();

  const [enableUser] = useMutation(ENABLE_USER);
  const [disableUser] = useMutation(DISABLE_USER);
  const [setUserRole] = useMutation(SET_USER_ROLE);
  const [updatePassword] = useMutation(CHANGE_USER_PASSWORD, {
    onCompleted: () =>
      message.success($t({ id: 'UserMenuCell.changePasswordSuccess', defaultMessage: 'Password updated' })),
    onError: (error) => message.error(error.message),
  });

  const handleActivate = async () => {
    setShowUserActivationModal(false);

    const id = Number(user.id);
    const result = await enableUser({ variables: { userId: id } });
    if (result.data?.enableUser) {
      message.success(
        $t({
          id: 'UserMenuCell.userEnabled',
          defaultMessage: `User {name} is enabled`,
          values: { name: user.name },
        }),
      );
    } else {
      // in fact, non-admin users should not be able to see disabled users but anyway :)
      message.error(result.errors?.[0]?.message);
    }
  };

  const handleDeactivate = async () => {
    setShowUserActivationModal(false);

    const id = Number(user.id);
    const result = await disableUser({ variables: { userId: id } });
    if (result.data?.disableUser) {
      message.success(
        $t({
          id: 'UserMenuCell.userDisabled',
          defaultMessage: `User {name} is disabled`,
          values: { name: user.name },
        }),
      );
    } else {
      message.error(result.errors?.[0]?.message);
    }
  };

  const handleRoleChange = async (role: UserRole) => {
    setShowChangeRoleModal(undefined);

    const result = await setUserRole({
      variables: {
        role,
        userId: Number(user.id),
      },
    });
    if (result.data?.setUserRole?.role === role) {
      message.success(
        $t({
          id: 'UserMenuCell.changeRoleSuccess',
          defaultMessage: `{name} has changed to {role}`,
          values: {
            name: user.name,
            role: getUserRoleDisplayName(role),
          },
        }),
      );
    } else {
      message.error(result.errors?.[0]?.message);
    }
  };

  const displayRoles = [UserRole.OrgAdmin, UserRole.Member, ...(withObserver ? [UserRole.Observer] : [])];

  const menu = (
    <Menu>
      {Object.values(UserRole)
        ?.filter((role) => {
          // don't show current role
          if (user.role && user.role === role) return null;
          // do not show if the role is higher ranked than currentRole
          if (RoleRanked.indexOf(currentUser.role) < RoleRanked.indexOf(role)) return null;
          return role ? displayRoles.includes(role) : null;
        })
        .map((role) => (
          <MenuItem key={role} onClick={() => setShowChangeRoleModal(role)}>
            {$t({
              id: 'UserMenuCell.changeRoleTo',
              defaultMessage: 'Change role to {role}',
              values: { role: getUserRoleDisplayName(role) },
            })}
          </MenuItem>
        ))}
      <MenuItem onClick={() => setShowUserActivationModal(true)}>
        {user.disabled
          ? $t({ id: 'UserMenuCell.activateUser', defaultMessage: 'Activate User' })
          : $t({ id: 'UserMenuCell.deactivateUser', defaultMessage: 'Deactivate User' })}
      </MenuItem>
      {configuration.passwordAuthenticationEnabled && (
        <MenuItem onClick={() => setShowPasswordResetModal(true)}>
          {$t({ id: 'UserMenuCell.resetPassword', defaultMessage: 'Reset Password' })}
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <>
      <MoreActions moreActionsMenu={menu} />
      {showUserActivationModal && (
        <ToggleUserActivationModal
          user={user}
          onActivate={handleActivate}
          onDeactivate={handleDeactivate}
          onClose={() => setShowUserActivationModal(false)}
        />
      )}
      {showChangeRoleModal && (
        <ChangeUserRoleModal
          user={user}
          role={showChangeRoleModal}
          onConfirm={handleRoleChange}
          onClose={() => setShowChangeRoleModal(undefined)}
        />
      )}
      {configuration.passwordAuthenticationEnabled && showPasswordResetModal && (
        <ResetUserPasswordModal
          user={user}
          onSubmit={async (newPassword: string) => {
            await updatePassword({
              variables: {
                userId: Number(user.id),
                newPassword,
              },
            });
            setShowPasswordResetModal(false);
          }}
          onClose={() => setShowPasswordResetModal(false)}
        />
      )}
    </>
  );
};
