import React from 'react';

import type { AssetRowProps } from '../../../ui';

import { ReactComponent as VersionIcon } from '../../../assets/icons/specials/flat/ic-version.svg';
import { ReactComponent as ModelIcon } from '../../../assets/icons/specials/ic-model.svg';
import { getModelTypeDisplayName } from '../../../entities/utils';
import { FragmentType, graphql, useFragment } from '../../../gql';
import { Project, Workspace } from '../../../gql/graphql';
import { AssetRow, Badge, FlexContainer, Icon, Typography } from '../../../ui';
import { getModelTypeIcon } from '../../../utils';
import { FormatDate, FormatUpdatedBy } from '../../formatters';
import { UserAvatar } from '../user';
import { FormatWorkspace } from '../workspace';

const ModelRowFragment = graphql(`
  fragment modelRow on Model {
    vecticeId
    name
    type
    versionStats {
      experimentationCount
      productionCount
      stagingCount
      retiredCount
    }
    updatedDate
    lastUpdatedBy {
      ...userFields
    }
  }
`);

interface ModelRowProps extends Omit<AssetRowProps, 'icon' | 'label'> {
  model?: FragmentType<typeof ModelRowFragment>;
  project?: Pick<Project, 'vecticeId' | 'name'> | null;
  workspace?: Pick<Workspace, 'vecticeId' | 'type' | 'name'> | null;
}

export const ModelRow = (props: ModelRowProps) => {
  const model = useFragment(ModelRowFragment, props.model);

  if (!model) {
    return null;
  }

  const typeIcon = getModelTypeIcon(model.type);

  return (
    <AssetRow icon={ModelIcon} parents={props.project?.name} label={model.name} {...props}>
      <Typography variant="footnote" color="secondary">
        {model.vecticeId}
      </Typography>
      {props.workspace && (
        <Typography variant="footnote" color="tertiary" ellipsis>
          <FormatWorkspace name={props.workspace.name} iconSize={14} type={props.workspace.type} ellipsis />
        </Typography>
      )}
      <Typography variant="footnote" color="secondary">
        {typeIcon && <Icon icon={typeIcon} size={14} />} {getModelTypeDisplayName(model.type)}
      </Typography>
      {model.versionStats && (
        <FlexContainer>
          <Badge leftIcon={VersionIcon} size="sm" color="success">
            {model.versionStats.productionCount} in Production
          </Badge>
          <Badge leftIcon={VersionIcon} size="sm" color="warning">
            {model.versionStats.stagingCount} in Staging
          </Badge>
          <Badge leftIcon={VersionIcon} size="sm" color="info">
            {model.versionStats.experimentationCount} in Experimentation
          </Badge>
        </FlexContainer>
      )}
      {!!model.updatedDate && (
        <Typography variant="footnote" color="secondary">
          <FormatUpdatedBy
            date={<FormatDate date={model.updatedDate} fromNow />}
            user={<UserAvatar user={model.lastUpdatedBy} size="xs" />}
          />
        </Typography>
      )}
    </AssetRow>
  );
};
